import { Component, input } from '@angular/core';

@Component({
    selector: 'app-space-background',
    templateUrl: './space-background.component.html',
    styleUrl: './space-background.component.scss'
})
export class SpaceBackgroundComponent {
    public large = input<boolean>();
}
