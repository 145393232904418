import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'app/common/constants';
import { ChangePasswordResult } from 'app/common/enums/enums';
import { DialogsService } from 'app/common/services/dialogs.service';
import { SessionService } from 'app/common/services/session.service';
import { FormHelper } from 'app/components/forms/form-helper.service';
import { IPasswordDto } from '../dto/password.dto';
import { LoginDataService } from '../login-data.service';
import { WeakPasswordFormService } from '../weak-password-form.service';

@Component({
    selector: 'app-weak-password',
    templateUrl: './weak-password.component.html',
    styleUrls: ['./weak-password.component.scss']
})
export class WeakPasswordComponent implements OnInit, OnDestroy {
    public form: UntypedFormGroup;
    public message: string;
    public weakPassword = false;

    constructor(
        private readonly translate: TranslateService,
        private readonly formHelper: FormHelper,
        private readonly router: Router,
        private readonly dialogs: DialogsService,
        private readonly sessionService: SessionService,
        private readonly dataService: LoginDataService,
        private readonly formService: WeakPasswordFormService
    ) {}

    ngOnInit() {
        this.sessionService.fullView = true;

        this.form = this.formService.initWeakPasswordForm();
    }

    ngOnDestroy() {
        this.sessionService.fullView = false;
    }

    public changePassword() {
        if (this.formService.form && !this.formService.form.valid) {
            this.formService.form.markAllAsTouched();
            return;
        }

        if (this.form.get('newPassword').value !== this.form.get('confirmPassword').value) {
            this.message = this.translate.instant('ChangePassword_DoNotCorrespond');
            return;
        }

        const dto: IPasswordDto = new IPasswordDto();
        const infoForm = this.formService.getFormGroup();
        if (infoForm) {
            dto.currentPassword = infoForm.value.currentPassword;
            dto.newPassword = infoForm.value.newPassword;
        }

        this.dataService.changeWeakPassword(dto).subscribe(
            (res) => {
                this.message = '';
                switch (res.result) {
                    case ChangePasswordResult.WeakPassword:
                        this.message = this.translate.instant('Login_PasswordTooWeak');
                        this.form.reset();
                        break;
                    case ChangePasswordResult.PasswordsNotEqual:
                        this.message = this.translate.instant('ChangePassword_DoNotCorrespond');
                        this.form.reset();
                        break;
                    default:
                        this.navigate();
                        break;
                }
            },
            (err: HttpErrorResponse) => {
                if (err.status === 400) {
                    this.dialogs.badRequestMessage(err);
                }
            }
        );
    }

    public navigate() {
        this.router.navigate(['/login'], { queryParams: { msg: 'pwd-changed' } }).catch(EMPTY);
    }
}
