<app-space-background>
    <h2 class="py-4 text-center">{{ 'Login_ChangePassword' | translate }}</h2>
    <div>
        <div class="alert alert-warning">
            {{ 'Login_WeakPassword' | translate }}
        </div>
        <div class="alert alert-danger" *ngIf="message">
            {{ message }}
        </div>
        <form [formGroup]="form" *ngIf="form">
            <app-new-password [form]="form" [(weakPassword)]="weakPassword"></app-new-password>
            <div class="form-group">
                <button
                    id="submitBtn"
                    type="submit"
                    class="btn btn-primary block full-width m-b"
                    (click)="changePassword($event)"
                    [disabled]="!form.valid || weakPassword"
                    data-tid="btnChange"
                >
                    {{ 'Common_Change' | translate }}
                </button>
            </div>
        </form>
    </div>
</app-space-background>
