import { Validators } from '@alza/cms-components';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class SetupTwoFaFormService {
    public form: UntypedFormGroup;

    initSetup2FaForm() {
        this.form = new UntypedFormGroup({
            id: new UntypedFormControl(),
            mode: new UntypedFormControl('', [Validators.required]),
            qrCode: new UntypedFormControl(null),
            manualCode: new UntypedFormControl(''),
            code: new UntypedFormControl('')
        });

        this.form.get('mode').valueChanges.subscribe((val) => this.setCodeValidators(val));
        return this.form;
    }

    private setCodeValidators(val: string) {
        if (val === 'APP') {
            this.form.get('code').setValidators([Validators.required, Validators.maxLength(6)]);
        } else {
            this.form.get('code').setValidators([]);
        }

        this.form.get('code').updateValueAndValidity();
    }

    getFormGroup() {
        if (this.form) {
            return this.form;
        }
        return null;
    }
}
