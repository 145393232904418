<div *ngIf="form">
    <form class="form" [formGroup]="form">
        <div class="row flexrow">
            <div class="col-xs-12 col-md-6 b-r">
                <div class="mb-4">
                    <app-button
                        [color]="'primary'"
                        class="button-small mr-4"
                        [id]="'ShouldGenerateUrl'"
                        type="button"
                        (click)="openUrlModal()"
                        [disabled]="isReadOnly"
                    >
                        {{ 'Category_Parameters_ProcessUrl' | translate }}
                    </app-button>
                    <app-button
                        [color]="'info'"
                        class="button-small"
                        [id]="'RedirectToWeb'"
                        type="button"
                        (click)="redirectToWeb()"
                        [disabled]="!form.get('sourceCategoryId').value"
                    >
                        {{ 'Category_Parameters_ShowOnWebsite' | translate }}
                    </app-button>
                </div>
                <div class="row">
                    <app-category-select-one
                        [id]="'sourceCategoryId'"
                        name="sourceCategoryId"
                        class="col-xs-12 col-md-8 flexcol"
                        [labelText]="'Category_Parameters_CategoryId' | translate"
                        placeholder="{{ 'Common_all' | translate }}"
                        [formControl]="form.get('sourceCategoryId')"
                    ></app-category-select-one>
                    <cms-checkbox
                        [labelText]="'Category_Parameters_Enabled' | translate"
                        [id]="'Enabled'"
                        name="enabled"
                        [formControl]="form.get('enabled')"
                        class="col-xs-12 col-md-4 withoutHeading"
                    ></cms-checkbox>
                </div>
                <div class="row">
                    <cms-number-input
                        class="col-sm-12 col-md-4"
                        [id]="'PriceFrom'"
                        name="priceFrom"
                        type="number"
                        [labelText]="'Category_Parameters_PriceFrom' | translate"
                        [formControl]="form.get('priceFrom')"
                    >
                        <error-message [errorCode]="'dateRangeFrom'" [message]="'Category_PriceToMustBeBiggerThanFromError' | translate"></error-message>
                    </cms-number-input>
                    <cms-number-input
                        class="col-sm-12 col-md-4"
                        [id]="'PriceTo'"
                        name="priceTo"
                        type="number"
                        [labelText]="'Category_Parameters_PriceTo' | translate"
                        [formControl]="form.get('priceTo')"
                    >
                        <error-message [errorCode]="'dateRangeTo'" [message]="'Category_PriceFromMustBeSmallerThanToError' | translate"> </error-message>
                    </cms-number-input>
                    <cms-checkbox
                        [labelText]="'Category_Parameters_InStock' | translate"
                        [id]="'InStock'"
                        name="inStock"
                        [formControl]="form.get('inStock')"
                        class="col-sm-12 col-md-4 withoutHeading"
                    ></cms-checkbox>
                </div>
                <cms-select
                    [id]="'Producers'"
                    name="producers"
                    [labelText]="'Category_Parameters_Producers' | translate"
                    [multiple]="true"
                    [items]="parameterProducers"
                    [formControl]="form.get('producers')"
                    bindLabel="name"
                    bindValue="id"
                    [clearable]="true"
                ></cms-select>
            </div>

            <div class="col-xs-12 col-md-6">
                <h2>{{ 'Category_Parameters' | translate }}</h2>
                <app-parameter-preview class="mb-4" [disabled]="isReadOnly" [parameterFilter]="parameterFilter" (removeParameter)="removeParameter($event)">
                </app-parameter-preview>
                <div class="mt-4">
                    <app-button
                        [id]="'openParameterModal'"
                        [disabled]="!form || !form.get('enabled').enabled || isReadOnly"
                        class="mr-4"
                        (click)="openParameterModal()"
                    >
                        {{ 'Category_Parameters_SetParameters' | translate }}
                    </app-button>
                </div>
            </div>
        </div>
    </form>
    <app-froala
        *ngIf="parameterInfoControl"
        [id]="'parameterInfo'"
        [labelText]="'Category_ParameterInfo' | translate"
        [formControl]="parameterInfoControl"
    ></app-froala>
</div>
