import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone, inject, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppInsightsMonitoringService } from './app-insights-monitoring.service';
import { EMPTY } from './constants';
import { InvalidFormError, NavigateToNotFoundError } from './errors';
import { ConcurrentSaveFormError } from './errors/concurrent-save-form-error';
import { DialogsService } from './services';

@Injectable()
export class LoggingErrorHandler extends ErrorHandler {
    private readonly _http = inject(HttpClient);
    private readonly _appInsights = inject(AppInsightsMonitoringService);
    private readonly _router = inject(Router);
    private readonly _ngZone = inject(NgZone);
    private readonly _injector = inject(Injector);
    constructor() {
        super();
    }

    handleError(error: Error): void {
        if (!error) {
            return;
        }

        if (error instanceof HttpErrorResponse) {
            // There is no point in logging HTTP responses below
            if (error.status === HttpStatusCode.BadRequest || error.status === HttpStatusCode.Unauthorized || error.status === HttpStatusCode.NotFound) {
                return;
            }
        }

        if (error instanceof NavigateToNotFoundError) {
            this.handleNotFoundError(error);
            return;
        }

        if (error instanceof ConcurrentSaveFormError) {
            return;
        }

        // Only log to console in dev mode
        if (error instanceof InvalidFormError) {
            if (isDevMode()) {
                super.handleError(error);
            }
            return;
        }

        super.handleError(error);

        // Force page refresh when new prod released and a client did not refresh and use navigation
        if (error.message.startsWith('Failed to fetch dynamically imported module:')) {
            window.location.reload();
            return;
        }

        this._appInsights.logException(error);

        const message = error.message ? error.message : error.toString();
        this._http
            .post('/api/log/client-exception', {
                message,
                name: error.name,
                stackTrace: error.stack,
                url: window.location.href
            })
            .subscribe();
    }

    private handleNotFoundError(error: NavigateToNotFoundError) {
        const dialogs = this._injector.get(DialogsService);
        const translate = this._injector.get(TranslateService);

        let body: string;
        if (Number.isNaN(error.primaryKey) || !Number.isInteger(error.primaryKey)) {
            body = translate.instant('Common_NotFoundDialogMessageNaN');
        } else {
            body = translate.instant('Common_NotFoundDialogMessage', { primaryKey: error.primaryKey });
        }

        dialogs.warningMessage(translate.instant('Common_NotFoundDialogHeader'), body);

        if (error.notFoundRoute) {
            this._ngZone.run(() => {
                this._router.navigate(error.notFoundRoute.command, error.notFoundRoute.extras).catch(EMPTY);
            });
        } else {
            this._ngZone.run(() => {
                const root = this._router.url.split('/')[1];
                this._router.navigate(['/', root]).catch(EMPTY);
            });
        }
    }
}
