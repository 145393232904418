import { Component } from '@angular/core';

import { Router } from '@angular/router';
import { EMPTY } from 'app/common/constants';
import { CompatibleParameterValuesDataService } from '../compatible-parameter-values-data.service';
import { IParameterDto } from '../compatible-parameter-values-dto';
import { FindParameterByCategory } from '../find-parameter-by-category';

@Component({
    templateUrl: './collection.component.html',
    styleUrls: ['./collection.component.scss']
})
export class CollectionComponent extends FindParameterByCategory {
    constructor(private router: Router, dataService: CompatibleParameterValuesDataService) {
        super(dataService);
    }

    goto(parameter: IParameterDto) {
        this.parameterSelected = parameter;
        this.router.navigate(['/CompatibleParameterValues', parameter.id]).catch(EMPTY);
    }
}
