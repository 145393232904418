<app-space-background>
    <div>
        <h1 class="py-4 text-center">{{ 'Login_ChangePassword' | translate }}</h1>
    </div>
    <div>
        <div class="alert alert-danger" *ngIf="message">
            {{ message }}
        </div>
        <form [formGroup]="form" *ngIf="form">
            <div class="form-group">
                <cms-input
                    [id]="'curentPassword'"
                    [type]="'password'"
                    [placeholder]="'Login_CurrentPassword' | translate"
                    formControlName="currentPassword"
                ></cms-input>
            </div>
            <app-new-password [form]="form" [(weakPassword)]="weakPassword"></app-new-password>
            <div class="form-group">
                <button
                    id="submitBtn"
                    type="submit"
                    class="btn btn-primary block full-width m-b"
                    (click)="changePassword($event)"
                    [disabled]="!form.valid || weakPassword"
                    data-tid="btnChange"
                >
                    {{ 'Common_Change' | translate }}
                </button>
            </div>
            <div class="form-group">
                <button type="button" routerLink="/dashboard" class="btn btn-default block full-width m-b" data-tid="btnContinue">
                    {{ 'Common_Back' | translate }}
                </button>
            </div>
        </form>
    </div>
</app-space-background>
