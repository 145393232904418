import { Validators } from '@alza/cms-components';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'app/common/constants';
import { DialogsService, SessionService } from 'app/common/services';
import { LoginDataService } from '../login-data.service';

@Component({
    selector: 'app-setup-phone-number',
    templateUrl: './setup-phone-number.component.html',
    styleUrls: ['./setup-phone-number.component.scss']
})
export class SetupPhoneNumberComponent implements OnInit, OnDestroy {
    constructor(
        private readonly session: SessionService,
        private readonly route: ActivatedRoute,
        private readonly dataService: LoginDataService,
        private readonly dialogs: DialogsService,
        private readonly router: Router,
        private readonly translate: TranslateService
    ) {}

    public phoneNumber = new UntypedFormControl(null, [Validators.required, Validators.pattern(/^\+[1-9]{1}[0-9]{9,13}$/)]);
    public code = new UntypedFormControl(null, Validators.required);

    public step: 'setup' | 'verify' = 'setup';

    ngOnInit() {
        this.session.fullView = true;
    }

    ngOnDestroy() {
        this.session.fullView = false;
    }

    public setup() {
        if (this.phoneNumber.invalid) {
            this.phoneNumber.markAsTouched();
            return;
        }
        this.dataService.setupPhoneNumber(this.route.snapshot.queryParams['token'], this.phoneNumber.value).subscribe(
            () => {
                this.step = 'verify';
            },
            (err) => {
                this.dialogs.badRequestMessage(err);
            }
        );
    }

    public verify() {
        if (this.code.invalid) {
            this.code.markAsTouched();
            return;
        }
        this.dataService.verifyPhoneNumber(this.route.snapshot.queryParams['token'], this.code.value, this.phoneNumber.value).subscribe(
            () => {
                this.dialogs.successMessage(this.translate.instant('Login_Required2FaSetupPhoneNumber_SucessfullySetup2FaPhoneNumber'), '', { timer: 10000 });
                this.router.navigate(['/login']).catch(EMPTY);
            },
            (err) => {
                this.dialogs.badRequestMessage(err);
            }
        );
    }
}
