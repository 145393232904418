<app-page-header [title]="title" *ngIf="formService.isInitialized()">
    <breadcrumb [text]="'Menu_Translations' | translate"></breadcrumb>
    <breadcrumb [link]="returnRoute" [text]="'CategoryProducerTranslations_CollectionTitle' | translate"></breadcrumb>
    <breadcrumb [active]="true" [text]="title"></breadcrumb>
</app-page-header>
<div class="wrapper wrapper-content animated fadeInRight">
    <div *ngIf="form && targetLanguage">
        <form class="form" [formGroup]="form">
            <div class="row">
                <div class="col-xs-12 col-md-6">
                    <app-tabs id="languages" [active]="selectedCountry" (activeChange)="selectedCountry = $event">
                        <tab [title]="language.name" [value]="language.id" *ngFor="let language of languages"></tab>
                        <div body>
                            <app-tabs id="texts-left" [active]="textTabs.selected" (activeChange)="textTabs.select($event)">
                                <tab [title]="tab.text" [value]="tab.name" *ngFor="let tab of textTabs.tabs"></tab>
                                <div body>
                                    <div *ngIf="textTabs.isSelected('general')" class="row">
                                        <cms-input
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.name').value"
                                            [id]="'sourceName'"
                                            [labelText]="'Category_Name' | translate"
                                            [formControl]="localized('name')"
                                        ></cms-input>
                                        <cms-input
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.seoName').value"
                                            [id]="'sourceSeoName'"
                                            [labelText]="'Category_SeoName' | translate"
                                            [formControl]="localized('seoName')"
                                        ></cms-input>
                                        <cms-input
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.title').value"
                                            [id]="'sourceTitle'"
                                            [labelText]="'Category_ProducerTitle' | translate"
                                            [formControl]="localized('title')"
                                        ></cms-input>
                                        <cms-input
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.descriptionTag').value"
                                            [id]="'sourceDescriptionTag'"
                                            [labelText]="'Category_DescriptionTag' | translate"
                                            [formControl]="localized('descriptionTag')"
                                        ></cms-input>
                                    </div>
                                    <div *ngIf="textTabs.isSelected('upperDescription')" class="row">
                                        <app-froala
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.upperDescriptionAnnotation').value"
                                            [id]="'sourceUpperDescriptionAnnotation'"
                                            [labelText]="'Category_UpperDescriptionAnnotation' | translate"
                                            [formControl]="localized('upperDescriptionAnnotation')"
                                        >
                                        </app-froala>
                                        <app-froala
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.upperDescription').value"
                                            [id]="'sourceUpperDescription'"
                                            [labelText]="'Category_UpperDescription' | translate"
                                            [formControl]="localized('upperDescription')"
                                        >
                                        </app-froala>
                                    </div>
                                    <div *ngIf="textTabs.isSelected('bottomDescription')" class="row">
                                        <app-froala
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.bottomDescriptionAnnotation').value"
                                            [id]="'sourceBottomDescriptionAnnotation'"
                                            [labelText]="'Category_BottomDescriptionAnnotation' | translate"
                                            [formControl]="localized('bottomDescriptionAnnotation')"
                                        >
                                        </app-froala>
                                        <app-froala
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.bottomDescription').value"
                                            [id]="'sourceBottomDescription'"
                                            [labelText]="'Category_BottomDescription' | translate"
                                            [formControl]="localized('bottomDescription')"
                                        >
                                        </app-froala>
                                    </div>
                                    <div *ngIf="textTabs.isSelected('productInfo')" class="row">
                                        <app-froala
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.commodityUpperText').value"
                                            [id]="'sourceCommodityUpperText'"
                                            [labelText]="'Category_CommodityUpperText' | translate"
                                            [formControl]="localized('commodityUpperText')"
                                        >
                                        </app-froala>
                                        <app-froala
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.commodityBottomText').value"
                                            [id]="'sourceCommodityBottomText'"
                                            [labelText]="'Category_CommodityBottomText' | translate"
                                            [formControl]="localized('commodityBottomText')"
                                        >
                                        </app-froala>
                                        <app-froala
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.commodityFaq').value"
                                            [id]="'sourceCommodityFaq'"
                                            [labelText]="'Category_CommodityFaq' | translate"
                                            [formControl]="localized('commodityFaq')"
                                        ></app-froala>
                                    </div>
                                </div>
                            </app-tabs>
                        </div>
                    </app-tabs>
                </div>
                <div class="col-xs-12 col-md-6">
                    <app-tabs [active]="targetLanguage.id">
                        <tab [title]="targetLanguage.name" [value]="targetLanguage.id"></tab>
                        <div body>
                            <app-tabs id="texts-right" [active]="textTabs.selected" (activeChange)="textTabs.select($event)">
                                <tab [title]="tab.text" [value]="tab.name" *ngFor="let tab of textTabs.tabs"></tab>
                                <h3>{{ targetLanguage.name }}</h3>
                                <div body>
                                    <div *ngIf="textTabs.isSelected('general')" class="row">
                                        <cms-input
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.name').value"
                                            [id]="'targetName'"
                                            [labelText]="'Category_Name' | translate"
                                            [formControl]="form.get('targetTranslation.name')"
                                        ></cms-input>
                                        <cms-input
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.seoName').value"
                                            [id]="'targetSeoName'"
                                            [labelText]="'Category_SeoName' | translate"
                                            [formControl]="form.get('targetTranslation.seoName')"
                                        ></cms-input>
                                        <cms-input
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.title').value"
                                            [id]="'targetTitle'"
                                            [labelText]="'Category_ProducerTitle' | translate"
                                            [formControl]="form.get('targetTranslation.title')"
                                        ></cms-input>
                                        <cms-input
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.descriptionTag').value"
                                            [id]="'targetDescriptionTag'"
                                            [labelText]="'Category_DescriptionTag' | translate"
                                            [formControl]="form.get('targetTranslation.descriptionTag')"
                                        ></cms-input>
                                    </div>
                                    <div *ngIf="textTabs.isSelected('upperDescription')" class="row">
                                        <app-froala
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.upperDescriptionAnnotation').value"
                                            [id]="'targetUpperDescriptionAnnotation'"
                                            [labelText]="'Category_UpperDescriptionAnnotation' | translate"
                                            [formControl]="form.get('targetTranslation.upperDescriptionAnnotation')"
                                        ></app-froala>
                                        <app-froala
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.upperDescription').value"
                                            [id]="'targetUpperDescription'"
                                            [labelText]="'Category_UpperDescription' | translate"
                                            [formControl]="form.get('targetTranslation.upperDescription')"
                                        ></app-froala>
                                    </div>
                                    <div *ngIf="textTabs.isSelected('bottomDescription')" class="row">
                                        <app-froala
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.bottomDescriptionAnnotation').value"
                                            [id]="'targetBottomDescriptionAnnotation'"
                                            [labelText]="'Category_BottomDescriptionAnnotation' | translate"
                                            [formControl]="form.get('targetTranslation.bottomDescriptionAnnotation')"
                                        ></app-froala>
                                        <app-froala
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.bottomDescription').value"
                                            [id]="'targetBottomDescription'"
                                            [labelText]="'Category_BottomDescription' | translate"
                                            [formControl]="form.get('targetTranslation.bottomDescription')"
                                        ></app-froala>
                                    </div>
                                    <div *ngIf="textTabs.isSelected('productInfo')" class="row">
                                        <app-froala
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.commodityUpperText').value"
                                            [id]="'targetCommodityUpperText'"
                                            [labelText]="'Category_CommodityUpperText' | translate"
                                            [formControl]="form.get('targetTranslation.commodityUpperText')"
                                        ></app-froala>
                                        <app-froala
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.commodityBottomText').value"
                                            [id]="'targetCommodityBottomText'"
                                            [labelText]="'Category_CommodityBottomText' | translate"
                                            [formControl]="form.get('targetTranslation.commodityBottomText')"
                                        ></app-froala>
                                        <app-froala
                                            class="col-xs-12"
                                            [class.highlighted]="form.get('fieldsToHighlight.commodityFaq').value"
                                            [id]="'targetCommodityFaq'"
                                            [labelText]="'Category_CommodityFaq' | translate"
                                            [formControl]="form.get('targetTranslation.commodityFaq')"
                                        ></app-froala>
                                    </div>
                                </div>
                            </app-tabs>
                        </div>
                    </app-tabs>
                </div>
            </div>
        </form>
    </div>
</div>

<app-page-footer [returnRoute]="returnRoute">
    <storno-button></storno-button>
    <save-and-close-button type="submit" (click)="save($event)"></save-and-close-button>
</app-page-footer>
