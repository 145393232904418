import { Directive, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { defaultCountryTranslation } from 'app/common/constants';
import { Subject } from 'rxjs';

@Directive()
export class CategoryBaseComponent<Tform extends UntypedFormArray | UntypedFormGroup = UntypedFormGroup> implements OnDestroy {
    protected unsubscribe = new Subject<void>();
    public selectedCountry = defaultCountryTranslation;
    public form?: Tform;

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public localized(key: string) {
        return this.control(['localization', this.selectedCountry, key]);
    }

    public control(key: string | Array<string | number>) {
        return this.form.get(key);
    }

    public controls(key: string) {
        return <Array<AbstractControl>>(<UntypedFormArray | UntypedFormGroup>this.form.get(key)).controls;
    }

    public isDeleted(formGroup: UntypedFormGroup) {
        return formGroup.get('$state').value === 'deleted';
    }

    public isNew(formGroup: UntypedFormGroup) {
        return formGroup.get('$state').value === 'added';
    }

    public deleteMany(container: UntypedFormArray, indexes: Array<number>) {
        indexes.sort((a, b) => b - a).forEach((index) => this.delete(container, index));
    }

    public delete(container: UntypedFormArray, index: number) {
        const item = container.controls[index];
        if (!item) {
            return;
        }
        if (item.get('$state') && item.get('$state').value !== 'added') {
            item.get('$state').setValue('deleted');
        } else {
            container.removeAt(index);
        }
    }

    public undelete(item: UntypedFormGroup) {
        item.get('$state').setValue('modified');
    }
}
