export class Path<T> {
    private _path: Array<T> = [];
    constructor(root: T) {
        this._path[0] = root;
    }

    public get path() {
        return this._path;
    }

    public set path(value: Array<T>) {
        this._path = value;
    }

    public push(node: T) {
        this._path.push(node);
    }

    public findOrPush(node: T, comparer: (obj1: T, obj2: T) => boolean) {
        const index = this._path.findIndex((i) => comparer(i, node));
        if (index >= 0) {
            this._path = this._path.slice(0, index + 1);
        } else {
            this._path.push(node);
        }
    }

    public get root() {
        return this._path[0];
    }

    public get current() {
        return this._path[this._path.length - 1];
    }

    public get isPathVisible(): boolean {
        return this._path.length > 1;
    }

    public get previousNode(): T | null {
        if (this._path && this._path.length > 1) {
            return this._path[this._path.length - 2];
        }
        return null;
    }
}
