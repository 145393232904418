<app-page-header [title]="'CategoryTranslations_CollectionTitle' | translate">
    <breadcrumb [text]="'Menu_Translations' | translate"></breadcrumb>
    <breadcrumb [text]="'CategoryTranslations_CollectionTitle' | translate" [active]="true"></breadcrumb>
</app-page-header>

<div class="wrapper-content animated fadeInRight">
    <app-tabs [active]="tabManager.selected" (activeChange)="tabManager.select($event)">
        <tab [title]="tab.text" [value]="tab.name" *ngFor="let tab of tabManager.tabs"></tab>
        <div body><router-outlet></router-outlet></div>
    </app-tabs>
</div>

<app-page-footer> </app-page-footer>
