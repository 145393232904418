<div mat-dialog-title>
    <button type="button" class="close" (click)="closeDialog(false)"><span aria-hidden="true">×</span></button>
    <h4>{{ 'Category_UpdatedTextsPopup_Title' | translate }}</h4>
</div>
<mat-dialog-content>
    <cms-checkbox
        [(ngModel)]="saveLastUpdated"
        id="saveLastUpdated"
        name="saveLastUpdated"
        [labelText]="'Category_UpdatedTextsPopup_CheckboxLabel' | translate"
    ></cms-checkbox>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" class="btn btn-primary" data-tid="saveModalCategoryTextUpdated" (click)="closeDialog(true)">
        <i class="far fa-save fa-fw"></i> {{ 'Common_Save' | translate }}
    </button>
</mat-dialog-actions>
