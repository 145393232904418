<app-space-background>
    <div>
        <h1 class="py-4 text-center">{{ 'ForgottenPasswordHeader' | translate }}</h1>
    </div>
    <div>
        <div class="alert alert-success" *ngIf="message">
            {{ message }}
        </div>
        <form [formGroup]="form" *ngIf="form && !emailSent">
            <cms-input [id]="'username'" [placeholder]="'ForgottenPassword_username' | translate" formControlName="username"></cms-input>
            <br />
            <div class="form-group">
                <button type="submit" class="btn btn-primary block full-width m-b" (click)="sendEmail()">{{ 'ForgottenPasswordSend' | translate }}</button>
            </div>
            <div class="form-group">
                <button type="button" routerLink="/login" class="btn btn-default block full-width m-b">
                    {{ 'ForgottenPassword_GoBack' | translate }}
                </button>
            </div>
        </form>
        <div class="form-group" *ngIf="emailSent">
            <button type="button" routerLink="/login" class="btn btn-default block full-width m-b">
                {{ 'ForgottenPassword_GoBack' | translate }}
            </button>
        </div>
    </div>
</app-space-background>
