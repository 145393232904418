import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { CategoryBaseComponent } from '../../category-base.component';
import { CategoryFormService } from '../../category-form.service';

@Component({
    selector: 'app-images',
    templateUrl: './images.component.html',
    styleUrls: ['./images.component.scss']
})
export class ImagesComponent extends CategoryBaseComponent implements OnInit {
    constructor(readonly formService: CategoryFormService, private readonly sanitizer: DomSanitizer) {
        super();
    }

    ngOnInit() {
        this.formService.reload$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.form = this.formService.getImages();
        });
    }

    getImage(data: string) {
        return data ? this.sanitizer.bypassSecurityTrustUrl(data) : null;
    }
}
