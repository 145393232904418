<div *ngIf="form">
    <form class="form" [formGroup]="form">
        <div *ngIf="isLocked" data-tid="countryGrid" class="grid grid-preview table-responsive">
            <div class="grid-row header">
                <span>{{ 'Category_Country_Name' | translate }}</span>
                <span>{{ 'Category_Country_SpecialUrl' | translate }}</span>
                <span>{{ 'Category_Country_SpecialUrlAction' | translate }}</span>
                <span>{{ 'Category_Country_Visible' | translate }}</span>
                <span>{{ 'Category_Country_Order' | translate }}</span>
            </div>

            <div *ngFor="let control of form.controls; let ix = index" class="grid-row striped hover">
                <cms-label [text]="control.get('countryName').value"></cms-label>
                <span>{{ control.get('specialUrl').value }}</span>
                <span>{{ control.get('specialUrlAction').value | enum : 'Category+SpecialUrlAction' }}</span>
                <span class="centered"
                    ><i class="fas" [ngClass]="{ 'fa-times': !control.get('visible').value, 'fa-check': control.get('visible').value }"></i
                ></span>
                <span>{{ control.get('index').value }}</span>
            </div>
        </div>

        <div *ngIf="!isLocked" class="grid table-responsive">
            <div class="grid-row header">
                <span>{{ 'Category_Country_Name' | translate }} </span>
                <span> {{ 'Category_Country_SpecialUrl' | translate }} </span>
                <span> {{ 'Category_Country_SpecialUrlAction' | translate }} </span>
                <span> {{ 'Category_Country_Visible' | translate }} </span>
                <span> {{ 'Category_Country_Order' | translate }} </span>
                <span> </span>
            </div>

            <div *ngFor="let control of form.controls; let ix = index" class="grid-row striped hover" [attr.data-tid]="'row_' + ix">
                <ng-container *ngIf="!isLocked">
                    <cms-label [text]="control.get('countryName').value"></cms-label>
                    <span>
                        <cms-input [id]="'specialUrl_' + ix" name="specialUrl" [formControl]="control.get('specialUrl')"></cms-input>
                    </span>
                    <span>
                        <cms-select
                            [id]="'specialUrlAction_' + ix"
                            name="specialUrlAction"
                            [enum]="'Category+SpecialUrlAction'"
                            appendTo="body"
                            [formControl]="control.get('specialUrlAction')"
                        ></cms-select>
                    </span>
                    <span>
                        <cms-checkbox [id]="'visible_' + ix" [formControl]="control.get('visible')"> </cms-checkbox>
                    </span>
                    <cms-number-input [id]="'countryVal_' + ix" name="countryVal" type="number" [formControl]="control.get('index')"></cms-number-input>
                    <span>
                        <app-button
                            *ngIf="control.get('countryId').value === 0"
                            [color]="'primary'"
                            [id]="'Copy_' + ix"
                            type="button"
                            [cmsTooltip]="'Category_Country_CopyToAll' | translate"
                            (click)="btnCopy(control.get('index').value, $event)"
                        >
                            <i class="fas fa-copy"></i>
                        </app-button>
                    </span>
                </ng-container>
            </div>
        </div>
    </form>
</div>
