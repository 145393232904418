import { FormHelper } from '@alza/cms-components';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'app/common/constants';
import { ICountryTranslationDto } from 'app/common/services/common-data.service';
import { DialogsService } from 'app/common/services/dialogs.service';
import { MenuService } from 'app/common/services/menu.service';
import { SessionService } from 'app/common/services/session.service';
import { SignInResult } from 'app/models/enums';
import { IAdminUserDto } from '../dto/user.dto';
import { LoginDataService } from '../login-data.service';
import { LoginFormService } from '../login-form.service';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    public form: UntypedFormGroup;
    public infoMessage: string;
    public warningMessage: string;
    public returnUrl: string;
    public countries: Array<ICountryTranslationDto> = [];
    public sectedCountry?: ICountryTranslationDto;

    constructor(
        private readonly translate: TranslateService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly dialogs: DialogsService,
        private readonly sessionService: SessionService,
        private readonly menuService: MenuService,
        private readonly dataService: LoginDataService,
        private readonly formService: LoginFormService,
        private readonly formHelper: FormHelper
    ) {
        this.form = this.formService.initUserForm();
    }

    ngOnInit() {
        this.sessionService.fullView = true;

        this.route.queryParams.subscribe((query) => {
            if (query['msg']) {
                switch (query['msg']) {
                    case 'pwd-changed':
                        this.translate.get('Login_Msg_ChangePassword_Changed').subscribe((x) => (this.infoMessage = x));
                        break;
                    case 'pwd-weak':
                        this.translate.get('Login_Msg_PasswordWeak').subscribe((x) => (this.infoMessage = x));
                        break;
                    case '2fa-setup-required':
                        this.translate.get('Login_Msg_2FaSetupRequired').subscribe((x) => (this.infoMessage = x));
                        break;
                }
            }
            if (query['result']) {
                const signInResult = Number(query['result']) as SignInResult;
                switch (signInResult) {
                    case SignInResult.AccountBlocked:
                        this.translate.get('Login_BlockedAccount').subscribe((x) => (this.warningMessage = x));
                        break;
                    case SignInResult.LoginMethodNotSupported:
                        this.translate.get('Login_LoginMethodNotSupported').subscribe((x) => (this.warningMessage = x));
                        break;
                    default:
                        this.translate.get('Login_LoginFailed').subscribe((x) => (this.warningMessage = x));
                        break;
                }
            }

            this.returnUrl = decodeURIComponent(query['returnUrl'] || query['ReturnUrl'] || '');
        });
    }

    ngOnDestroy() {
        this.sessionService.fullView = false;
    }

    public navigate() {
        this.menuService.getMenuItem(this.returnUrl).subscribe((result) => {
            if (this.returnUrl && result) {
                if (!result.isMvcModule) {
                    this.router.navigateByUrl(this.returnUrl).catch(EMPTY);
                } else {
                    window.location.href = this.returnUrl;
                }
            } else {
                this.router.navigate(['/dashboard/']).catch(EMPTY);
            }
            return;
        });
    }

    private setMessage(message: string) {
        this.dialogs.warningMessage(message);
    }

    public login() {
        if (this.formService.form && !this.formService.form.valid) {
            this.formService.form.markAllAsTouched();
            return;
        }

        const dto: IAdminUserDto = new IAdminUserDto();
        const infoForm = this.formService.getFormGroup();
        if (infoForm) {
            dto.password = infoForm.value.password;
            dto.userName = infoForm.value.username;
        }

        this.dataService.loginAdminUser(dto).subscribe(
            (result) => {
                switch (result.signInResult) {
                    case SignInResult.Failed:
                        this.formHelper.addError(this.form.get('password'), { invalidPassword: true });
                        break;
                    case SignInResult.Succeeded:
                        this.navigate();
                        break;
                    case SignInResult.RequireEmailVerification:
                        this.router.navigate(['/unconfirmed-email'], { queryParams: { required: true } }).catch(EMPTY);
                        break;
                    case SignInResult.SucceededRequireEmailVerification:
                        this.router.navigate(['/unconfirmed-email']).catch(EMPTY);
                        break;
                    case SignInResult.SucceededWeakPassword:
                        this.router.navigate(['/weak-password']).catch(EMPTY);
                        break;
                    case SignInResult.AccountLocked:
                        this.setMessage(this.translate.instant('Login_TemporaryBlockedAccount'));
                        break;
                    case SignInResult.AccountBlocked:
                        this.setMessage(this.translate.instant('Login_BlockedAccount'));
                        break;
                    case SignInResult.SucceededTwoFactorRequired:
                        this.router.navigate(['/verify-2fa'], { queryParams: { returnUrl: this.returnUrl } }).catch(EMPTY);
                        break;
                    case SignInResult.PhoneNumberRequired:
                        this.router.navigate(['/phone-number-required']).catch(EMPTY);
                        break;
                    case SignInResult.LoginMethodNotSupported:
                        this.setMessage(this.translate.instant('Login_LoginMethodNotSupported'));
                        break;
                    default:
                        break;
                }
            },
            (err: HttpErrorResponse) => {
                if (err.status === 400) {
                    this.dialogs.badRequestMessage(err);
                }
            }
        );
    }
}
