<div *ngIf="form">
    <form class="form" [formGroup]="form">
        <div *ngIf="!isLocked" class="row">
            <cms-select
                [id]="'producerToAdd'"
                name="producerToAdd"
                [labelText]="'Campaign_AddProducer' | translate"
                [(ngModel)]="producerToAdd"
                [ngModelOptions]="{ standalone: true }"
                [items]="producersToAdd"
                bindLabel="text"
                bindValue="id"
                virtualScroll="true"
                class="col-xs-12 col-sm-6 col-md-3 col-lg-2"
            ></cms-select>
            <div class="col-xs-12 col-sm-4 withoutHeading">
                <button class="btn btn-primary" data-tid="btnAddProducer" [disabled]="unableToAddProducer()" (click)="addProducer()">
                    <i class="fas fa-fw fa-plus"></i>
                    {{ 'Campaign_AddProducerBtn' | translate }}
                </button>
            </div>
        </div>
        <app-tabs id="producers" *ngIf="form.controls.length > 0" [active]="selectedProducer" (activeChange)="select($event)">
            <tab
                *ngFor="let producer of form.controls; let i = index"
                [title]="producer.get('name').value"
                [value]="producer.get('id').value"
                [formInvalid]="producerInvalid(producer.get('id').value)"
                [entityState]="getProducerState(producer.get('id').value)"
            ></tab>
        </app-tabs>
        <app-producer-texts [gridTab]="gridTab" *ngIf="producerTextForm" [form]="producerTextForm"></app-producer-texts>
    </form>
</div>
