import { Component, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { CategoryBaseComponent } from '../../category-base.component';
import { CategoryFormService } from '../../category-form.service';

@Component({
    selector: 'app-countries',
    templateUrl: './countries.component.html',
    styleUrls: ['./countries.component.scss']
})
export class CountriesComponent extends CategoryBaseComponent<UntypedFormArray> implements OnInit {
    constructor(readonly formService: CategoryFormService) {
        super();
    }

    ngOnInit() {
        this.formService.reload$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.form = this.formService.getCountries();
        });
    }

    public get isLocked() {
        return !this.formService.isEditable('countries');
    }

    btnCopy(index: number) {
        for (const el of this.form.controls) {
            el.get('index').patchValue(index);
        }
    }
}
