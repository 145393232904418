import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { CommonDataService } from 'app/common/services';
import { ISelectListItemDto } from 'app/models';
import { takeUntil } from 'rxjs/operators';
import { CategoryBaseComponent } from '../../category-base.component';
import { CategoryFormService } from '../../category-form.service';

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss']
})
export class TagsComponent extends CategoryBaseComponent<UntypedFormArray> implements OnInit {
    public tagList: Array<ISelectListItemDto> = [];
    public tagFullList: Array<ISelectListItemDto> = [];
    public tagToAdd: number;

    constructor(readonly commonDataService: CommonDataService, private readonly formService: CategoryFormService) {
        super();
    }

    ngOnInit() {
        this.commonDataService.selectList.getTags().subscribe((tags) => {
            this.tagFullList = tags;
            this.updateTagsList();
        });

        this.formService.reload$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.form = this.formService.getTags();
            this.form.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
                this.updateTagsList();
            });
        });
    }

    public get isLocked() {
        return !this.formService.isEditable('tags');
    }

    public updateTagsList() {
        const includedItemIds = this.form.getRawValue().map((x) => x.tagId);
        this.tagList = this.tagFullList.filter((x) => !includedItemIds.includes(x.id));
    }

    addItem() {
        if (!this.form.getRawValue().some((x) => x.tagId === this.tagToAdd)) {
            this.form.push(
                this.formService.createAndFillTagControl({
                    id: 0,
                    tagId: this.tagToAdd,
                    index: this.form.controls.length + 1,
                    style: 0,
                    tagText: this.tagList.filter((x) => x.id === this.tagToAdd)[0].text,
                    $state: 'added'
                })
            );
        }
        this.tagToAdd = null;
    }

    public onDrop(event: CdkDragDrop<any>) {
        if (event.container.id === 'orderList') {
            moveItemInArray(this.form.controls, event.previousIndex, event.currentIndex);
            this.form.updateValueAndValidity();
        }
    }
}
