import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiParameterCodec } from 'app/common/api-parameter-codec';
import { HttpMemoryCache } from 'app/common/http-memory-cache';
import { IPasswordDto } from './dto/password.dto';
import { ISetup2Fa, IVerify2Fa } from './dto/setup-2fa.dto';
import { IAdminUserDto } from './dto/user.dto';

@Injectable({
    providedIn: 'root'
})
export class LoginDataService {
    private readonly _cache = new HttpMemoryCache();

    constructor(private readonly http: HttpClient) {}

    public loginAdminUser(dto: IAdminUserDto) {
        return this.http.post<any>(`/api/accounts/login`, dto);
    }

    public forgottenPassword(dto: IAdminUserDto) {
        return this.http.post<any>(`/api/accounts/forgotten-password`, dto);
    }

    public changePassword(dto: IPasswordDto) {
        return this.http.put<any>(`/api/accounts/change-password`, dto);
    }

    public changeWeakPassword(dto: IPasswordDto) {
        return this.http.put<any>(`/api/accounts/change-weak-password`, dto);
    }

    public resetPassword(dto: IPasswordDto) {
        return this.http.post<any>(`/api/accounts/reset-password`, dto);
    }

    public get2FaSetup() {
        return this.http.get<any>(`/api/accounts/setup-2fa`);
    }

    public update2FaSetup(dto: ISetup2Fa) {
        return this.http.put<any>(`/api/accounts/setup-2fa`, dto);
    }

    public sendConfirmationEmail() {
        return this.http.get<any>(`/api/accounts/send-confirmation-email`);
    }

    public sendSetup2FaPhoneNumberEmail() {
        return this.http.get<any>(`/api/accounts/send-2fa-phone-number-email`);
    }

    public getUserEmail() {
        return this.http.get<{ email: string }>(`/api/accounts/user-email`);
    }

    public is2FaEnabled() {
        return this.http.get<any>(`/api/accounts/2fa-enabled`);
    }

    public verify2Fa(dto: IVerify2Fa) {
        return this.http.post<any>(`/api/accounts/verify-2fa`, dto);
    }

    public confirmEmail(token: string) {
        let p = new HttpParams({ encoder: new ApiParameterCodec() });
        p = p.set('token', token);
        return this.http.get<any>(`/api/accounts/confirm-email`, {
            params: p
        });
    }

    public verifyToken(token: string) {
        return this.http.post<{ valid: boolean; username: string; token: string }>('/api/accounts/verify-token', { token: token });
    }

    public setupPhoneNumber(token: string, phoneNumber: string) {
        return this.http.post('/api/accounts/setup-2fa-phone-number', { token, phoneNumber });
    }

    public verifyPhoneNumber(token: string, code: string, phoneNumber: string) {
        return this.http.post('/api/accounts/verify-2fa-phone-number', { token, code, phoneNumber });
    }
}
