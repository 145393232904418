import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'app/common/constants';
import { CommonDataService } from 'app/common/services';
import { ISelectListItemDto } from 'app/models';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CategoryBaseComponent } from '../../category-base.component';
import { CategoryFormService } from '../../category-form.service';

@Component({
    templateUrl: './producers.component.html',
    styleUrls: ['./producers.component.scss']
})
export class ProducersComponent extends CategoryBaseComponent<UntypedFormArray> implements OnInit {
    public id: number;
    public producerTextForm?: UntypedFormGroup;
    public producerToAdd?: number;
    public selectedProducer: number;
    public gridTab: string;

    constructor(
        readonly commonDataService: CommonDataService,
        readonly formService: CategoryFormService,
        readonly translateService: TranslateService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        super();
    }

    ngOnInit() {
        this.gridTab = this.route.parent?.snapshot.params['gridTab'] || 'tree';
        this.formService.reload$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.selectedProducer = null;
            this.producerTextForm = null;
            this.id = this.formService.id;
            this.form = this.formService.getProducers();
            this.formService.formReloadSubscriptions(
                combineLatest([this.route.params.pipe(takeUntil(this.unsubscribe)), this.formService.producersLoaded]).subscribe(([params, loaded]) => {
                    // for form reinicializations
                    if (!loaded) {
                        return;
                    }
                    if (params['producerId'] && params['producerId'] !== 'general') {
                        this.selectedProducer = Number(params['producerId']);
                    } else {
                        this.selectedProducer = null;
                    }
                    this.filterProducersToAdd();
                    if (this.selectedProducer) {
                        if (this.form.controls.find((control) => control.get('id').value === this.selectedProducer) === undefined) {
                            this.select(null);
                        } else {
                            this.select(this.selectedProducer);
                        }
                    }
                    if (!this.selectedProducer && this.form.controls.length > 0) {
                        this.select(this.form.controls[0].value['id']);
                    }
                })
            );
        });
    }

    public get isLocked() {
        return !this.formService.isEditable('producerTexts');
    }

    public get producersToAdd() {
        return this.formService.producersToAdd;
    }

    public set producersToAdd(value: Array<ISelectListItemDto>) {
        this.formService.producersToAdd = value;
    }

    unableToAddProducer() {
        return this.producerToAdd === null || this.isLocked;
    }

    select(producerId: number | null) {
        this.selectedProducer = producerId;
        if (this.selectedProducer) {
            this.router.navigate(['category', this.gridTab, this.id, 'producers', this.selectedProducer], { queryParamsHandling: 'merge' }).catch(EMPTY);
            this.producerTextForm = this.formService.getProducerText(this.selectedProducer);
        } else {
            this.producerTextForm = null;
            this.selectedProducer = null;
            this.router.navigate(['category', this.gridTab, this.id, 'producers'], { queryParamsHandling: 'merge' }).catch(EMPTY);
        }
    }

    producerInvalid(producerId: number) {
        return this.formService.producerInvalid(producerId);
    }

    getProducerState(producerId: number) {
        return this.formService.getProducerState(producerId);
    }

    addProducer() {
        if (!this.producerToAdd || this.producersToAdd.find((p) => p.id === this.producerToAdd) === undefined) {
            return false;
        }
        const group = new UntypedFormGroup({
            id: new UntypedFormControl(this.producerToAdd),
            hasTexts: new UntypedFormControl(false),
            name: new UntypedFormControl(this.producersToAdd.find((p) => p.id === this.producerToAdd).text)
        });
        this.form.controls.push(group);
        this.formService.setProducerState(group.get('id').value);
        this.select(this.producerToAdd);
        this.filterProducersToAdd();
        return true;
    }

    filterProducersToAdd() {
        this.producersToAdd = this.producersToAdd.filter((producer) => this.form.controls.every((control) => control.value['id'] !== producer.id));
        if (this.producersToAdd.findIndex((p) => p.id === this.producerToAdd) === -1) {
            this.producerToAdd = null;
        }
    }
}
