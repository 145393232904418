<app-space-background [large]="true">
    <h1 class="py-4 text-center">{{ 'Login_Title' | translate }}</h1>
    <div *ngIf="infoMessage" class="alert alert-success text-center">
        {{ infoMessage }}
    </div>
    <div *ngIf="warningMessage" class="alert alert-danger text-center">
        {{ warningMessage }}
    </div>
    <div class="row mb-5">
        <div class="col-md-6 col-employee-login">
            <h2 class="text-center">{{ 'Login_Employee' | translate }}</h2>
            <div class="description text-center">{{ 'Login_UseAlzaIdentity' | translate }}</div>
            <a [href]="'/api/accounts/external-login/microsoft?returnUrl=' + returnUrl" class="btn btn-primary block full-width" data-tid="btnExternalLogin">
                {{ 'Login_Login' | translate }}
            </a>
        </div>
        <div class="col-md-6">
            <h2 class="text-center">{{ 'Login_Externist' | translate }}</h2>
            <form [formGroup]="form" *ngIf="form" (submit)="login()">
                <div class="form-group">
                    <cms-input appInitialfocus [id]="'username'" [placeholder]="'Login_Username' | translate" formControlName="username"></cms-input>
                </div>
                <div class="form-group">
                    <cms-input [id]="'password'" [type]="'password'" [placeholder]="'Login_Password' | translate" formControlName="password">
                        <error-message errorCode="invalidPassword" [message]="'Login_WrongLogin' | translate"></error-message>
                    </cms-input>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-primary block full-width m-b" data-tid="btnLogin">
                        {{ ('Login_Login' | translate) || 'Přihlásit' }}
                    </button>
                </div>
                <div class="form-group">
                    <div class="text-center">
                        <a routerLink="/forgotten-password" data-tid="linkForgottenPassword"> {{ 'Login_ForgotPassword' | translate }} </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</app-space-background>
