<div *ngIf="form" [formGroup]="form">
    <div class="form-group">
        <cms-input
            [id]="'NewPassword'"
            [type]="'password'"
            (keyup)="scorePassword($event.target.value)"
            [placeholder]="'Login_NewPassword' | translate"
            formControlName="newPassword"
        ></cms-input>
        <div class="progress progress-mini">
            <div id="pwdBar" class="progress-bar" [ngClass]="getProgressClass()" [style.width.%]="getProgressWidth()"></div>
        </div>
        <span id="pwdStr" class="text-danger" *ngIf="passwScore > 0 && passwScore < 60">
            {{ 'PasswordStrength_Weak' | translate }}
        </span>
        <span id="pwdStr" class="text-success" *ngIf="passwScore >= 60 && passwScore < 80">
            {{ 'PasswordStrength_Good' | translate }}
        </span>
        <span id="pwdStr" class="text-primary2" *ngIf="passwScore >= 80">
            {{ 'PasswordStrength_Strong' | translate }}
        </span>
        <p id="pwdBlacklistStr" class="text-success" *ngIf="containsBlacklisted">
            {{ 'Login_NewPassword_Hint' | translate }}
        </p>
    </div>
    <div class="form-group">
        <cms-input [id]="'ConfirmPassword'" [type]="'password'" [placeholder]="'Login_NewPasswordConfirm' | translate" formControlName="confirmPassword">
            <error-message [errorCode]="'pwdMatch'" [message]="'Login_NewPassword_NotMatch' | translate"></error-message>
        </cms-input>
    </div>
    <div class="form-group">
        <div class="alert alert-info">
            <i class="fas fa-info-circle"></i>
            {{ 'Login_NewPassword_Help' | translate }}
        </div>
    </div>
</div>
