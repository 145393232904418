import { Validators } from '@alza/cms-components';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormHelper } from 'app/components/forms/form-helper.service';
import { LoginDataService } from './login-data.service';

@Injectable({
    providedIn: 'root'
})
export class ForgottenPasswordFormService {
    public form: UntypedFormGroup;

    constructor(private formHelper: FormHelper, public readonly dataService: LoginDataService) {}

    initForgottenPasswordForm() {
        this.form = new UntypedFormGroup({
            id: new UntypedFormControl(),
            username: new UntypedFormControl(null, [Validators.required])
        });
        return this.form;
    }

    getFormGroup() {
        if (this.form) {
            return this.form;
        }
        return null;
    }
}
