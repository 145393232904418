import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: './rating-upper.component.html',
    styleUrls: ['./rating-upper.component.scss']
})
export class RatingUpperComponent implements OnInit {
    public categoryId: number;

    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.parent.parent.params.subscribe((params) => {
            this.categoryId = params['id'];
        });
    }
}
