import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'app/common/constants';
import { ChangePasswordResult } from 'app/common/enums/enums';
import { DialogsService } from 'app/common/services/dialogs.service';
import { SessionService } from 'app/common/services/session.service';
import { FormHelper } from 'app/components/forms/form-helper.service';
import { switchMap } from 'rxjs/operators';
import { IPasswordDto } from '../dto/password.dto';
import { LoginDataService } from '../login-data.service';
import { ResetPasswordFormService } from '../reset-password-form.service';

@Component({
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    public form: UntypedFormGroup;
    public message: string;
    public weakPassword = false;
    public invalidToken = false;
    public username = '';

    private _token: string;

    constructor(
        private readonly translate: TranslateService,
        private readonly formHelper: FormHelper,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly dialogs: DialogsService,
        private readonly sessionService: SessionService,
        private readonly dataService: LoginDataService,
        private readonly formService: ResetPasswordFormService
    ) {
        this.form = this.formService.initResetPasswordForm();
    }

    ngOnInit() {
        this.sessionService.fullView = true;
        this.route.queryParams.pipe(switchMap((qs) => this.dataService.verifyToken(qs.token))).subscribe((verifyResult) => {
            this.invalidToken = !verifyResult.valid;
            this._token = verifyResult.token;
            this.username = verifyResult.username;
        });
    }

    ngOnDestroy() {
        this.sessionService.fullView = false;
    }

    public resetPassword() {
        if (this.form.invalid || this.weakPassword) {
            this.formService.form.markAllAsTouched();
            return;
        }
        const dto: IPasswordDto = new IPasswordDto();
        const infoForm = this.formService.getFormGroup();

        if (infoForm) {
            dto.newPassword = infoForm.value.newPassword;
            dto.token = this._token;
        }

        this.dataService.resetPassword(dto).subscribe(
            (res) => {
                this.message = '';
                switch (res.result) {
                    case ChangePasswordResult.WeakPassword:
                        this.message = this.translate.instant('Login_PasswordTooWeak');
                        this.form.reset();
                        break;
                    case ChangePasswordResult.TokenNotValid:
                        this.message = this.translate.instant('Login_ForgottenPassword_Failed');
                        break;
                    default:
                        this.navigate();
                        break;
                }
            },
            (err: HttpErrorResponse) => {
                if (err.status === 400) {
                    this.dialogs.badRequestMessage(err);
                }
            }
        );
    }

    public navigate() {
        this.router.navigate(['/login'], { queryParams: { msg: 'pwd-changed' } }).catch(EMPTY);
    }
}
