import { Validators } from '@alza/cms-components';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { handle404 } from 'app/common/operators';
import { DialogsService } from 'app/common/services/dialogs.service';
import { FormHelper, FormServiceBase } from 'app/components/forms';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CategoryProducerTranslationsDataService } from './category-producer-translations-data.service';

@Injectable()
export class CategoryProducerTranslationsFormService extends FormServiceBase<{ id: number; producerId: number; languageId: number }> {
    public get languageId() {
        return this.getKey('languageId');
    }

    public get producerId() {
        return this.getKey('producerId');
    }

    constructor(
        dialogs: DialogsService,
        translate: TranslateService,
        formHelper: FormHelper,
        public readonly dataService: CategoryProducerTranslationsDataService
    ) {
        super(dialogs, formHelper, translate);
    }

    get titleName() {
        let text = '';
        if (this.form && this.form.get('textTranslations.targetTranslation.name')) {
            text = this.form.get('textTranslations.targetTranslation.name').value;
            text = text == null ? '' : text;
        }
        return text;
    }

    getTextTranslations() {
        let group = this.form.get('textTranslations');
        if (!group) {
            group = new UntypedFormGroup({
                sourceTranslations: this.formHelper.localizationGroup(() => {
                    const group = new UntypedFormGroup({
                        seoName: new UntypedFormControl(null, Validators.maxLength(500)),
                        title: new UntypedFormControl(null, Validators.maxLength(150)),
                        upperDescription: new UntypedFormControl(null),
                        upperDescriptionAnnotation: new UntypedFormControl(null),
                        name: new UntypedFormControl(null, Validators.maxLength(50)),
                        bottomDescription: new UntypedFormControl(null),
                        bottomDescriptionAnnotation: new UntypedFormControl(null),
                        cannonical: new UntypedFormControl(null),
                        commodityBottomText: new UntypedFormControl(null, Validators.maxLength(2000)),
                        commodityFaq: new UntypedFormControl(null),
                        commodityUpperText: new UntypedFormControl(null, Validators.maxLength(2000)),
                        descriptionTag: new UntypedFormControl(null, Validators.maxLength(2000))
                    });
                    return group;
                }),
                targetTranslation: new UntypedFormGroup({
                    seoName: new UntypedFormControl(null, Validators.maxLength(500)),
                    title: new UntypedFormControl(null, Validators.maxLength(150)),
                    upperDescription: new UntypedFormControl(null, Validators.cdnImage),
                    upperDescriptionAnnotation: new UntypedFormControl(null, Validators.cdnImage),
                    name: new UntypedFormControl(null, Validators.maxLength(50)),
                    bottomDescription: new UntypedFormControl(null, Validators.cdnImage),
                    bottomDescriptionAnnotation: new UntypedFormControl(null, Validators.cdnImage),
                    cannonical: new UntypedFormControl(null),
                    commodityBottomText: new UntypedFormControl(null, [Validators.maxLength(2000), Validators.cdnImage]),
                    commodityFaq: new UntypedFormControl(null, Validators.cdnImage),
                    commodityUpperText: new UntypedFormControl(null, [Validators.maxLength(2000), Validators.cdnImage]),
                    descriptionTag: new UntypedFormControl(null, Validators.maxLength(2000))
                }),
                fieldsToHighlight: new UntypedFormGroup({
                    bottomDescription: new UntypedFormControl(false),
                    bottomDescriptionAnnotation: new UntypedFormControl(false),
                    commodityBottomText: new UntypedFormControl(false),
                    commodityFaq: new UntypedFormControl(false),
                    commodityUpperText: new UntypedFormControl(false),
                    descriptionTag: new UntypedFormControl(false),
                    name: new UntypedFormControl(false),
                    seoName: new UntypedFormControl(false),
                    title: new UntypedFormControl(false),
                    upperDescription: new UntypedFormControl(false),
                    upperDescriptionAnnotation: new UntypedFormControl(false)
                })
            });
            this.form.addControl('textTranslations', group);
            this.formHelper.disableAll(group.get('sourceTranslations'));
            this.dataService
                .loadProducerTextTranslations(this.id, this.producerId, this.languageId)
                .pipe(
                    handle404(),
                    tap((data) => {
                        if (data.fieldsToHighlight === null) {
                            delete data[this.languageId].fieldsToHighlight;
                        }
                        group.patchValue(data);
                    })
                )
                .subscribe();
        }
        return group as UntypedFormGroup;
    }

    public save(reload: boolean) {
        if (!(this.form && this.form.valid && !!this.form.get(`textTranslations.targetTranslation`))) {
            this.form.markAllAsTouched();
            return throwError(null);
        }
        const data = (this.form.get(`textTranslations.targetTranslation`) as UntypedFormGroup).getRawValue();
        return this.dataService.saveProducerTextTranslations(this.id, this.producerId, this.languageId, data).pipe(
            tap(() => {
                if (reload) {
                    this.init({ id: this.id, producerId: this.producerId, languageId: this.languageId });
                }
            }),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 400) {
                    this.dialogs.badRequestMessage(err);
                }
                return throwError(err);
            })
        );
    }
}
