import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'app/common/constants';
import { IObjectLock } from 'app/common/dto/common-dto';
import { DialogsService } from 'app/common/services/dialogs.service';
import { QueryString } from 'app/common/utils';
import { SaveButtonEvent } from 'app/components/page-footer/button-events';
import { ITab, RoutingTabManager, TabManager } from 'app/components/tabs';
import { CategoryDataService } from '../category-data.service';
import { CategoryFormService } from '../category-form.service';

@Component({
    providers: [RoutingTabManager, CategoryFormService],
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit, OnDestroy {
    public objectLock: IObjectLock;
    public gridTab = 'tree';
    public returnUrl?: string;

    public tabs: TabManager;
    constructor(
        public readonly dataService: CategoryDataService,
        private readonly dialogs: DialogsService,
        public readonly formService: CategoryFormService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private translate: TranslateService
    ) {
        this.tabs = new TabManager(translate);
        this.tabs.tabs = [
            {
                name: 'main',
                text: _('Category_Main')
            },
            {
                name: 'parameters',
                text: _('Category_Parameters')
            },
            {
                name: 'top-producer-countries',
                text: _('Category_TopProducers')
            },
            {
                name: 'images',
                text: _('Category_Images')
            },
            {
                name: 'tags',
                text: _('Category_Tags')
            },
            {
                name: 'countries',
                text: _('Category_Countries')
            },
            {
                name: 'texts',
                text: _('Category_Texts')
            },
            {
                name: 'producers',
                text: _('Category_TextProducers')
            },
            {
                name: 'rating',
                text: _('Category_Rating')
            },
            {
                name: 'history',
                text: _('Common_History')
            }
        ];
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            if (params['gridTab']) {
                this.gridTab = params['gridTab'];
            }

            QueryString.validateNumericParams(
                { throwIfNaN: true, notFoundRoute: { command: ['/', 'category', 'collection', params['gridTab']], extras: {} } },
                params['id']
            );

            if (params['id'].startsWith('new')) {
                this.formService.init({ id: 0 });
            } else {
                this.formService.init({ id: +params['id'] });
            }
            if (this.route.snapshot.firstChild) {
                this.tabs.select(this.route.snapshot.firstChild.url[0].path);
            }

            this.formService.formReloadSubscriptions(
                this.formService.objectLock$.subscribe((objectLock) => {
                    this.objectLock = objectLock;
                })
            );
        });
        this.route.queryParams.subscribe((params) => {
            if (params['returnUrl']) {
                this.returnUrl = params['returnUrl'];
            }
        });
    }

    ngOnDestroy() {
        if (this.formService.isInitialized()) {
            this.formService.unlock();
        }
    }

    public get isLocked() {
        return this.formService.isLocked;
    }

    public tabSelect(tab: string) {
        this.tabs.select(tab);
        this.router.navigate(['/', 'category', this.gridTab, this.formService.id, tab], { queryParamsHandling: 'preserve' }).catch(EMPTY);
    }

    public tabInvalid(tab: ITab) {
        return this.formService.isInitialized() && this.formService.tabInvalid(tab.name);
    }

    @HostListener('window:unload', ['$event'])
    unloadHandler() {
        this.formService.unlock();
    }

    get title() {
        if (this.formService.id === 0) {
            return this.translate.instant('Category_NewTitle') + ': ' + this.formService.titleName;
        }
        return this.translate.instant('Category_EditTitle') + ' ' + this.formService.id + ': ' + this.formService.titleName;
    }

    public save(event: SaveButtonEvent) {
        if (this.isLocked) {
            return;
        }
        if (this.formService.validate()) {
            this.formService.formSave(event.action === 'save').subscribe(() => {
                event.navigateToReturnRoute();
                this.dialogs.successMessage(this.translate.instant('Category_MsgSaved'), '');
            });
        }
    }
}
