<div *ngIf="form" class="panel-body">
    <div class="row">
        <cms-select
            *ngIf="countryForm"
            class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
            [id]="'topProducerCount'"
            name="topProducerCount"
            [labelText]="'Category_TopProducers_TopDisplayCount' | translate"
            [items]="topProducerCountValues"
            [formControl]="countryForm.get('topProducerCount')"
        ></cms-select>
    </div>
    <ng-container *ngIf="!isLocked">
        <div class="row">
            <cms-select
                *ngIf="producersList"
                [id]="'producersList'"
                name="style"
                [labelText]="'Category_TopProducers_ProducersToAdd' | translate"
                [items]="producersList"
                bindValue="id"
                bindLabel="name"
                [clearable]="true"
                class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
                [(ngModel)]="producerToAdd"
                virtualScroll="true"
            ></cms-select>
            <div class="withoutHeading">
                <app-button type="button" (click)="addItem()" [id]="'addItem'" [color]="'primary'" [disabled]="!producerToAdd">
                    <i class="fas fa-fw fa-plus"></i> {{ 'Common_Add' | translate }}
                </app-button>
            </div>
        </div>
    </ng-container>
    <form class="form" [formGroup]="form">
        <div class="row">
            <div class="col-xs-12 col-md-6">
                <ul class="list-group" id="orderList" cdkDropList (cdkDropListDropped)="onDrop($event)">
                    <li
                        *ngFor="let item of topProducersOrdered; let i = index; let odd = odd; let even = even"
                        class="list-group-item"
                        [class.odd-dnd]="odd"
                        [class.even-dnd]="even"
                        [cdkDragDisabled]="item.get('source').value == categoryTopProducerSources.Auto || isLocked"
                        cdkDrag
                        [attr.data-tid]="'row_' + i"
                        (mouseenter)="mouseOverRowIndex = i"
                        (mouseleave)="mouseOverRowIndex = -1"
                    >
                        <div class="row">
                            <div class="col-xs-4 col-sm-4">
                                <div class="dragHandle" cdkDragHandle><i class="fas fa-grip-vertical"></i></div>
                                {{ item.get('index').value }} - {{ item.get('source').value | enum : 'Category+CategoryTopProducerSource' }}
                            </div>
                            <div class="col-xs-6 col-sm-4" data-tid="topProducerName">
                                <span>{{ item.get('name').value }}</span>
                            </div>
                            <div *ngIf="!isLocked" class="col-xs-2 col-sm-4 text-right">
                                <div *ngIf="item.get('source').value == categoryTopProducerSources.Manual">
                                    <span (click)="removeItem(item.get('producerId').value)" class="remove-btn cursor-pointer" data-tid="btnRemoveTopProducer">
                                        <span class="glyphicon glyphicon-remove"></span>
                                    </span>
                                </div>
                                <div *ngIf="item.get('source').value == categoryTopProducerSources.Auto">
                                    <button class="btn btn-sm btn-primary mr-2" *ngIf="mouseOverRowIndex === i" (click)="moveProducerToManual(item)">
                                        {{ 'Category_TopProducers_MoveProducerToManual' | translate }}
                                    </button>
                                    <span class="glyphicon glyphicon-ban-circle"></span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </form>
</div>
