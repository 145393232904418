import { Validators } from '@alza/cms-components';
import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { LoginDataService } from './login-data.service';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordFormService {
    public form: UntypedFormGroup;

    constructor(public readonly dataService: LoginDataService) {}

    initResetPasswordForm() {
        this.form = new UntypedFormGroup({
            id: new UntypedFormControl(),
            newPassword: new UntypedFormControl(null, [Validators.required]),
            confirmPassword: new UntypedFormControl(null, [Validators.required, this.validateConfirmPassword.bind(this)])
        });
        return this.form;
    }

    private validateConfirmPassword(control: AbstractControl): null | ValidationErrors {
        if (!this.form) {
            return null;
        }
        const pwd = this.form.get('newPassword').value;
        const val = control.value;
        if (!pwd || !val) {
            return null;
        }
        if (pwd !== val) {
            return { pwdMatch: true };
        }
    }

    getFormGroup() {
        if (this.form) {
            return this.form;
        }
        return null;
    }
}
