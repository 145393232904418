import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { defaultCountryTranslation, EMPTY } from 'app/common/constants';
import { ICountryTranslationDto } from 'app/common/dto/common-dto';
import { CommonDataService } from 'app/common/services';
import { UrlUtils } from 'app/common/utils';
import { ITab, TabManager } from 'app/components/tabs';
import { takeUntil } from 'rxjs/operators';
import { CategoryBaseComponent } from '../../category-base.component';
import { CategoryFormService } from '../../category-form.service';

@Component({
    selector: 'app-producer-texts',
    templateUrl: './producer-texts.component.html',
    styleUrls: ['./producer-texts.component.scss']
})
export class ProducerTextsComponent extends CategoryBaseComponent implements OnInit {
    @Input()
    public form: UntypedFormGroup;
    @Input()
    public gridTab = 'tree';
    public languages: Array<ICountryTranslationDto>;
    public translationsDisabled = true;
    public textTabs: TabManager;
    public get producerId() {
        return this.form.get('producerId').value;
    }

    constructor(
        private readonly commonDataService: CommonDataService,
        private readonly formService: CategoryFormService,
        private readonly router: Router,
        readonly translateService: TranslateService
    ) {
        super();
        this.textTabs = new TabManager(translateService);
        this.textTabs.tabs = [
            {
                name: 'general',
                text: _('Category_TextsGeneralTab')
            },
            {
                name: 'upperDescription',
                text: _('Category_TextsUpperDescriptionTab')
            },
            {
                name: 'bottomDescription',
                text: _('Category_TextsBottomDescriptionTab')
            },
            {
                name: 'productInfo',
                text: _('Category_TextsProductInfoTab')
            }
        ];
    }

    ngOnInit() {
        this.commonDataService.language
            .getLanguages()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((languages) => {
                this.languages = languages.filter((language) => language.id !== defaultCountryTranslation);
            });
    }

    public get isLocked() {
        return !this.formService.isEditable('producerTexts');
    }

    public delete() {
        const state = this.form.get('$state').value;
        this.formService.deleteProducer(this.producerId);
        if (state === 'added') {
            this.router.navigate(['/', 'category', this.gridTab, this.formService.id, 'producers'], { queryParamsHandling: 'preserve' }).catch(EMPTY);
        }
    }

    public restore() {
        this.formService.restoreProducer(this.producerId);
    }

    public tabInvalid(tab: ITab) {
        if (!this.form) {
            return false;
        }
        const loc = this.form.get('localization') as UntypedFormArray;
        for (const country of Object.keys(loc.controls)) {
            switch (tab.name) {
                case 'general':
                    return (
                        this.form.get(`localization.${country}.name`).invalid ||
                        this.form.get(`localization.${country}.seoName`).invalid ||
                        this.form.get(`localization.${country}.title`).invalid ||
                        this.form.get(`localization.${country}.descriptionTag`).invalid ||
                        this.form.get(`localization.${country}.canonical`).invalid ||
                        this.form.get('canonicalCategoryId').invalid
                    );
                case 'upperDescription':
                    return (
                        this.form.get(`localization.${country}.upperDescription`).invalid ||
                        this.form.get(`localization.${country}.upperDescriptionAnnotation`).invalid
                    );
                case 'bottomDescription':
                    return (
                        this.form.get(`localization.${country}.bottomDescriptionAnnotation`).invalid ||
                        this.form.get(`localization.${country}.bottomDescription`).invalid
                    );
            }
        }
        return false;
    }

    public fillUrl() {
        this.form
            .get(`localization.${this.selectedCountry}.url`)
            .patchValue(UrlUtils.toUrlFormat(this.form.get(`localization.${this.selectedCountry}.name`).value));
    }
}
