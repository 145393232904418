import { DataSource } from '@alza/cms-components';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY } from 'app/common/constants';
import { Country } from 'app/common/enums/enums';
import { CommonDataService, MessageService } from 'app/common/services';
import { ISelectListItemDto } from 'app/models';
import { ICategoryOverviewCollectionFilterDto, ICategoryOverviewCollectionItemDto } from 'app/models/category';
import { CategoryFilterOptionsEnum } from 'app/models/enums/category';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CategoryDataService } from '../category-data.service';

@Component({
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit, OnDestroy {
    private _unsubscribe = new Subject<void>();
    public countries: Array<ISelectListItemDto> = [];
    public readonly dataSource: DataSource<ICategoryOverviewCollectionFilterDto, ICategoryOverviewCollectionItemDto>;
    items: Array<ICategoryOverviewCollectionItemDto>;
    public extendedTable = false;

    constructor(
        private readonly categoryDataService: CategoryDataService,
        private readonly commonService: CommonDataService,
        readonly http: HttpClient,
        private readonly messageService: MessageService,
        private router: Router
    ) {
        this.dataSource = new DataSource<ICategoryOverviewCollectionFilterDto, ICategoryOverviewCollectionItemDto>({
            endpoint: (p) => http.get<any>('/api/category/overview', { params: p }),
            filter: {
                sortKey: 0,
                sortDesc: false,
                countryId: Country.CzechRepublic,
                filterOption: CategoryFilterOptionsEnum.MissingUpperDescription,
                rootCategoryId: null
            }
        });
    }

    ngOnInit() {
        this.commonService.selectList.getTranslationCountries().subscribe((res) => {
            this.countries = res;
        });
        this.messageService
            .getMessage('Category_OverviewExport')
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(() => {
                this.categoryDataService.categoryOverviewExport(this.dataSource.filter);
            });
    }

    public openDetail(id: number, tab = '') {
        this.router.navigate(['/', 'category', 'overview', id, tab]).catch(EMPTY);
    }

    ngOnDestroy() {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }
}
