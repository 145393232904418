<app-page-header [title]="'Category_CollectionTitle' | translate">
    <breadcrumb [text]="'Menu_Category' | translate"></breadcrumb>
    <breadcrumb [text]="'Category_CollectionTitle' | translate" [active]="true"></breadcrumb>
</app-page-header>

<div class="wrapper-content animated fadeInRight">
    <app-tabs [active]="tabManager.selected" (activeChange)="tabManager.select($event)">
        <tab [title]="tab.text" [value]="tab.name" *ngFor="let tab of tabManager.tabs"></tab>
        <div body><router-outlet></router-outlet></div>
    </app-tabs>
</div>

<app-page-footer>
    <custom-button
        *ngIf="tabManager.isSelected('overview')"
        [iconClass]="'fas fa-download'"
        [type]="'button'"
        [color]="'success'"
        [text]="'Common_Export' | translate"
        (click)="overviewExport()"
        data-tid="btnOverviewExport"
    ></custom-button>
    <custom-button
        *ngIf="tabManager.isSelected('rating')"
        [iconClass]="'fas fa-download'"
        [type]="'button'"
        [color]="'success'"
        [text]="'Common_Export' | translate"
        (click)="ratingExport()"
        [loading]="exporting"
        data-tid="btnRatingExport"
    ></custom-button>
</app-page-footer>
