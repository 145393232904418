import { ICmsComponentsEnumProvider } from '@alza/cms-components';
import { Injectable } from '@angular/core';
import { EnumNames } from 'app/models/enums';
import { EnumsService } from './services';

@Injectable({ providedIn: 'root' })
export class AdminEnumProvider implements ICmsComponentsEnumProvider {
    constructor(private readonly enums: EnumsService) {}
    getEnum(value: string) {
        return this.enums.getEnum(value as EnumNames);
    }
}
