<app-removable-content
    [styledAs]="'none'"
    [stateControl]="form.get('$state')"
    class="removable-content"
    [class.added]="form.get('$state').value === 'added'"
    [class.deleted]="form.get('$state').value === 'deleted'"
    [restorable]="true"
    (delete)="delete()"
    (restore)="restore()"
    [disabled]="isLocked"
>
    <div *ngIf="form">
        <form class="form" [formGroup]="form">
            <div class="row">
                <div class="col-xs-12 col-md-8">
                    <app-tabs id="producer_texts" [active]="textTabs.selected" (activeChange)="textTabs.select($event)">
                        <tab [title]="tab.text" [value]="tab.name" *ngFor="let tab of textTabs.tabs" [formInvalid]="tabInvalid(tab)"></tab>
                        <div body>
                            <div *ngIf="textTabs.isSelected('general')">
                                <div class="localizationGroup">
                                    <app-localization-switch
                                        [localizedGroup]="form.get('localization')"
                                        (localizationChanged)="selectedCountry = $event"
                                        [properties]="['descriptionTag', 'canonical', 'name', 'seoName', 'title']"
                                    ></app-localization-switch>
                                    <div class="items ibox-content">
                                        <div class="row flexrow">
                                            <!-- name -->
                                            <div class="col-xs-12">
                                                <cms-checkbox
                                                    class="compact send-checkbox"
                                                    [id]="'flagName'"
                                                    [labelText]="'Category_SendToTranslate' | translate"
                                                    [formControl]="form.get('sendTextsToTranslate.fieldsToSend.name')"
                                                >
                                                </cms-checkbox>
                                                <cms-input
                                                    [id]="'name'"
                                                    [labelText]="'Category_Name' | translate"
                                                    [formControl]="localized('name')"
                                                ></cms-input>
                                            </div>
                                            <!-- seo name -->
                                            <div class="col-xs-12">
                                                <cms-checkbox
                                                    class="compact send-checkbox"
                                                    [id]="'flagSeoName'"
                                                    [labelText]="'Category_SendToTranslate' | translate"
                                                    [formControl]="form.get('sendTextsToTranslate.fieldsToSend.seoName')"
                                                >
                                                </cms-checkbox>
                                                <cms-input
                                                    [id]="'seoName'"
                                                    [labelText]="'Category_SeoName' | translate"
                                                    [formControl]="localized('seoName')"
                                                ></cms-input>
                                            </div>
                                            <!-- title -->
                                            <div class="col-xs-12">
                                                <cms-checkbox
                                                    class="compact send-checkbox"
                                                    [id]="'flagTitle'"
                                                    [labelText]="'Category_SendToTranslate' | translate"
                                                    [formControl]="form.get('sendTextsToTranslate.fieldsToSend.title')"
                                                >
                                                </cms-checkbox>
                                                <cms-input
                                                    [id]="'title'"
                                                    [labelText]="'Category_ProducerTitle' | translate"
                                                    [formControl]="localized('title')"
                                                ></cms-input>
                                            </div>
                                            <!-- description -->
                                            <div class="col-xs-12">
                                                <cms-checkbox
                                                    class="compact send-checkbox"
                                                    [id]="'flagDescriptionTag'"
                                                    [labelText]="'Category_SendToTranslate' | translate"
                                                    [formControl]="form.get('sendTextsToTranslate.fieldsToSend.descriptionTag')"
                                                >
                                                </cms-checkbox>
                                                <cms-input
                                                    [id]="'descriptionTag'"
                                                    [labelText]="'Category_DescriptionTag' | translate"
                                                    [formControl]="localized('descriptionTag')"
                                                ></cms-input>
                                            </div>
                                            <div class="col-xs-12">
                                                <div class="row">
                                                    <app-category-select-one
                                                        class="col-xs-12 col-sm-4"
                                                        [id]="'canonicalCategoryId'"
                                                        [labelText]="'Category_CanonicalCategory' | translate"
                                                        [formControl]="form.get('canonicalCategoryId')"
                                                    >
                                                        <error-message
                                                            errorCode="invalidCampaignError"
                                                            [message]="'Category_InvalidCategoryIdError' | translate"
                                                        >
                                                        </error-message>
                                                    </app-category-select-one>
                                                    <div class="col-xs-12 col-sm-8">
                                                        <cms-input
                                                            [id]="'canonical'"
                                                            [labelText]="'Category_Canonical' | translate"
                                                            [formControl]="localized('canonical')"
                                                        ></cms-input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="textTabs.isSelected('upperDescription')" class="row">
                                <div class="localizationGroup">
                                    <app-localization-switch
                                        [localizedGroup]="form.get('localization')"
                                        (localizationChanged)="selectedCountry = $event"
                                        [properties]="['upperDescriptionAnnotation', 'upperDescription']"
                                    >
                                    </app-localization-switch>
                                    <div class="items ibox-content">
                                        <div class="row flexrow">
                                            <div class="col-xs-12">
                                                <cms-checkbox
                                                    [id]="'responsiveAnnotation'"
                                                    [labelText]="'Category_ResponsiveAnnotation' | translate"
                                                    [formControl]="form.get('responsiveAnnotation')"
                                                ></cms-checkbox>
                                            </div>
                                            <div class="col-xs-12">
                                                <div class="clearfix"></div>
                                                <cms-checkbox
                                                    class="compact send-checkbox"
                                                    [id]="'flagUpperDescriptionAnnotation'"
                                                    [labelText]="'Category_SendToTranslate' | translate"
                                                    [formControl]="form.get('sendTextsToTranslate.fieldsToSend.upperDescriptionAnnotation')"
                                                >
                                                </cms-checkbox>
                                                <app-froala
                                                    [id]="'upperDescriptionAnnotation'"
                                                    [labelText]="'Category_UpperDescriptionAnnotation' | translate"
                                                    [formControl]="localized('upperDescriptionAnnotation')"
                                                >
                                                </app-froala>
                                            </div>
                                            <div class="col-xs-12">
                                                <cms-checkbox
                                                    class="compact send-checkbox"
                                                    [id]="'flagUpperDescription'"
                                                    [labelText]="'Category_SendToTranslate' | translate"
                                                    [formControl]="form.get('sendTextsToTranslate.fieldsToSend.upperDescription')"
                                                >
                                                </cms-checkbox>
                                                <app-froala
                                                    [id]="'upperDescription'"
                                                    [labelText]="'Category_UpperDescription' | translate"
                                                    [formControl]="localized('upperDescription')"
                                                ></app-froala>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="textTabs.isSelected('bottomDescription')" class="row">
                                <div class="localizationGroup">
                                    <app-localization-switch
                                        [localizedGroup]="form.get('localization')"
                                        (localizationChanged)="selectedCountry = $event"
                                        [properties]="['bottomDescriptionAnnotation', 'bottomDescription']"
                                    >
                                    </app-localization-switch>
                                    <div class="items ibox-content">
                                        <div class="row flexrow">
                                            <div class="col-xs-12">
                                                <cms-checkbox
                                                    class="compact send-checkbox"
                                                    [id]="'flagBottomDescriptionAnnotation'"
                                                    [labelText]="'Category_SendToTranslate' | translate"
                                                    [formControl]="form.get('sendTextsToTranslate.fieldsToSend.bottomDescriptionAnnotation')"
                                                >
                                                </cms-checkbox>
                                                <app-froala
                                                    [id]="'bottomDescriptionAnnotation'"
                                                    [labelText]="'Category_BottomDescriptionAnnotation' | translate"
                                                    [formControl]="localized('bottomDescriptionAnnotation')"
                                                >
                                                </app-froala>
                                            </div>
                                            <div class="col-xs-12">
                                                <cms-checkbox
                                                    class="compact send-checkbox"
                                                    [id]="'flagBottomDescription'"
                                                    [labelText]="'Category_SendToTranslate' | translate"
                                                    [formControl]="form.get('sendTextsToTranslate.fieldsToSend.bottomDescription')"
                                                >
                                                </cms-checkbox>
                                                <app-froala
                                                    [id]="'bottomDescription'"
                                                    [labelText]="'Category_BottomDescription' | translate"
                                                    [formControl]="localized('bottomDescription')"
                                                ></app-froala>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="textTabs.isSelected('productInfo')" class="row">
                                <div class="localizationGroup">
                                    <app-localization-switch
                                        [localizedGroup]="form.get('localization')"
                                        (localizationChanged)="selectedCountry = $event"
                                        [properties]="['commodityUpperText', 'commodityBottomText', 'commodityFaq']"
                                    >
                                    </app-localization-switch>
                                    <div class="items ibox-content">
                                        <div class="row flexrow">
                                            <div class="col-xs-12">
                                                <cms-checkbox
                                                    class="compact send-checkbox"
                                                    [id]="'flagCommodityUpperText'"
                                                    [labelText]="'Category_SendToTranslate' | translate"
                                                    [formControl]="form.get('sendTextsToTranslate.fieldsToSend.commodityUpperText')"
                                                >
                                                </cms-checkbox>
                                                <app-froala
                                                    [id]="'commodityUpperText'"
                                                    [labelText]="'Category_CommodityUpperText' | translate"
                                                    [formControl]="localized('commodityUpperText')"
                                                ></app-froala>
                                            </div>
                                            <div class="col-xs-12">
                                                <cms-checkbox
                                                    class="compact send-checkbox"
                                                    [id]="'flagCommodityBottomText'"
                                                    [labelText]="'Category_SendToTranslate' | translate"
                                                    [formControl]="form.get('sendTextsToTranslate.fieldsToSend.commodityBottomText')"
                                                >
                                                </cms-checkbox>
                                                <app-froala
                                                    [id]="'commodityBottomText'"
                                                    [labelText]="'Category_CommodityBottomText' | translate"
                                                    [formControl]="localized('commodityBottomText')"
                                                ></app-froala>
                                            </div>
                                            <div class="col-xs-12">
                                                <cms-checkbox
                                                    class="compact send-checkbox"
                                                    [id]="'flagCommodityFaq'"
                                                    [labelText]="'Category_SendToTranslate' | translate"
                                                    [formControl]="form.get('sendTextsToTranslate.fieldsToSend.commodityFaq')"
                                                >
                                                </cms-checkbox>
                                                <app-froala
                                                    [id]="'commodityFaq'"
                                                    [labelText]="'Category_CommodityFaq' | translate"
                                                    [formControl]="localized('commodityFaq')"
                                                ></app-froala>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </app-tabs>
                </div>
                <div class="col-xs-12 col-md-4">
                    <div class="panel panel-default mt-5" [class.disabledBlock]="form.get('sendTextsToTranslate.languageIds').disabled">
                        <div class="panel-heading">
                            <label>{{ 'Category_TranslateInLanguage' | translate }}</label>
                        </div>

                        <div class="panel-body" *ngIf="languages">
                            <cms-select
                                [id]="'languageIds'"
                                [labelText]="'Category_TranslateToLanguage' | translate"
                                [formControl]="form.get('sendTextsToTranslate.languageIds')"
                                [items]="languages"
                                bindLabel="name"
                                bindValue="id"
                                [multiple]="true"
                            ></cms-select>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</app-removable-content>
