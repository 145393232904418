import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY } from 'app/common/constants';
import { StornoButtonEvent } from 'app/components/page-footer/button-events';
import { HistoryV2DataService } from 'app/modules/history-v2/history-data.service';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: './collection.component.html',
    styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit, OnDestroy {
    public id: string;
    public source: number;
    private _sub?: Subscription;
    public get detailBreadCrumbs(): string {
        return this.historyService.detailBreadCrumbs;
    }
    constructor(private router: Router, private route: ActivatedRoute, private historyService: HistoryV2DataService) {}

    ngOnInit() {
        this._sub = this.historyService.id$.subscribe((res) => {
            setTimeout(() => {
                this.id = res.id;
                this.source = res.source;
            });
        });
    }
    ngOnDestroy() {
        if (this._sub) {
            this._sub.unsubscribe();
        }
    }

    public stornoClick(event: StornoButtonEvent) {
        event.preventDefault();
        this.router.navigate(['/v2-audit'], { queryParams: { restoreFilter: true } }).catch(EMPTY);
    }
}
