import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { AuthGuard } from 'app/common/auth-guard';
import { ComponentsModule } from 'app/components/components.module';
import { CollectionComponent } from './collection/collection.component';
import { CompatibleParameterValuesDataService } from './compatible-parameter-values-data.service';
import { DetailComponent } from './detail/detail.component';
import { FindRelatedParameterModalComponent } from './find-related-parameter-modal/find-related-parameter-modal.component';

const routes: Routes = [
    {
        path: 'CompatibleParameterValues',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: CollectionComponent
            },
            {
                path: 'Index',
                component: CollectionComponent
            },
            {
                path: ':id',
                component: DetailComponent
            }
        ]
    }
];

@NgModule({
    imports: [ComponentsModule, RouterModule.forChild(routes), TranslateModule],
    declarations: [CollectionComponent, DetailComponent, FindRelatedParameterModalComponent],

    providers: [CompatibleParameterValuesDataService]
})
export class CompatibleParameterValuesModule {}
