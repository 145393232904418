import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DefaultUrlSerializer, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ExceptionType } from 'app/models/enums';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EMPTY } from './constants';
import { DialogsService } from './services/dialogs.service';

@Injectable()
export class ErrorHandlingHttpInterceptor implements HttpInterceptor {
    constructor(
        private readonly router: Router,
        private readonly dialogs: DialogsService,
        private readonly translate: TranslateService,
        private readonly matDialog: MatDialog
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                    switch (err.status) {
                        case 500:
                            switch (err.error.exceptionType) {
                                case ExceptionType.DatabaseTimeout: {
                                    this.dialogs.warningMessage(this.translate.instant('Common_TimeoutError'), '');
                                    break;
                                }
                                case ExceptionType.K2Integration: {
                                    this.dialogs.warningMessage(this.translate.instant('Common_K2IntegrationError'), '');
                                    break;
                                }
                                case ExceptionType.CustomerLoans: {
                                    this.dialogs.warningMessage(this.translate.instant('Common_CustomerLoansError'), '');
                                    break;
                                }
                                default: {
                                    this.dialogs.errorMessage(this.translate.instant('Common_InternalServerError'), '');
                                    break;
                                }
                            }
                            break;
                        case 403:
                            this.matDialog.closeAll();
                            this.router.navigate(['error/forbidden']).catch(EMPTY);
                            break;
                        case 401:
                            if (req.url !== 'api/session/user' && !this.router.url.startsWith('/login')) {
                                const urlTree = new UrlTree();
                                urlTree.root = new UrlSegmentGroup([new UrlSegment('login', {})], {});
                                urlTree.queryParams = {
                                    returnUrl: this.router.url
                                };
                                const urlSerializer = new DefaultUrlSerializer();
                                const url = urlSerializer.serialize(urlTree);
                                window.location.href = url;
                            }
                            break;
                        case 409:
                            this.dialogs.errorMessage(
                                this.translate.instant('Common_UpdateConcurrencyError'),
                                this.translate.instant('Common_UpdateConcurrencyErrorBody')
                            );
                            break;
                    }
                }
                return throwError(err);
            })
        );
    }
}
