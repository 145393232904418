import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { SessionService } from './services';

@Injectable({
    providedIn: 'root'
})
export class AppInsightsMonitoringService {
    private _appInsights: ApplicationInsights;
    private _loaded = false;
    constructor(session: SessionService) {
        session.user$.subscribe((user) => {
            if (user?.applicationInsights?.instrumentationKey) {
                this._appInsights = new ApplicationInsights({
                    config: {
                        instrumentationKey: user.applicationInsights.instrumentationKey,
                        enableAutoRouteTracking: true // option to log all route changes
                    }
                });
                this._appInsights.loadAppInsights();
                this._loaded = true;
            }
        });
    }

    logPageView(name?: string, url?: string) {
        if (!this._loaded) {
            return;
        }
        // option to call manually
        this._appInsights.trackPageView({
            name,
            uri: url
        });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        if (!this._loaded) {
            return;
        }
        this._appInsights.trackEvent({ name }, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        if (!this._loaded) {
            return;
        }
        this._appInsights.trackMetric({ name, average }, properties);
    }

    logException(exception: Error, severityLevel?: number) {
        if (!this._loaded) {
            return;
        }
        this._appInsights.trackException({ exception, severityLevel });
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        if (!this._loaded) {
            return;
        }
        this._appInsights.trackTrace({ message }, properties);
    }
}
