<app-page-header [title]="title" *ngIf="formService.isInitialized()">
    <breadcrumb [text]="'Menu_Category' | translate"></breadcrumb>
    <breadcrumb [link]="'/category'" [text]="'Category_CollectionTitle' | translate"></breadcrumb>
    <breadcrumb [active]="true" [text]="title"></breadcrumb>
</app-page-header>

<app-object-locked [data]="objectLock" *ngIf="formService.isInitialized() && objectLock && isLocked"></app-object-locked>
<div class="wrapper wrapper-content animated fadeInRight">
    <app-tabs id="mainTabs" [active]="tabs.selected" (activeChange)="tabSelect($event)">
        <tab [title]="tab.text" [value]="tab.name" *ngFor="let tab of tabs.tabs" [formInvalid]="tabInvalid(tab)"></tab>
        <div body><router-outlet></router-outlet></div>
    </app-tabs>
</div>
<app-page-footer [returnRoute]="returnUrl || ['/category', 'collection', gridTab]" *ngIf="formService.isInitialized()">
    <storno-button></storno-button>
    <save-button [disabled]="isLocked" type="submit" (click)="save($event)"></save-button>
    <save-and-close-button [disabled]="isLocked" type="submit" (click)="save($event)"></save-and-close-button>
</app-page-footer>
