import { Component, OnInit } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { MessageService } from 'app/common/services';
import { RoutingTabManager } from 'app/components/tabs';
import { Subject, takeUntil } from 'rxjs';

@Component({
    providers: [RoutingTabManager],
    templateUrl: './collection.component.html',
    styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit {
    public exporting = false;
    private _unsubscribe = new Subject<void>();

    constructor(private readonly messageService: MessageService, public readonly tabManager: RoutingTabManager) {
        this.tabManager.tabs = [
            {
                name: 'tree',
                text: _('Category_TreeGrid')
            },
            {
                name: 'overview',
                text: _('Category_OverviewGrid')
            },
            {
                name: 'rating',
                text: _('Category_Rating')
            }
        ];
    }

    ngOnInit() {
        this.messageService
            .getMessage('Category_Rating_Exported')
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(() => {
                this.exporting = false;
            });
    }

    overviewExport() {
        this.messageService.sendMessage('Category_OverviewExport');
    }

    ratingExport() {
        this.exporting = true;
        this.messageService.sendMessage('Category_RatingExport');
    }
}
