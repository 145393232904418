import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IClientSettingsDto } from 'app/models/common';

@Injectable({ providedIn: 'root' })
export class ClientSettingsService {
    public get value() {
        return this.#value;
    }

    #value: IClientSettingsDto;

    constructor(private readonly http: HttpClient) {}

    public initialize() {
        return this.http
            .get<IClientSettingsDto>('/api/common/client-settings')
            .toPromise()
            .then((res) => {
                this.#value = res;
            });
    }
}
