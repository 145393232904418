import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { handle404, selectIdParam } from 'app/common/operators';
import { DialogsService } from 'app/common/services/dialogs.service';
import { StringUtils } from 'app/common/utils';
import { filter } from 'rxjs/operators';
import { CompatibleParameterValuesDataService } from '../compatible-parameter-values-data.service';
import { ICompatibleParameterValuesDto, IParameterDto, IParameterValueDto } from '../compatible-parameter-values-dto';
import { FindRelatedParameterModalComponent } from '../find-related-parameter-modal/find-related-parameter-modal.component';

const pageSize = 15;

@Component({
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
    public id: number;
    public selected: IParameterValueDto;
    public data: ICompatibleParameterValuesDto;
    public parametersForSelected: Array<IParameterDto>;
    public paginatedParameterValues: Array<IParameterValueDto> = [];
    public filteredParameterValues: Array<IParameterValueDto> = [];
    public pageSize = pageSize;
    public currentPage = 1;
    public totalPages = 1;
    public parameterValueFilter = '';

    constructor(
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private dialogs: DialogsService,
        private dataService: CompatibleParameterValuesDataService,
        private readonly matDialog: MatDialog
    ) {}

    ngOnInit() {
        this.route.params.pipe(selectIdParam({ throwIfNaN: true })).subscribe((params) => {
            this.id = +params['id'];

            this.dataService
                .getCompatibleParameterValues(this.id)
                .pipe(handle404())
                .subscribe((res) => {
                    this.data = res;
                    this.filterParameterValues();
                });
        });
    }

    public filterParameterValues() {
        if (this.parameterValueFilter?.trim().length > 0) {
            const filterNumeric = parseInt(this.parameterValueFilter, 10);
            if (filterNumeric === Number(this.parameterValueFilter)) {
                this.filteredParameterValues = this.data.mainParameter.values.filter((v) => v.id === filterNumeric);
            } else {
                this.filteredParameterValues = this.data.mainParameter.values.filter((v) =>
                    StringUtils.removeAccents(v.name.trim().toLowerCase()).includes(StringUtils.removeAccents(this.parameterValueFilter.trim().toLowerCase()))
                );
            }
        } else {
            this.filteredParameterValues = this.data.mainParameter.values;
        }
        this.countPagination();
    }

    private countPagination() {
        this.totalPages = Math.ceil(this.filteredParameterValues.length / this.pageSize);
        if (this.currentPage > this.totalPages) {
            this.currentPage = 1;
        }
        this.paginate();
    }

    public setPage(newPage: number) {
        this.currentPage = newPage;
        this.paginate();
    }

    private paginate() {
        this.paginatedParameterValues = this.filteredParameterValues.slice(
            this.currentPage * this.pageSize - this.pageSize,
            this.pageSize * this.currentPage - 1
        );
    }

    save() {
        this.dataService.saveCompatibleParameterValues(this.data).subscribe(() => {
            this.dialogs.successMessage(this.translateService.instant('CompatibleParameterValues_SaveSuccessful'), '');
        });
    }

    selectPairedValue(event: any, id: number) {
        if (this.data.pairedValues[this.selected.id] === undefined) {
            this.data.pairedValues[this.selected.id] = Array(id);
        } else {
            const index = this.data.pairedValues[this.selected.id].indexOf(id);
            if (index === -1) {
                this.data.pairedValues[this.selected.id].push(id);
                event.target.checked = true;
            } else {
                this.data.pairedValues[this.selected.id].splice(index, 1);
                event.target.checked = false;
            }
        }
    }

    private getAllPairedValueIds() {
        const allPairedIds: Array<number> = [];
        this.data.mainParameter.values.forEach((value) => {
            if (this.data.pairedValues[value.id]) {
                this.data.pairedValues[value.id].forEach((pairedId) => {
                    if (allPairedIds.indexOf(pairedId) >= 0) {
                        return;
                    }
                    allPairedIds.push(pairedId);
                });
            }
        });
        return allPairedIds;
    }

    selectMainParameterValue(item: IParameterValueDto) {
        scrollTo(undefined, 0);
        this.selected = item;
        if (this.data.pairedValues[item.id] === undefined) {
            this.data.pairedValues[item.id] = [];
        }
        // it was originally called only for this.data.pairedValues[item.id]
        // but we want to show all enums that are used in other tabs
        // so that the user doesn't have to add them in each one of those
        this.dataService.getRelatedParametersByValues(this.getAllPairedValueIds()).subscribe((res) => (this.parametersForSelected = res));
    }

    addPairableParameter() {
        this.matDialog
            .open<FindRelatedParameterModalComponent>(FindRelatedParameterModalComponent, {
                data: {
                    filteredParameterId: this.id
                },
                panelClass: 'modal-lg'
            })
            .afterClosed()
            .pipe(filter((x) => !!x))
            .subscribe((enumId) => {
                this.dataService.getParameter(enumId).subscribe((res) => this.parametersForSelected.push(res));
            });
    }
}
