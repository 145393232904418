<app-space-background>
    <h1 class="py-4 text-center">{{ 'Login_EmailConfirmedHeader' | translate }}</h1>
    <div class="alert alert-danger" *ngIf="failedMessage">
        {{ failedMessage }}
    </div>
    <div class="alert alert-success" *ngIf="successMessage">
        {{ successMessage }}
    </div>

    <div class="form-group">
        <a routerLink="/dashboard" class="btn btn-primary block full-width m-b">
            {{ 'Login_ConfirmEmail_Continue' | translate }}
        </a>
    </div>
</app-space-background>
