import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY } from 'app/common/constants';

@Component({
    templateUrl: './category-rating.component.html',
    styleUrls: ['./category-rating.component.scss']
})
export class CategoryRatingComponent {
    constructor(private router: Router) {}

    public ratingClick(id: number) {
        this.router.navigate(['/', 'category', 'rating', id, 'rating']).catch(EMPTY);
    }
}
