import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogsService } from 'app/common/services';
import { IParameterValues } from 'app/components/parameter-values/models';
import { ICategoryParametersDto } from 'app/models/category';
import { IParameterFilterDto } from 'app/models/parameter';
import { takeUntil } from 'rxjs/operators';
import { CategoryBaseComponent } from '../../category-base.component';
import { CategoryDataService } from '../../category-data.service';
import { CategoryFormService } from '../../category-form.service';
import { IParameterModal } from './models';
import { ParameterModalComponent } from './parameter-modal/parameter-modal.component';
import { ParameterUrlModalComponent } from './parameter-url-modal/parameter-url-modal.component';

@Component({
    selector: 'app-parameters',
    templateUrl: './parameters.component.html',
    styleUrls: ['./parameters.component.scss']
})
export class ParametersComponent extends CategoryBaseComponent implements OnInit {
    public parameterFilter: Array<IParameterFilterDto>;
    public parameterInfoControl: UntypedFormControl;

    constructor(
        private readonly formService: CategoryFormService,
        private readonly dataService: CategoryDataService,
        private readonly dialogs: DialogsService,
        private readonly translate: TranslateService,
        private readonly matDialog: MatDialog
    ) {
        super();
    }

    ngOnInit() {
        this.formService.reload$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.form = this.formService.getParameters();
            this.parameterInfoControl = this.formService.getParameterInfo();
            this.parameterFilter = this.form.get('parameterFilters').value;
            this.formService
                .getParameterFilters()
                .valueChanges.pipe(takeUntil(this.unsubscribe))
                .subscribe((values) => {
                    this.parameterFilter = values;
                });
        });
    }

    public get isReadOnly() {
        return this.formService.isLocked || this.form?.disabled;
    }

    public get parameterProducers() {
        return this.formService.parameterProducers;
    }

    public openParameterModal() {
        const data: IParameterModal = {
            categoryId: this.form.get('sourceCategoryId').value,
            parameterValues: this.formService.getParameterValuesForModal(this.form.get('parameterFilters') as UntypedFormArray)
        };
        this.matDialog
            .open<ParameterModalComponent, IParameterModal, IParameterValues>(ParameterModalComponent, { data, panelClass: 'modal-xl' })
            .afterClosed()
            .subscribe((res) => {
                console.log(res);
                if (res) {
                    this.fillNewParameterValues(res);
                }
            });
    }

    public openUrlModal() {
        this.matDialog
            .open<ParameterUrlModalComponent, void, string>(ParameterUrlModalComponent, {
                panelClass: 'modal-xl'
            })
            .afterClosed()
            .subscribe((res) => {
                if (res) {
                    this.processUrl(res);
                }
            });
    }

    processUrl(url: string) {
        if (url) {
            this.dataService.getParametersFromUrl(url).subscribe(
                (res) => {
                    this.fillFromUrl(res);
                },
                (err: HttpErrorResponse) => {
                    this.dialogs.badRequestMessage(err);
                }
            );
        } else {
            this.dialogs.badRequestMessage(this.translate.instant('Category_Parameters_BadUrl', null));
        }
    }

    private fillFromUrl(newParameters: ICategoryParametersDto) {
        this.form.patchValue({ sourceCategoryId: newParameters.sourceCategoryId }); // triggers onvaluechange cleanup
        this.form.patchValue({
            inStock: newParameters.inStock,
            priceFrom: newParameters.priceFrom,
            priceTo: newParameters.priceTo,
            producers: newParameters.producers
        });
        this.fillNewParameterValuesFromFilter(newParameters.parameterFilters);
    }

    public removeParameter(id: number) {
        const parameterFilters = this.formService.getParameterFilters();
        const index = parameterFilters.controls.findIndex((c) => c.value['id'] === id);
        const enumId = parameterFilters.controls[index].get('filterInfo.typeEnumId').value;
        const parentEnumId = parameterFilters.controls[index].get('filterInfo.typeEnumParentId').value;
        this.delete(parameterFilters, index);

        if (enumId) {
            const childParameters = parameterFilters.controls.filter((x) => x.get('filterInfo.typeEnumParentId').value === enumId);

            if (childParameters?.length) {
                childParameters.forEach((x) => this.removeParameter(x.get('id').value));
            }

            const parentParameter = parameterFilters.controls.find((x) => x.get('filterInfo.typeEnumId').value === parentEnumId);
            const siblingParameters = parameterFilters.controls.filter((x) => x.get('filterInfo.typeEnumParentId').value === parentEnumId);

            if (!siblingParameters?.length && parentParameter) {
                this.removeParameter(parentParameter.get('id').value);
            }
        }
    }

    public redirectToWeb() {
        const urlData = this.form.getRawValue();
        urlData['parameterFilters'] = this.formService.getParameterFilters().value.filter((v) => v.$state !== 'deleted');
        this.dataService.categoryUrlBuild(urlData).subscribe((res: string) => window.open(res, '_blank'));
    }

    public fillNewParameterValuesFromFilter(parameterFilters: Array<IParameterFilterDto>) {
        const parametersFormArray = this.formService.getParameterFilters();
        this.formService.clearParameterFilters();
        parameterFilters.forEach((parameterFilter) => {
            let controlIndex = parametersFormArray.controls.findIndex(
                (c) =>
                    c.value['parameterTypeGroupId'] === parameterFilter.parameterTypeGroupId &&
                    c.value['parameterTypeEnumId'] === parameterFilter.parameterTypeEnumId &&
                    c.value['value'] === parameterFilter.value
            );
            let control: UntypedFormGroup;
            if (controlIndex < 0) {
                control = this.formService.createParameterControl();
                parametersFormArray.push(control);
                controlIndex = parametersFormArray.length - 1;
            } else {
                control = parametersFormArray.controls[controlIndex] as UntypedFormGroup;
            }
            let newState = control.get('$state').value;
            newState = newState === 'added' ? 'added' : 'modified';
            control.patchValue({
                $state: newState,
                cableLeftTypeEnumId: parameterFilter.cableLeftTypeEnumId,
                cableRightTypeEnumId: parameterFilter.cableRightTypeEnumId,
                filterInfo: parameterFilter.filterInfo,
                parameterTypeGroupId: parameterFilter.parameterTypeGroupId,
                parameterTypeEnumId: parameterFilter.parameterTypeEnumId,
                value: parameterFilter.value,
                valueFrom: parameterFilter.valueFrom,
                valueTo: parameterFilter.valueTo,
                fiterInfo: parameterFilter.filterInfo
            });
        });
    }

    private findOrCreateControl(controlFilter: (c: AbstractControl) => boolean) {
        const parametersFormArray = this.formService.getParameterFilters();
        let controlIndex = parametersFormArray.controls.findIndex(controlFilter);
        let control: UntypedFormGroup;
        if (controlIndex < 0) {
            control = this.formService.createParameterControl();
            parametersFormArray.push(control);
            controlIndex = parametersFormArray.length - 1;
        } else {
            control = parametersFormArray.controls[controlIndex] as UntypedFormGroup;
        }
        let newState = control.get('$state').value;
        newState = newState === 'added' ? 'added' : 'modified';
        this.formService.fillParameterControl(
            control,
            {
                $state: newState
            },
            true
        );
        return control;
    }

    public fillNewParameterValues(data: IParameterValues) {
        this.formService.clearParameterFilters();
        Object.keys(data).forEach((key) => {
            const groupId = Number(key);
            const res = data[groupId];
            if (res) {
                if (res.enumIds && res.enumIds.length > 0) {
                    res.enumIds.forEach((val) => {
                        const control = this.findOrCreateControl(
                            (c) => c.value['parameterTypeEnumId'] === val.enumId && c.value['parameterTypeGroupId'] === groupId
                        );
                        this.formService.fillParameterControl(control, {
                            parameterTypeGroupId: groupId,
                            parameterTypeEnumId: val.enumId
                        });
                    });
                } else if (res.values && res.values.length > 0) {
                    res.values.forEach((val) => {
                        // each value has its own formcontrol (so we remove value 2 and add value 3 instead of updating value 2 to value 3 )
                        const control = this.findOrCreateControl((c) => c.value['value'] === val.value && c.value['parameterTypeGroupId'] === groupId);
                        this.formService.fillParameterControl(control, {
                            parameterTypeGroupId: groupId,
                            value: val.value
                        });
                    });
                } else if (res.cableLeft || res.cableRight || res.valueFrom || res.valueTo) {
                    // cables, and fromtos are unique by groupid
                    const control = this.findOrCreateControl((c) => groupId === c.value['parameterTypeGroupId']);
                    this.formService.fillParameterControl(control, {
                        parameterTypeGroupId: groupId,
                        valueFrom: res.valueFrom,
                        valueTo: res.valueTo,
                        cableLeftTypeEnumId: res.cableLeft,
                        cableRightTypeEnumId: res.cableRight
                    });
                }
            }
        });
    }
}
