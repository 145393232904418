import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonDataService } from 'app/common/services/common-data.service';
import { ICategoryDto, ICompatibleParameterValuesDto, IParameterDto, IParameterGroupDto } from './compatible-parameter-values-dto';

@Injectable()
export class CompatibleParameterValuesDataService {
    public persistentCategoryId = 1;
    public persistentGroupId: number | null = null;

    constructor(private http: HttpClient, private commonData: CommonDataService) {}

    saveCompatibleParameterValues(data: ICompatibleParameterValuesDto) {
        return this.http.post<any>('/api/compatible-parameter-values/save-compatible-parameter-values/', data);
    }

    getCategories(parentId: number) {
        return this.http.get<Array<ICategoryDto>>('/api/compatible-parameter-values/load-categories/', {
            params: new HttpParams().set('parentId', parentId.toString())
        });
    }

    getCategoryGroups(categoryId: number) {
        return this.http.get<Array<IParameterGroupDto>>('/api/compatible-parameter-values/load-category-groups/', {
            params: new HttpParams().set('categoryId', categoryId.toString())
        });
    }
    getCompatibleParameterValues(id: number) {
        return this.http.get<ICompatibleParameterValuesDto>('/api/compatible-parameter-values/load-compatible-parameter-values/', {
            params: new HttpParams().set('id', id.toString())
        });
    }

    getGroupParameters(groupId: number) {
        return this.http.get<Array<IParameterDto>>('/api/compatible-parameter-values/load-group-parameters/', {
            params: new HttpParams().set('groupId', groupId.toString())
        });
    }

    getParameter(id: number) {
        return this.http.get<IParameterDto>('/api/compatible-parameter-values/get-parameter/', { params: new HttpParams().set('id', id.toString()) });
    }

    getPath(categoryId: number) {
        return this.http.get<Array<ICategoryDto>>('/api/compatible-parameter-values/load-category-path/', {
            params: new HttpParams().set('categoryId', categoryId.toString())
        });
    }

    getRelatedParametersByValues(relatedValueIds: Array<number>) {
        return this.http.post<Array<IParameterDto>>('/api/compatible-parameter-values/parameters-by-values/', relatedValueIds);
    }
}
