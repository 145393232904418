<form (ngSubmit)="dataSource.reload()">
    <div class="row">
        <cms-input
            name="searchValue"
            [id]="'searchValue'"
            [(ngModel)]="dataSource.filter.searchValue"
            [labelText]="'Common_Search_Heading' | translate"
            [placeholder]="'Common_SearchPlaceholder' | translate"
            class="col-xs-12 col-sm-12 col-md-6 col-lg-3"
        >
            <label left-addon class="input-group-addon fa-primary-hover" for="searchValue"> <i class="fas fa-search"></i> </label>
        </cms-input>
        <cms-select
            [id]="'appcountryId'"
            [(ngModel)]="dataSource.filter.countryId"
            name="appcountryId"
            [items]="countries"
            bindLabel="text"
            bindValue="id"
            [labelText]="'Common_Country' | translate"
            [multiple]="false"
            class="col-xs-12 col-md-4 col-lg-3"
        >
        </cms-select>
        <cms-select
            [id]="'appFilterOptions'"
            [(ngModel)]="dataSource.filter.filterOption"
            name="appFilterOptions"
            enum="Category+CategoryFilterOptionsEnum"
            sortByLabel="true"
            [labelText]="'Category_FilterType' | translate"
            [multiple]="false"
            class="col-xs-12 col-md-4 col-lg-3"
        >
        </cms-select>
        <app-category-select-one
            [id]="'appRootCategories'"
            [(ngModel)]="dataSource.filter.rootCategoryId"
            name="appRootCategories"
            [labelText]="'Category_CollectionTitle' | translate"
            placeholder="{{ 'Admin_Common_all' | translate }}"
            class="col-xs-12 col-md-4 col-lg-3 prewrap"
        >
        </app-category-select-one>
        <cms-select
            [id]="'isResponsiveAnnotation'"
            name="isResponsiveAnnotation"
            class="col-xs-12 col-md-4 col-lg-3"
            [labelText]="'Category_IsResponsiveAnnotation' | translate"
            placeholder="{{ 'Common_all' | translate }}"
            [(ngModel)]="dataSource.filter.isResponsiveAnnotation"
            [items]="[
                {
                    text: 'Category_IsResponsiveAnnotation_OnlyUnresponsive' | translate,
                    value: false
                },
                {
                    text: 'Category_IsResponsiveAnnotation_OnlyResponsive' | translate,
                    value: true
                }
            ]"
            bindLabel="text"
            bindValue="value"
            clearable="true"
        ></cms-select>
        <app-segment-select
            [id]="'segmentId'"
            [(ngModel)]="dataSource.filter.segmentId"
            name="segmentId"
            [inputSize]="[12, 12, 4, 3, 3]"
            [breakLabelOnOverflow]="true"
        ></app-segment-select>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 withoutHeading">
            <div class="form-group btn-group" role="group">
                <button type="submit" data-tid="btnSearch" class="btn btn-primary">
                    {{ 'Common_Search' | translate }}
                </button>
                <button type="button" data-tid="btnClear" class="btn btn-secondary" (click)="dataSource.resetFilter()">
                    {{ 'Common_ClearSearch' | translate }}
                </button>
            </div>
        </div>
    </div>
</form>

<div class="row mrgt-10">
    <div class="col-xs-12 col-sm-12">
        <div class="switch pull-right" [cmsTooltip]="'Category_Collection_Extended_TableTooltip' | translate">
            <div class="onoffswitch">
                <input type="checkbox" data-tid="ooColumns" [(ngModel)]="extendedTable" class="onoffswitch-checkbox" />
                <label class="onoffswitch-label" (click)="extendedTable = !extendedTable">
                    <span class="onoffswitch-inner"></span> <span class="onoffswitch-switch"></span>
                </label>
            </div>
        </div>
    </div>
</div>
<cms-data-grid [rowContextMenu]="rowContextMenu" [dataSource]="dataSource" (rowClick)="openDetail($event.id)">
    <cms-column size="minmax(80px, max-content)" [header]="'Common_Id' | translate" field="id" [sortIndex]="1"></cms-column>
    <cms-column size="minmax(150px, 1fr)" [header]="'Common_Name' | translate" field="name" [sortIndex]="0"></cms-column>
    <cms-column
        size="minmax(80px, 150px)"
        [header]="'Category_AproximateCommodityCount' | translate"
        field="approximateCommodityCount"
        [sortIndex]="2"
    ></cms-column>
    <cms-column size="minmax(150px, 1fr)" [header]="'Category_Collection_Type' | translate" field="type"></cms-column>
    <cms-column size="minmax(200px, 1fr)" [header]="'Category_Collection_Url' | translate" field="url"></cms-column>
    <cms-column size="minmax(100px, 200px)" [header]="'Category_Collection_Segment1' | translate" field="segment1"></cms-column>
    <cms-column size="minmax(100px, 200px)" [header]="'Category_Collection_Segment2' | translate" field="segment2"></cms-column>
    <cms-column size="minmax(100px, 200px)" [header]="'Category_Collection_Segment3' | translate" field="segment3"></cms-column>
    <cms-column size="70px" [header]="'Category_Collection_IsSeoCategory' | translate" field="isSeoCategory" [type]="'bool'" align="center"></cms-column>
    <cms-column size="minmax(150px, max-content)" [header]="'Category_Collection_SectionName' | translate" field="sectionName" [sortIndex]="3"></cms-column>
    <cms-column
        size="80px"
        [header]="'Category_Collection_HideInProduction' | translate"
        field="hideInProduction"
        [type]="'bool'"
        align="center"
        *ngIf="extendedTable"
    ></cms-column>
    <cms-column
        size="80px"
        [header]="'Category_Collection_IsComparable' | translate"
        field="isComparable"
        [type]="'bool'"
        align="center"
        *ngIf="extendedTable"
    ></cms-column>
    <cms-column
        size="80px"
        [header]="'Category_Collection_UpperDescriptionShouldBeFilled' | translate"
        field="upperDescriptionShouldBeFilled"
        [type]="'bool'"
        align="center"
        *ngIf="extendedTable"
    ></cms-column>
    <cms-column
        size="80px"
        [header]="'Category_Collection_ParametersRequired' | translate"
        field="parametersRequired"
        [type]="'bool'"
        align="center"
        *ngIf="extendedTable"
    ></cms-column>
    <cms-column
        size="80px"
        [header]="'Category_Collection_ShowRecommendedProducts' | translate"
        field="showRecommendedProducts"
        [type]="'bool'"
        align="center"
        *ngIf="extendedTable"
    ></cms-column>
    <cms-column
        size="80px"
        [header]="'Category_Collection_EnableAutoGeneratedPages' | translate"
        field="enableAutoGeneratedPages"
        [type]="'bool'"
        align="center"
        *ngIf="extendedTable"
    ></cms-column>
    <cms-column
        size="80px"
        [header]="'Category_Collection_IsParametricGenerated' | translate"
        field="isParametricGenerated"
        [type]="'bool'"
        align="center"
        *ngIf="extendedTable"
    ></cms-column>
    <cms-column
        size="80px"
        [header]="'Category_Collection_PrefixCount' | translate"
        field="prefixCount"
        [type]="'number'"
        align="center"
        *ngIf="extendedTable"
    ></cms-column>
    <cms-column
        size="80px"
        [header]="'Category_Collection_CountOfH2' | translate"
        field="countOfH2"
        [type]="'number'"
        align="center"
        *ngIf="extendedTable"
    ></cms-column>
    <cms-column
        size="80px"
        [header]="'Category_Collection_FilledInSearchRedirect' | translate"
        field="filledInSearchRedirect"
        [type]="'bool'"
        align="center"
        *ngIf="extendedTable"
    ></cms-column>
    <cms-column
        size="80px"
        [header]="'Category_Collection_IsCommodityBottomText' | translate"
        field="isCommodityBottomText"
        [type]="'bool'"
        align="center"
        *ngIf="extendedTable"
    ></cms-column>
    <cms-column size="150px" [header]="'Category_Collection_Title' | translate" field="title" [type]="'text'" align="center" *ngIf="extendedTable"></cms-column>
    <cms-column
        size="80px"
        [header]="'Category_Collection_ExistsParameters' | translate"
        field="existsParameters"
        [type]="'bool'"
        align="center"
        *ngIf="extendedTable"
    ></cms-column>
    <cms-column
        size="80px"
        [header]="'Category_Collection_ExistsParametricParameter' | translate"
        field="existsParametricParameter"
        [type]="'bool'"
        align="center"
        *ngIf="extendedTable"
    ></cms-column>
    <cms-column
        size="80px"
        [header]="'Category_Collection_ParamsInLeftMenuCount' | translate"
        field="paramsInLeftMenuCount"
        [type]="'number'"
        align="center"
        *ngIf="extendedTable"
    ></cms-column>
    <cms-column
        size="80px"
        [header]="'Category_Collection_TopProducerCount' | translate"
        field="topProducerCount"
        [type]="'number'"
        align="center"
        *ngIf="extendedTable"
    ></cms-column>

    <ng-template let-item #colBanned>
        <span>
            <i class="fas fa-fw fa-times text-danger" *ngIf="!item.isBanned"></i>
            <i class="fas fa-fw fa-check text-navy" *ngIf="item.isBanned"></i>
        </span>
    </ng-template>
</cms-data-grid>

<context-menu #rowContextMenu>
    <ng-template contextMenuItem let-value [passive]="true">
        <div class="context-id text-overflow" style="width: 200px">{{ value?.id }} - {{ value?.name }}</div>
    </ng-template>
    <ng-template contextMenuItem divider="true"></ng-template>

    <ng-template contextMenuItem let-value (execute)="openDetail($event.value.id)">
        <span class="contextIcon"> <i class="fas fa-edit fa-col-primary"></i> </span>
        <span> {{ 'Category_Texts' | translate }} </span>
    </ng-template>

    <ng-template contextMenuItem let-value (execute)="openDetail($event.value.id, 'history')">
        <span class="contextIcon"> <i class="fas fa-history fa-col-success"></i> </span>
        <span>{{ 'Common_History' | translate }} </span>
    </ng-template>
</context-menu>
