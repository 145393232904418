<div mat-dialog-title>
    <button type="button" class="close" mat-dialog-close><span aria-hidden="true">×</span></button>
    <h4>{{ 'CompatibleParameterValues_AddParameterModal' | translate }}</h4>
</div>
<mat-dialog-content>
    <div class="breadcrumbs">
        <span *ngFor="let category of categoryPath.path" [attr.data-tid]="'breAdd_' + category.name" (click)="categorySelected = category">
            / {{ category.name }}
        </span>
    </div>

    <div class="row">
        <div *ngIf="categoryList" class="col-xs-4">
            <h2>{{ 'CompatibleParameterValues_Categories' | translate }}</h2>
            <div class="modalCategoryList">
                <div class="item" *ngIf="categoryPath.isPathVisible" (click)="categorySelected = categoryPath.previousNode">..</div>
                <div class="item" [attr.data-tid]="'catAdd_' + category.name" *ngFor="let category of categoryList" (click)="categorySelected = category">
                    {{ category.name }}
                </div>
            </div>
        </div>

        <div *ngIf="parameterGroupList" class="col-xs-4">
            <h2>{{ 'CompatibleParameterValues_Groups' | translate }}</h2>
            <div *ngIf="!!(parameterGroupList && parameterGroupList.length > 0)" class="modalGroupList">
                <div
                    *ngFor="let group of parameterGroupList"
                    [attr.data-tid]="'groAdd_' + group.name"
                    (click)="parameterGroupSelected = group"
                    [class.selected]="!!(parameterGroupSelected && parameterGroupSelected.id === group.id)"
                    class="item"
                >
                    {{ group.name }}
                </div>
            </div>
        </div>

        <div *ngIf="parameterList" class="col-xs-4">
            <h2>{{ 'CompatibleParameterValues_Types' | translate }}</h2>
            <div class="modalParameterList">
                <div
                    [class.selected]="!!(parameterSelected && parameterSelected.id === parameter.id)"
                    [attr.data-tid]="'parAdd_' + parameter.name"
                    *ngFor="let parameter of parameterListWithoutCurrent"
                    (click)="parameterSelected = parameter"
                    class="item"
                >
                    {{ parameter.name }}
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" data-tid="btnCancelAdd" class="btn btn-default" mat-dialog-close>{{ 'Common_Storno' | translate }}</button>
    <button type="button" data-tid="btnSaveAdd" class="btn btn-primary" [disabled]="!canSubmit" (click)="ok()">{{ 'Common_Ok' | translate }}</button>
</mat-dialog-actions>
