<ng-progress [spinner]="false" [color]="'#1ab394'"></ng-progress>
<div *ngIf="showProductionDbOnLocalWarning" class="production-db-warn">Pozor! Připojeno k produkční databázi!</div>
<div>
    <app-navbar id="side-menu-angular" *ngIf="this.session.isInitialized && !this.session.fullView"> </app-navbar>
    <div [attr.id]="!session.fullView ? 'page-wrapper' : ''" class="gray-bg" [class.full-vh]="session.fullView">
        <div class="alert alert-success alert-dismissible new-version-alert mb-0 text-center" role="alert" *ngIf="showReleaseNotesStrip">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            {{ 'App_NewVersionReleased' | translate }} <b>{{ session.user?.latestTag }}</b
            >. <a (click)="showReleaseNotes()" href="javascript:void(0)"> {{ 'App_NewVersionReleasedShowReleaseNotes' | translate }}</a>
        </div>
        <router-outlet (activate)="onActivate()" #outlet></router-outlet>
    </div>
</div>
