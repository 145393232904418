<ul class="nav navbar-top-links navbar-right">
    <li>
        <div class="language-select">
            <a class="dropdown-toggle" data-toggle="dropdown" href="javascript:void(0)" data-tid="ddLanguageHeader">
                <i class="lang {{ sectedCountry?.code | lowercase }}"></i>{{ sectedCountry?.name }} <i class="fas fa-caret-down"></i>
            </a>
            <ul class="dropdown-menu dropdown-user">
                <li *ngFor="let c of countries">
                    <a href="javascript:void(0)" (click)="changeLanguage(c.code)" [attr.data-tid]="'lang_' + c.name">
                        <i class="lang {{ c.code | lowercase }}"></i>{{ c.name }}</a
                    >
                </li>
            </ul>
        </div>
    </li>
</ul>
