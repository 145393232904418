import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { defaultCountryTranslation } from 'app/common/constants';
import { ICountryTranslationDto } from 'app/common/dto/common-dto';
import { CommonDataService } from 'app/common/services/common-data.service';
import { DialogsService } from 'app/common/services/dialogs.service';
import { QueryString, UrlUtils } from 'app/common/utils';
import { SaveButtonEvent } from 'app/components/page-footer/button-events';
import { TabManager } from 'app/components/tabs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CategoryProducerTranslationsDataService } from '../category-producer-translations-data.service';
import { CategoryProducerTranslationsFormService } from '../category-producer-translations-form.service';

@Component({
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit, OnDestroy {
    public form: UntypedFormGroup;
    public languages: Array<ICountryTranslationDto>;
    public returnRoute = '/category-producer-translations/assigned';
    public selectedCountry = defaultCountryTranslation;
    public targetLanguage: ICountryTranslationDto;
    public producerId: number;
    private _unsubscribe = new Subject<void>();
    public textTabs: TabManager;

    constructor(
        private readonly commonDataService: CommonDataService,
        public readonly dataService: CategoryProducerTranslationsDataService,
        private readonly dialogs: DialogsService,
        public readonly formService: CategoryProducerTranslationsFormService,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private readonly router: Router
    ) {
        this.textTabs = new TabManager(translate);
        this.textTabs.tabs = [
            {
                name: 'general',
                text: _('Category_TextsGeneralTab')
            },
            {
                name: 'upperDescription',
                text: _('Category_TextsUpperDescriptionTab')
            },
            {
                name: 'bottomDescription',
                text: _('Category_TextsBottomDescriptionTab')
            },
            {
                name: 'productInfo',
                text: _('Category_TextsProductInfoTab')
            }
        ];
    }
    ngOnInit() {
        this.route.params.subscribe((params) => {
            QueryString.validateNumericParams(
                {
                    throwIfNaN: true,
                    throwIfNew: true,
                    notFoundRoute: { command: ['/', 'category-producer-translations', this.route.snapshot.url[0].path], extras: {} }
                },
                params['id'],
                params['producerId'],
                params['languageId']
            );

            this.formService.init({ id: +params['id'], producerId: +params['producerId'], languageId: +params['languageId'] });
            if (this.route.snapshot.url[0]) {
                this.returnRoute = '/category-producer-translations/' + this.route.snapshot.url[0].path;
            }
        });
        this.commonDataService.language
            .getLanguages()
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((languages) => {
                if (this.formService.isInitialized()) {
                    this.languages = languages.filter((language) => language.id !== this.formService.languageId);
                    this.targetLanguage = languages.find((language) => language.id === this.formService.languageId);
                }
            });
        this.formService.reload$.pipe(takeUntil(this._unsubscribe)).subscribe(() => (this.form = this.formService.getTextTranslations()));
    }

    ngOnDestroy() {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    public localized(key: string) {
        return this.control(`sourceTranslations.${this.selectedCountry}.${key}`);
    }
    public control(key: string) {
        return this.form.get(key);
    }
    public controls(key: string) {
        return <Array<AbstractControl>>(<UntypedFormArray | UntypedFormGroup>this.form.get(key)).controls;
    }

    get title() {
        if (this.formService.id === 0) {
            return this.translate.instant('CategoryProducerTranslations_NewTitle') + ': ' + this.formService.titleName;
        }
        return this.translate.instant('CategoryProducerTranslations_EditTitle') + ' ' + this.formService.id + ': ' + this.formService.titleName;
    }

    public fillUrl() {
        this.form
            .get(`targetTranslation.${this.formService.languageId}.url`)
            .patchValue(
                UrlUtils.toUrlFormat(this.form.get(`targetTranslation.${this.formService.languageId}.seoName`).value || '') + '/' + this.formService.id + '.htm'
            );
    }

    public save(event: SaveButtonEvent) {
        this.formService.save(event.action === 'save').subscribe(() => {
            event.navigateToReturnRoute();
            this.dialogs.successMessage(this.translate.instant('CategoryProducerTranslations_MsgSaved'), '');
        });
    }
}
