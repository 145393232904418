import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './common/auth-guard';

/* eslint-disable max-len */

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'dashboard'
            },
            {
                path: 'video-review-article',
                loadChildren: () => import('app/modules/video-review-article/video-review-article.module').then((m) => m.VideoReviewArticleModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'user-reward-setting',
                loadChildren: () => import('app/modules/user-reward-setting/user-reward-setting.module').then((m) => m.UserRewardsSettingsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'action-category',
                loadChildren: () => import('app/modules/action-category/action-category.module').then((m) => m.ActionCategoryModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'accessory',
                loadChildren: () => import('app/modules/accessory/accessory.module').then((m) => m.AccessoryModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'accessory-groups',
                loadChildren: () => import('app/modules/accessory-groups/accessory-groups.module').then((m) => m.AccessoryGroupsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'article-category',
                loadChildren: () => import('app/modules/article-category/article-category.module').then((m) => m.ArticleCategoryModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'Badge',
                loadChildren: () => import('app/modules/badge/badge.module').then((m) => m.BadgeModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'Branch',
                loadChildren: () => import('app/modules/delivery/delivery.module').then((m) => m.DeliveryModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'BulkUpdate',
                loadChildren: () => import('app/modules/delivery/bulk-update/bulk-update.module').then((m) => m.BulkUpdateModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'cashback',
                loadChildren: () => import('app/modules/cashback/cashback.module').then((m) => m.CashbackModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'languages',
                loadChildren: () => import('app/modules/languages/languages.module').then((m) => m.LanguagesModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'carousel-translations',
                loadChildren: () => import('app/modules/carousel-translations/carousel-translations.module').then((m) => m.CarouselTranslationsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'CommodityDiscussionPosts',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('app/modules/commodity-discussion-posts/commodity-discussion-posts.module').then((m) => m.CommodityDiscussionPostsModule)
            },
            {
                path: 'CommodityToEdit',
                loadChildren: () => import('app/modules/commodity-to-edit/commodity-to-edit.module').then((m) => m.CommodityToEditModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-overview',
                loadChildren: () => import('app/modules/commodity-overview/commodity-overview.module').then((m) => m.CommodityOverviewModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-to-approve',
                loadChildren: () => import('app/modules/commodity-to-approve/commodity-to-approve.module').then((m) => m.CommodityToApproveModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'content-age',
                loadChildren: () => import('app/modules/content-age/content-age.module').then((m) => m.ContentAgeModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'customers',
                canActivate: [AuthGuard],
                loadChildren: () => import('app/modules/customers/customers.module').then((m) => m.CustomersModule)
            },
            {
                path: 'dashboard',
                canActivate: [AuthGuard],
                loadChildren: () => import('app/modules/dashboard/dashboard.module').then((m) => m.DashboardModule)
            },
            {
                path: 'Delivery',
                loadChildren: () => import('app/modules/delivery/delivery.module').then((m) => m.DeliveryModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-supplier',
                loadChildren: () => import('app/modules/commodity-supplier/commodity-supplier.module').then((m) => m.CommoditySupplierModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'Eshops/Exchange',
                loadChildren: () => import('app/modules/exchange-rate/exchange-rate.module').then((m) => m.ExchangeRateModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'ExternalBanner',
                loadChildren: () => import('app/modules/external-banner/external-banner.module').then((m) => m.ExternalBannerModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'error',
                loadChildren: () => import('app/modules/errors/errors.module').then((m) => m.ErrorsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'ga-reports',
                loadChildren: () => import('app/modules/ga-reports/ga-reports.module').then((m) => m.GareportsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'GenerateDescription',
                canActivate: [AuthGuard],
                loadChildren: () => import('app/modules/generate-description/generate-description.module').then((m) => m.GenerateDescriptionModule)
            },
            {
                path: 'help-desk-statistics',
                loadChildren: () => import('app/modules/help-desk-statistics/help-desk-statistics.module').then((m) => m.HelpDeskStatisticsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'holiday',
                loadChildren: () => import('app/modules/holiday/holiday.module').then((m) => m.HolidayModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'HowTo',
                loadChildren: () => import('app/modules/howto/howto.module').then((m) => m.HowtoModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'icu-translation-v2/language',
                loadChildren: () => import('app/modules/icu-translation-v2/language/language.module').then((m) => m.LanguageModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'icu-translation-v2/platform',
                loadChildren: () => import('app/modules/icu-translation-v2/platform/platform.module').then((m) => m.PlatformModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'icu-translation-v2/section',
                loadChildren: () => import('app/modules/icu-translation-v2/section/section.module').then((m) => m.SectionModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'icu-translation-v2/translation',
                loadChildren: () => import('app/modules/icu-translation-v2/translation/translation.module').then((m) => m.TranslationModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'icu-translations',
                loadChildren: () => import('app/modules/icu-translations/icu-translations.module').then((m) => m.IcuTranslationsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'JobPositions',
                loadChildren: () => import('app/modules/job-positions/job-positions.module').then((m) => m.JobPositionsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'Languages',
                loadChildren: () => import('app/modules/languages/languages.module').then((m) => m.LanguagesModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'LocalTitlePage',
                loadChildren: () => import('app/modules/local-title-page/local-title-page.module').then((m) => m.LocalTitlePageModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'mail-list-actions',
                loadChildren: () => import('app/modules/mail-list-actions/mail-list-actions.module').then((m) => m.MailListActionsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'search-redirect',
                loadChildren: () => import('app/modules/search-redirect/search-redirect.module').then((m) => m.SearchRedirectModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'queues-management',
                loadChildren: () => import('app/modules/commodity-management/commodity-management.module').then((m) => m.CommodityManagementModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'slot',
                loadChildren: () => import('app/modules/slot/slot.module').then((m) => m.SlotModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'translations/order-state',
                loadChildren: () => import('app/modules/order-state/order-state.module').then((m) => m.OrderStateModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'translation-state',
                loadChildren: () => import('app/modules/translation-state/translation-state.module').then((m) => m.TranslationStateModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'Translations/TranslationVariables',
                loadChildren: () => import('app/modules/translation-variables/translation-variables.module').then((m) => m.TranslationVariablesModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'url-rewrites',
                loadChildren: () => import('app/modules/url-rewrites/url-rewrites.module').then((m) => m.UrlRewritesModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'utilities',
                loadChildren: () => import('app/modules/utilities/utilities.module').then((m) => m.UtilitiesModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'Utils/PoiDevice',
                loadChildren: () => import('app/modules/poi-device/poi-device.module').then((m) => m.PoiDeviceModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'Utils/WebSetting',
                loadChildren: () => import('app/modules/web-setting/web-setting.module').then((m) => m.WebSettingModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'internal-banner',
                loadChildren: () => import('app/modules/internal-banner/internal-banner.module').then((m) => m.InternalBannerModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'job-offer',
                loadChildren: () => import('app/modules/job-positions/job-offer/job-offer.module').then((m) => m.JobOfferModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodityToEdit',
                loadChildren: () => import('app/modules/commodity-to-edit/commodity-to-edit.module').then((m) => m.CommodityToEditModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-translation',
                loadChildren: () => import('app/modules/commodity-translation/commodity-translation.module').then((m) => m.CommodityTranslationModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'CommodityPrefix',
                loadChildren: () => import('app/modules/commodity-prefix/commodity-prefix.module').then((m) => m.CommodityPrefixModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'Tag',
                loadChildren: () => import('app/modules/tags/tags.module').then((m) => m.TagsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'user',
                loadChildren: () => import('app/modules/user/user.module').then((m) => m.UserModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'UserPermissions',
                loadChildren: () => import('app/modules/user-permissions/user-permissions.module').then((m) => m.UserPermissionsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'articles',
                loadChildren: () => import('app/modules/article/article.module').then((m) => m.ArticleModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'parameter',
                loadChildren: () => import('app/modules/parameter/parameter.module').then((m) => m.ParameterModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'price-slasher',
                loadChildren: () => import('app/modules/price-slasher/price-slasher.module').then((m) => m.PriceSlasherModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'producers',
                loadChildren: () => import('app/modules/producers/producers.module').then((m) => m.ProducersModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'user-reward',
                loadChildren: () => import('app/modules/user-reward/user-reward.module').then((m) => m.UserRewardModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-collection',
                loadChildren: () => import('app/modules/commodity-collection/commodity-collection.module').then((m) => m.CommodityCollectionModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'parameter-translations',
                loadChildren: () => import('app/modules/parameter-translations/parameter-translations.module').then((m) => m.ParameterTranslationsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'warranty',
                loadChildren: () => import('app/modules/warranty/warranty.module').then((m) => m.WarrantyModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'wizard',
                loadChildren: () => import('app/modules/wizard/wizard.module').then((m) => m.WizardModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'section',
                loadChildren: () => import('app/modules/section/section.module').then((m) => m.SectionModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-to-process',
                loadChildren: () => import('app/modules/commodity-to-process/commodity-to-process.module').then((m) => m.CommodityToProcessModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'info-messages',
                loadChildren: () => import('app/modules/info-messages/info-messages.module').then((m) => m.InfoMessagesModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-edit-texts',
                loadChildren: () => import('app/modules/commodity-edit-texts/commodity-edit-texts.module').then((m) => m.CommodityEditTextsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'translation-sentences',
                loadChildren: () => import('app/modules/translation-sentences/translation-sentences.module').then((m) => m.TranslationSentencesModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'synonyms',
                loadChildren: () => import('app/modules/synonyms/synonyms.module').then((m) => m.SynonymsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'translation-settings',
                loadChildren: () => import('app/modules/translation-settings/translation-settings.module').then((m) => m.TranslationSettingsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'internal-banner-translation',
                loadChildren: () =>
                    import('app/modules/internal-banner-translation/internal-banner-translation.module').then((m) => m.InternalBannerTranslationModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-images-to-approve',
                loadChildren: () =>
                    import('app/modules/commodity-images-to-approve/commodity-images-to-approve.module').then((m) => m.CommodityImagesToApproveModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-images-to-approve-instruction',
                loadChildren: () =>
                    import('app/modules/commodity-images-to-approve-instruction/commodity-images-to-approve-instruction.module').then(
                        (m) => m.CommodityImagesToApproveInstructionModule
                    ),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity/commodity-send-to',
                loadChildren: () => import('app/modules/commodity-send-to/commodity-send-to.module').then((m) => m.CommoditySendToModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'translation/commodity-send-to',
                loadChildren: () => import('app/modules/commodity-send-to/commodity-send-to.module').then((m) => m.CommoditySendToModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'prefix-translations',
                loadChildren: () => import('app/modules/prefix-translations/prefix-translations.module').then((m) => m.PrefixTranslationsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-installation',
                loadChildren: () => import('app/modules/commodity-installation/commodity-installation.module').then((m) => m.CommodityInstallationModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'sent-email-log',
                loadChildren: () => import('app/modules/sent-email-log/sent-email-log.module').then((m) => m.SentEmailLogModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'payment-leasing-products',
                loadChildren: () => import('app/modules/payment-leasing-products/payment-leasing-products.module').then((m) => m.PaymentLeasingProductsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'payment-leasing-settings',
                loadChildren: () => import('app/modules/payment-leasing-settings/payment-leasing-settings.module').then((m) => m.PaymentLeasingSettingsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'price-comparer-competitor',
                loadChildren: () =>
                    import('app/modules/price-comparer-competitor/price-comparer-competitor.module').then((m) => m.PriceComparerCompetitorModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'image-platform-density',
                loadChildren: () => import('app/modules/image-platform-density/image-platform-density.module').then((m) => m.ImagePlatformDensityModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'search-miss-report',
                loadChildren: () => import('app/modules/search-miss-report/search-miss-report.module').then((m) => m.SearchMissReportModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'review',
                loadChildren: () => import('app/modules/review/review.module').then((m) => m.ReviewModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'search-facet',
                loadChildren: () => import('app/modules/search-facet/search-facet.module').then((m) => m.SearchFacetModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'promo-section',
                loadChildren: () => import('app/modules/promo-section/promo-section.module').then((m) => m.PromoSectionModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-pack',
                loadChildren: () => import('app/modules/commodity-pack/commodity-pack.module').then((m) => m.CommodityPackModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'help-desk-groups',
                loadChildren: () => import('app/modules/help-desk-groups/help-desk-groups.module').then((m) => m.HelpDeskGroupsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'upload-file',
                loadChildren: () => import('app/modules/upload-file/upload-file.module').then((m) => m.UploadFileModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'alias-text-category',
                loadChildren: () => import('app/modules/alias-text-category/alias-text-category.module').then((m) => m.AliasTextCategoryModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'alias-text',
                loadChildren: () => import('app/modules/alias-text/alias-text.module').then((m) => m.AliasTextModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'price-killer',
                loadChildren: () => import('app/modules/price-killer/price-killer.module').then((m) => m.PriceKillerModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-award',
                loadChildren: () => import('app/modules/commodity-award/commodity-award.module').then((m) => m.CommodityAwardModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'students-discount',
                loadChildren: () => import('app/modules/students-discount/students-discount.module').then((m) => m.StudentsDiscountModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'url-rewrites-v2',
                loadChildren: () => import('app/modules/url-rewrites-v2/url-rewrite-rule.module').then((m) => m.UrlRewriteRuleModule),
                canActivate: [AuthGuard]
            },
            { path: 'pop-up', loadChildren: () => import('./modules/pop-up/pop-up.module').then((m) => m.PopUpModule), canActivate: [AuthGuard] },
            {
                path: 'discount-actions',
                loadChildren: () => import('./modules/discount-actions/discount-actions.module').then((m) => m.DiscountActionsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'discount-vouchers',
                loadChildren: () => import('./modules/discount-vouchers/discount-vouchers.module').then((m) => m.DiscountVouchersModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'fast-order-voucher',
                loadChildren: () => import('./modules/fast-order-voucher/fast-order-voucher.module').then((m) => m.FastOrderVoucherModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'basket-order-delivery',
                loadChildren: () => import('app/modules/basket-order-delivery/basket-order-delivery.module').then((m) => m.BasketOrderDeliveryModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-redirect',
                loadChildren: () => import('./modules/commodity-redirect/commodity-redirect.module').then((m) => m.CommodityRedirectModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-review',
                loadChildren: () => import('app/modules/commodity-review/commodity-review.module').then((m) => m.CommodityReviewModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'reviews-to-resolve',
                loadChildren: () => import('app/modules/reviews-to-resolve/reviews-to-resolve.module').then((m) => m.ReviewsToResolveModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'gift-advisor',
                loadChildren: () => import('app/modules/gift-advisor/gift-advisor.module').then((m) => m.GiftAdvisorModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'mail-black-list',
                loadChildren: () => import('./modules/mail-black-list/mail-black-list.module').then((m) => m.MailBlackListModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-legend',
                loadChildren: () => import('app/modules/commodity-legend/commodity-legend.module').then((m) => m.CommodityLegendModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-legend-template',
                loadChildren: () =>
                    import('app/modules/commodity-legend-template/commodity-legend-template.module').then((m) => m.CommodityLegendTemplateModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'mail-list',
                loadChildren: () => import('./modules/mail-list/mail-list.module').then((m) => m.MailListModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'new-configuration',
                loadChildren: () => import('app/modules/new-configuration/new-configuration.module').then((m) => m.NewConfigurationModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'tag-translations',
                loadChildren: () => import('./modules/tag-translations/tag-translations.module').then((m) => m.TagTranslationsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-ar-model',
                loadChildren: () => import('./modules/commodity-ar-model/commodity-ar-model.module').then((m) => m.CommodityArModelModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-parameter',
                loadChildren: () => import('app/modules/commodity-parameter/commodity-parameter.module').then((m) => m.CommodityParameterModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'unmerge-client',
                loadChildren: () => import('./modules/unmerge-client/unmerge-client.module').then((m) => m.UnmergeClientModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'mailing-actions',
                loadChildren: () => import('./modules/mailing-actions/mailing-actions.module').then((m) => m.MailingActionsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-file',
                loadChildren: () => import('./modules/commodity-file/commodity-file.module').then((m) => m.CommodityFileModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-icon',
                loadChildren: () => import('./modules/commodity-icon/commodity-icon.module').then((m) => m.CommodityIconModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'rewrite-object',
                loadChildren: () => import('./modules/rewrite-object/rewrite-object.module').then((m) => m.RewriteObjectModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'user-group',
                loadChildren: () => import('./modules/user-group/user-group.module').then((m) => m.UserGroupModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'translation-web',
                loadChildren: () => import('./modules/translation-web/translation-web.module').then((m) => m.TranslationWebModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'payment-success',
                loadChildren: () => import('./modules/payment-success/payment-success.module').then((m) => m.PaymentSuccessModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'payment',
                loadChildren: () => import('./modules/payment/payment.module').then((m) => m.PaymentModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'good-text',
                loadChildren: () => import('./modules/good-text/good-text.module').then((m) => m.GoodTextModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'commodity-group',
                loadChildren: () => import('./modules/commodity-group/commodity-group.module').then((m) => m.CommodityGroupModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'gift-action',
                loadChildren: () => import('./modules/gift-action/gift-action.module').then((m) => m.GiftActionModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'competitor',
                loadChildren: () => import('./modules/competitor/competitor.module').then((m) => m.CompetitorModule),
                canActivate: [AuthGuard]
            }
        ])
    ]
})
export class AppRoutingModule {}
