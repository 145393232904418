import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { AuthGuard } from 'app/common/auth-guard';
import { ComponentsModule } from 'app/components/components.module';
import { Admin } from 'app/models/enums/history-entity-type';
import { ParameterGroupDataService } from '../parameter/parameter-group-data.service';
import { ParameterTypeDataService } from '../parameter/parameter-type-data.service';
import { RatingModule } from '../rating/rating.module';
import { CategoryDataService } from './category-data.service';
import { CollectionComponent } from './collection/collection.component';
import { DetailComponent } from './detail/detail.component';
import { CategoryDescriptionUpdateComponent } from './dialogs/category-description-update.component';
import { GridTreeComponent } from './grid-tree/grid-tree.component';
import { OverviewComponent } from './overview/overview.component';
import { CategoryRatingComponent } from './rating/category-rating.component';
import { CountriesComponent } from './tabs/countries/countries.component';
import { ImagesComponent } from './tabs/images/images.component';
import { MainComponent } from './tabs/main/main.component';
import { ParameterModalComponent } from './tabs/parameters/parameter-modal/parameter-modal.component';
import { ParameterUrlModalComponent } from './tabs/parameters/parameter-url-modal/parameter-url-modal.component';
import { ParametersComponent } from './tabs/parameters/parameters.component';
import { ProducerTextsComponent } from './tabs/producer-texts/producer-texts.component';
import { ProducersComponent } from './tabs/producers/producers.component';
import { RatingBottomComponent } from './tabs/rating-bottom/rating-bottom.component';
import { RatingUpperComponent } from './tabs/rating-upper/rating-upper.component';
import { RatingComponent } from './tabs/rating/rating.component';
import { TagsComponent } from './tabs/tags/tags.component';
import { TextsComponent } from './tabs/texts/texts.component';
import { TopProducerCountriesComponent } from './tabs/top-producer-countries/top-producer-countries.component';
import { TopProducersComponent } from './tabs/top-producers/top-producers.component';
const routes: Routes = [
    {
        path: 'Category/EditText/:id',
        redirectTo: '/category/:id/texts'
    },
    {
        path: 'category',
        canActivate: [AuthGuard],
        children: [
            {
                path: 'collection',
                component: CollectionComponent,
                children: [
                    { path: 'tree', component: GridTreeComponent },
                    { path: 'overview', component: OverviewComponent },
                    { path: 'rating', component: CategoryRatingComponent },
                    { path: '**', redirectTo: 'tree' }
                ]
            },
            {
                path: ':gridTab/:id',
                component: DetailComponent,
                children: [
                    {
                        path: 'main',
                        component: MainComponent
                    },
                    {
                        path: 'parameters',
                        component: ParametersComponent
                    },
                    {
                        path: 'top-producer-countries',
                        component: TopProducerCountriesComponent
                    },
                    {
                        path: 'images',
                        component: ImagesComponent
                    },
                    {
                        path: 'tags',
                        component: TagsComponent
                    },
                    {
                        path: 'countries',
                        component: CountriesComponent
                    },
                    {
                        path: 'texts',
                        component: TextsComponent
                    },
                    {
                        path: 'producers',
                        redirectTo: 'producers/',
                        pathMatch: 'full'
                    },
                    {
                        path: 'producers/:producerId',
                        component: ProducersComponent
                    },
                    {
                        path: 'rating',
                        component: RatingComponent,
                        children: [
                            {
                                path: 'upper',
                                component: RatingUpperComponent
                            },
                            {
                                path: 'bottom',
                                component: RatingBottomComponent
                            },
                            {
                                path: '**',
                                redirectTo: 'upper'
                            }
                        ]
                    },
                    {
                        path: 'history',
                        data: { objectTypeId: Admin.Category },
                        loadChildren: () => import('app/modules/history-v2/history.module').then((m) => m.HistoryV2Module)
                    },
                    {
                        path: '**',
                        redirectTo: 'main'
                    }
                ]
            },
            {
                path: '**',
                redirectTo: 'collection'
            }
        ]
    }
];

@NgModule({
    imports: [
        ComponentsModule,
        TranslateModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule.forChild(routes),
        ContextMenuModule,
        RatingModule,
        DragDropModule
    ],
    declarations: [
        CollectionComponent,
        DetailComponent,
        GridTreeComponent,
        OverviewComponent,
        ProducersComponent,
        ProducerTextsComponent,
        TextsComponent,
        CategoryRatingComponent,
        RatingComponent,
        RatingBottomComponent,
        RatingUpperComponent,
        MainComponent,
        CountriesComponent,
        TopProducersComponent,
        ImagesComponent,
        ParametersComponent,
        TagsComponent,
        ParameterModalComponent,
        ParameterUrlModalComponent,
        CategoryDescriptionUpdateComponent,
        TopProducerCountriesComponent
    ],
    providers: [CategoryDataService, ParameterTypeDataService, ParameterGroupDataService]
})
export class CategoryModule {}
