<div class="account-page">
    <div class="background">
        <img class="stars" src="https://cdn.alza.cz/Images/nginx-errors/stars.svg" alt="" />
        <img class="dustTopLeft" src="https://cdn.alza.cz/Images/nginx-errors/dust-top-left.svg" alt="" />
        <img class="dustBottomLeft" src="https://cdn.alza.cz/Images/nginx-errors/dust-bottom-left.svg" alt="" />
        <img class="dustBottomRight" src="https://cdn.alza.cz/Images/nginx-errors/dust-bottom-right.svg" alt="" />
        <img class="planetPinkGlow" src="https://cdn.alza.cz/Images/nginx-errors/planet-pink-glow.svg" alt="" />
        <img class="planetBlueGlow" src="https://cdn.alza.cz/Images/nginx-errors/planet-blue-glow.svg" alt="" />
        <img class="planetWhiteGlow" src="https://cdn.alza.cz/Images/nginx-errors/planet-white-glow.svg" alt="" />
        <img id="logo" class="logoWhite" src="https://cdn.alza.cz/Images/nginx-errors/Logo-White-Alza-cz.svg" alt="" />
        <img class="alzak" src="https://cdn.alza.cz/Images/nginx-errors/alzak-spacesuit-security.svg" alt="" />
    </div>
    <div class="content-wrapper">
        <div class="content" [class.large]="large()">
            <app-login-language-select></app-login-language-select>
            <ng-content></ng-content>
        </div>
    </div>
</div>
