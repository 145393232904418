import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { AuthGuard } from 'app/common/auth-guard';
import { PagerService } from 'app/common/services/pager.service';
import { ComponentsModule } from 'app/components/components.module';
import { CategoryProducerTranslationsDataService } from './category-producer-translations-data.service';
import { CategoryProducerTranslationsFormService } from './category-producer-translations-form.service';
import { CollectionComponent } from './collection/collection.component';
import { DetailComponent } from './detail/detail.component';
import { TranslationsAssignedGridComponent } from './translations-assigned-grid/translations-assigned-grid.component';
import { TranslationsMissingGridComponent } from './translations-missing-grid/translations-missing-grid.component';

const routes: Routes = [
    {
        path: 'category-producer-translations',
        canActivate: [AuthGuard],
        children: [
            {
                path: 'missing/:id/:producerId/:languageId',
                component: DetailComponent
            },
            {
                path: 'assigned/:id/:producerId/:languageId',
                component: DetailComponent
            },
            {
                path: '',
                component: CollectionComponent,
                children: [
                    { path: 'missing', component: TranslationsMissingGridComponent },
                    { path: 'assigned', component: TranslationsAssignedGridComponent },
                    { path: '**', redirectTo: 'missing' }
                ]
            },
            {
                path: '**',
                redirectTo: ''
            }
        ]
    }
];

@NgModule({
    imports: [ComponentsModule, TranslateModule, ReactiveFormsModule, CommonModule, ContextMenuModule, RouterModule.forChild(routes)],
    declarations: [CollectionComponent, DetailComponent, TranslationsMissingGridComponent, TranslationsAssignedGridComponent],
    providers: [CategoryProducerTranslationsDataService, CategoryProducerTranslationsFormService, PagerService]
})
export class CategoryProducerTranslationsModule {}
