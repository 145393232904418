import { Component, OnDestroy } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'app/common/services';
import { PagerService } from 'app/common/services/pager.service';
import { RoutingTabManager } from 'app/components/tabs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CategoryProducerTranslationsDataService } from '../category-producer-translations-data.service';

interface IFilter {
    searchValue: string;
}

@Component({
    providers: [PagerService, RoutingTabManager],
    templateUrl: './collection.component.html',
    styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnDestroy {
    public translationsAssignedCount?: number;
    private _unsubscribe = new Subject<void>();
    constructor(
        private readonly dataService: CategoryProducerTranslationsDataService,
        private readonly messageService: MessageService,
        public readonly pager: PagerService<IFilter, any>,
        public readonly tabManager: RoutingTabManager,
        private readonly translate: TranslateService
    ) {
        this.tabManager.tabs = [
            {
                name: 'missing',
                text: _('CategoryProducerTranslations_Missing')
            },
            {
                name: 'assigned'
            }
        ];
        this.messageService
            .getMessage('CategoryProducerTranslations_RefreshAssigmentCount')
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(() => {
                this.dataService.producerTextTranslationsAssignedCount().subscribe((count) => {
                    this.messageService.sendMessage('CategoryProducerTranslations_AssigmentCount', count);
                });
            });
        this.messageService.sendMessage('CategoryProducerTranslations_RefreshAssigmentCount');
        this.messageService
            .getMessage('CategoryProducerTranslations_AssigmentCount')
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((count) => {
                this.translate.get('CategoryTranslations_Assigned').subscribe((text) => {
                    const tab = this.tabManager.tabs.find((t) => t.name === 'assigned');
                    if (tab) {
                        tab.text = text + ' (' + count + ')';
                    }
                });
            });
    }

    ngOnDestroy() {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }
}
