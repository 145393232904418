import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { defaultCountryTranslation, EMPTY } from 'app/common/constants';
import { ICountryTranslationDto } from 'app/common/dto/common-dto';
import { CategoryTextFieldWithoutTranslation, Languages } from 'app/common/enums/enums';
import { MessageService } from 'app/common/services';
import { CommonDataService } from 'app/common/services/common-data.service';
import { PagerService } from 'app/common/services/pager.service';
import { SessionService } from 'app/common/services/session.service';
import { ISelectListItemDto } from 'app/models';
import { CategoryDataService } from 'app/modules/category/category-data.service';
import { CategoryTranslationsFormService } from '../category-translations-form.service';
import { ICategoryTextTranslationsCollectionItemDto } from '../dto/category-text-translations-collection-item-dto';

interface IFilter {
    assignedUserId?: number;
    field?: number; // helps with field persistence on translations-missing-grid
    fieldWithoutTranslation: number;
    languageId: number;
}

@Component({
    providers: [PagerService],
    templateUrl: './translations-to-correct-grid.component.html',
    styleUrls: ['./translations-to-correct-grid.component.scss']
})
export class TranslationsToCorrectGridComponent {
    public languages: Array<ICountryTranslationDto>;
    public filterDefault: IFilter = { languageId: Languages.Slovakia, fieldWithoutTranslation: CategoryTextFieldWithoutTranslation.UpperDescription };
    public userId: number;
    public users: Array<ISelectListItemDto>;
    constructor(
        private readonly commonDataService: CommonDataService,
        private readonly dataService: CategoryDataService,
        public readonly formService: CategoryTranslationsFormService,
        private readonly messageService: MessageService,
        public readonly pager: PagerService<IFilter, any>,
        private readonly router: Router,
        private readonly session: SessionService
    ) {
        pager.initialize('/api/category/text-translations-to-correct', { ...this.filterDefault }, { key: 0, desc: true });
        this.commonDataService.selectList.getUsers('All').subscribe((users) => {
            this.users = users;
        });
        this.commonDataService.language.getLanguages().subscribe((languages) => {
            this.languages = languages.filter((language) => language.id !== defaultCountryTranslation);
        });
        this.userId = this.session.user.id;
    }

    public openable(item: ICategoryTextTranslationsCollectionItemDto) {
        return !item.assignedUserName || item.assignedUserId === this.userId;
    }

    public unassign(item: ICategoryTextTranslationsCollectionItemDto) {
        if (!item.userTaskId) {
            return;
        }
        this.dataService.categoryUnassign(item.userTaskId).subscribe(() => {
            item.userTaskId = null;
            item.assignedUserName = null;
            this.messageService.sendMessage('CategoryTranslations_RefreshAssigmentCount');
        });
    }

    public openDetail(item: ICategoryTextTranslationsCollectionItemDto) {
        if (!this.openable(item)) {
            return;
        }
        this.router
            .navigate(['category-translations', 'to-correct', item.id, this.pager.filter.languageId], {
                queryParams: { returnQuery: this.pager.queryString }
            })
            .catch(EMPTY);
    }
}
