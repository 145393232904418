import { AlzaCmsComponentsModule } from '@alza/cms-components';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environmentRoutes } from 'environments/environment';
import { DragulaModule } from 'ng2-dragula';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminEnumProvider } from './common/admin-enum-provider.service';
import { AdminLanguageProvider } from './common/admin-language-provider.service';
import { AppTranslateLoader } from './common/app-translate-loader';
import { AuthGuard } from './common/auth-guard';
import { CommonModule } from './common/common.module';
import { ErrorHandlingHttpInterceptor } from './common/error-handling-http-interceptor';
import { LoadingIndicatorHttpInterceptor } from './common/loading-indicator-http-interceptor';
import { LoggingErrorHandler } from './common/logging-error-handler';
import { CommonDataService, SessionService } from './common/services';
import { ClientSettingsService } from './common/services/client-settings.service';
import { ComponentsModule } from './components/components.module';
import { LoginModule } from './modules/account/login.module';
import { AuditV2Module } from './modules/audit-v2/audit.module';
import { AuditModule } from './modules/audit/audit.module';
import { CategoryProducerTranslationsModule } from './modules/category-producer-translations/category-producer-translations.module';
import { CategoryTranslationsModule } from './modules/category-translations/category-translations.module';
import { CategoryModule } from './modules/category/category.module';
import { CompatibleParameterValuesModule } from './modules/compatible-parameter-values/compatible-parameter-values.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { ErrorsModule } from './modules/errors/errors.module';

// The reason why we can't use cdk/drag-and-drop - https://github.com/angular/material2/issues/13372

// AoT requires an exported function for factories
export function httpLoaderFactory(http: HttpClient, cookies: CookieService, commonData: CommonDataService, clientSettings: ClientSettingsService) {
    return new AppTranslateLoader(http, cookies, commonData, clientSettings);
}

export function initializeApplication(clientSettings: ClientSettingsService) {
    return () => clientSettings.initialize();
}
export function initializeSession(sessionService: SessionService) {
    return () => sessionService.initialize();
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        CompatibleParameterValuesModule,
        NgProgressModule.withConfig({ debounceTime: 150 }),
        NgProgressHttpModule,
        AuditModule,
        AuditV2Module,
        BrowserModule,
        BrowserAnimationsModule,
        AngularCommonModule,
        CommonModule,
        HttpClientModule,
        FormsModule,
        CategoryModule,
        ErrorsModule,
        ModalModule.forRoot(),
        DragulaModule.forRoot(),
        RouterModule.forRoot(environmentRoutes),
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient, CookieService, CommonDataService, ClientSettingsService]
            },
            useDefaultLang: false
        }),
        ComponentsModule,
        LoginModule,
        DashboardModule,
        CategoryModule,
        CategoryTranslationsModule,
        CategoryProducerTranslationsModule,
        MonacoEditorModule.forRoot(),
        AlzaCmsComponentsModule.forRoot({
            enumProvider: AdminEnumProvider,
            languagesProvider: AdminLanguageProvider,
            bootstrapVersion: 3,
            maxQueryStringLength: 5000
        })
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'cs-CZ' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlingHttpInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingIndicatorHttpInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: LoggingErrorHandler
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [ClientSettingsService],
            useFactory: initializeApplication
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [SessionService],
            useFactory: initializeSession
        },
        AuthGuard,
        CookieService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
