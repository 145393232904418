import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { DialogsService } from 'app/common/services/dialogs.service';
import { CompatibleParameterValuesDataService } from '../compatible-parameter-values-data.service';
import { IModalContextDto } from '../compatible-parameter-values-dto';
import { FindParameterByCategory } from '../find-parameter-by-category';

@Component({
    templateUrl: './find-related-parameter-modal.component.html',
    styleUrls: ['./find-related-parameter-modal.component.scss']
})
export class FindRelatedParameterModalComponent extends FindParameterByCategory {
    constructor(
        private router: Router,
        private http: HttpClient,
        private dialogs: DialogsService,
        private fb: UntypedFormBuilder,
        public readonly dialogRef: MatDialogRef<FindRelatedParameterModalComponent>,
        dataService: CompatibleParameterValuesDataService,
        @Inject(MAT_DIALOG_DATA) private readonly context: IModalContextDto | null = null
    ) {
        super(dataService);
    }
    public get parameterListWithoutCurrent() {
        if (this.parameterList !== null && this.context !== null && this.context.filteredParameterId !== null) {
            const i = this.context.filteredParameterId;
            return this.parameterList.filter((p) => p.id !== i);
        }
        return this.parameterList;
    }

    public get canSubmit() {
        return this.parameterSelected ? true : false;
    }

    public ok() {
        this.dialogRef.close(this.parameterSelected ? this.parameterSelected.id : null);
    }
}
