import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SessionService } from 'app/common/services/session.service';
import { LoginDataService } from '../login-data.service';

@Component({
    selector: 'app-new-password',
    templateUrl: './new-password.component.html',
    styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
    @Input()
    form: UntypedFormGroup;
    @Input()
    weakPassword: boolean;
    @Output()
    weakPasswordChange = new EventEmitter<boolean>();

    @Input()
    username: string;

    public containsBlacklisted = false;

    private _passwScore = 0;

    get passwScore() {
        return this._passwScore;
    }

    set passwScore(score: number) {
        this._passwScore = score;
        this.weakPassword = score < 60 ? true : false;
        this.weakPasswordChange.emit(this.weakPassword);
    }

    constructor(private readonly dataService: LoginDataService, private readonly session: SessionService) {}

    ngOnInit() {
        this.form.get('newPassword').valueChanges.subscribe((val) => {
            if (!val) {
                this.passwScore = 0;
            }
        });
    }

    public scorePassword(pass: string) {
        let score = 0;
        if (!pass) {
            this.passwScore = score;
            return;
        }

        // clean password for blacklisted words
        const cleanedPass = this.cleanPassword(pass);
        if (pass.length !== cleanedPass.length) {
            this.containsBlacklisted = true;
            pass = cleanedPass;
        } else {
            this.containsBlacklisted = false;
        }

        // award every unique letter until 5 repetitions
        const letters = new Object();
        for (let i = 0; i < pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }
        // bonus points for mixing it up
        const variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass)
        };

        let variationCount = 0;

        for (const check in variations) {
            if (variations.hasOwnProperty(check)) {
                variationCount += variations[check] === true ? 1 : 0;
            }
        }
        score += (variationCount - 1) * 10;

        this.passwScore = score;
    }

    private cleanPassword(pass: string) {
        if (!pass) {
            return '';
        }
        const username = this.username || (this.session.user ? this.session.user.login : '') || '';
        const blacklist = ['alza', 'admin', ...username.split('.').filter((x) => !!x)];
        let loweredPassword = pass.toLowerCase();
        blacklist.forEach((item) => {
            let ix = -1;
            while ((ix = loweredPassword.indexOf(item)) !== -1) {
                const start = pass.substr(0, ix);
                const end = pass.substr(ix + item.length, pass.length - (ix + item.length));
                pass = start + end;
                loweredPassword = pass.toLowerCase();
            }
        });

        return pass;
    }

    public getProgressClass() {
        if (this.passwScore > 0 && this.passwScore < 60) {
            return 'progress-bar-danger';
        } else if (this.passwScore >= 60 && this.passwScore < 80) {
            return 'progress-bar-success';
        } else if (this.passwScore >= 80) {
            return 'progress-bar-primary';
        } else {
            return 'progress-bar-danger';
        }
    }

    public getProgressWidth() {
        if (this.passwScore > 0 && this.passwScore < 60) {
            return 33;
        } else if (this.passwScore >= 60 && this.passwScore < 80) {
            return 66;
        } else if (this.passwScore >= 80) {
            return 100;
        } else {
            return 0;
        }
    }
}
