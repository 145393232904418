import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { AuthGuard } from 'app/common/auth-guard';
import { PagerService } from 'app/common/services/pager.service';
import { ComponentsModule } from 'app/components/components.module';
import { CategoryTranslationsDataService } from './category-translations-data.service';
import { CategoryTranslationsFormService } from './category-translations-form.service';
import { CollectionComponent } from './collection/collection.component';
import { DetailComponent } from './detail/detail.component';
import { TranslationsAssignedGridComponent } from './translations-assigned-grid/translations-assigned-grid.component';
import { TranslationsMissingGridComponent } from './translations-missing-grid/translations-missing-grid.component';
import { TranslationsToCorrectGridComponent } from './translations-to-correct-grid/translations-to-correct-grid.component';

const routes: Routes = [
    {
        path: 'category-translations',
        canActivate: [AuthGuard],
        children: [
            {
                path: 'missing/:id/:languageId',
                component: DetailComponent
            },
            {
                path: 'to-correct/:id/:languageId',
                component: DetailComponent
            },
            {
                path: 'assigned/:id/:languageId',
                component: DetailComponent
            },
            {
                path: '',
                component: CollectionComponent,
                children: [
                    { path: 'missing', component: TranslationsMissingGridComponent },
                    { path: 'to-correct', component: TranslationsToCorrectGridComponent },
                    { path: 'assigned', component: TranslationsAssignedGridComponent },
                    { path: '**', redirectTo: 'missing' }
                ]
            },
            {
                path: '**',
                redirectTo: ''
            }
        ]
    }
];

@NgModule({
    imports: [ComponentsModule, TranslateModule, ReactiveFormsModule, CommonModule, ContextMenuModule, RouterModule.forChild(routes)],
    declarations: [
        CollectionComponent,
        DetailComponent,
        TranslationsMissingGridComponent,
        TranslationsToCorrectGridComponent,
        TranslationsAssignedGridComponent
    ],
    providers: [CategoryTranslationsDataService, CategoryTranslationsFormService, PagerService]
})
export class CategoryTranslationsModule {}
