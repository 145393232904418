import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'app/common/services/session.service';
import { LoginDataService } from '../login-data.service';

@Component({
    selector: 'app-confirm-email',
    templateUrl: './confirm-email.component.html',
    styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit, OnDestroy {
    public successMessage: string;
    public failedMessage: string;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly dataService: LoginDataService,
        private readonly translate: TranslateService,
        private readonly sessionService: SessionService
    ) {}

    ngOnInit() {
        this.sessionService.fullView = true;

        this.route.queryParams.subscribe((query) => {
            const token = query['token'] ? query['token'] : '';

            if (token) {
                this.dataService.confirmEmail(token).subscribe(
                    () => {
                        this.translate.get('Login_ConfirmEmail_Success').subscribe((x) => (this.successMessage = x));
                    },
                    (err: HttpErrorResponse) => {
                        if (err.status === 400) {
                            this.translate.get('Login_ConfirmEmail_Failed').subscribe((x) => (this.failedMessage = x));
                        }
                    }
                );
            }
        });
    }

    ngOnDestroy() {
        this.sessionService.fullView = false;
    }
}
