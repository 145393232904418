import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ICountryTranslationDto } from 'app/common/dto/common-dto';
import { CommonDataService } from 'app/common/services/common-data.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-login-language-select',
    templateUrl: './language-select.component.html',
    styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {
    public countries: Array<ICountryTranslationDto> = [];
    public sectedCountry?: ICountryTranslationDto;

    constructor(private readonly commonData: CommonDataService, private readonly cookieService: CookieService, private readonly http: HttpClient) {}

    ngOnInit() {
        this.commonData.language.getLanguages().subscribe((res) => {
            const lang = this.cookieService.get('adm2lang') || 'cz';
            this.countries = res.filter((x) => x.code.toLowerCase() !== lang.toLowerCase());
            this.sectedCountry = res.find((x) => x.code.toLowerCase() === lang.toLowerCase());
        });
    }

    public changeLanguage(code: string) {
        this.http.post('/api/i18n/set-lang', { language: code }).subscribe(() => {
            location.reload();
        });
    }
}
