import { Validators } from '@alza/cms-components';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { handle404 } from 'app/common/operators';
import { DialogsService } from 'app/common/services/dialogs.service';
import { FormHelper, FormServiceBase } from 'app/components/forms';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CategoryTranslationsDataService } from './category-translations-data.service';

@Injectable()
export class CategoryTranslationsFormService extends FormServiceBase<{ id: number; languageId: number }> {
    public get languageId() {
        return this.getKey('languageId');
    }

    constructor(dialogs: DialogsService, translate: TranslateService, formHelper: FormHelper, public readonly dataService: CategoryTranslationsDataService) {
        super(dialogs, formHelper, translate);
    }

    get titleName() {
        let text = '';
        if (this.form && this.form.get('textTranslations.targetTranslation.name')) {
            text = this.form.get('textTranslations.targetTranslation.name').value;
            text = text == null ? '' : text;
        }
        return text;
    }

    getTextTranslations() {
        let group = this.form.get('textTranslations');
        if (!group) {
            group = new UntypedFormGroup({
                sourceTranslations: this.formHelper.localizationGroup(() => {
                    const group = new UntypedFormGroup({
                        id: new UntypedFormControl(this.id),
                        languageId: new UntypedFormControl(this.languageId),
                        bottomDescription: new UntypedFormControl(),
                        bottomDescriptionAnnotation: new UntypedFormControl(),
                        commodityBottomText: new UntypedFormControl(null, Validators.maxLength(2000)),
                        commodityFaq: new UntypedFormControl(),
                        commodityUpperText: new UntypedFormControl(null, Validators.maxLength(2000)),
                        descriptionTag: new UntypedFormControl(null, Validators.maxLength(2000)),
                        keywords: new UntypedFormControl(null, Validators.maxLength(5000)),
                        name: new UntypedFormControl(null, [Validators.maxLength(50), Validators.required]),
                        navigationText: new UntypedFormControl(null, Validators.maxLength(4000)),
                        redText: new UntypedFormControl(null, Validators.maxLength(59)),
                        tooltip: new UntypedFormControl(null),
                        responsiveAnnotation: new UntypedFormControl(false),
                        seoName: new UntypedFormControl(null, [Validators.maxLength(500), Validators.required]),
                        title: new UntypedFormControl(null, Validators.maxLength(150)),
                        upperDescription: new UntypedFormControl(),
                        upperDescriptionAnnotation: new UntypedFormControl(),
                        upperDescriptionInfoText: new UntypedFormControl(null),
                        url: new UntypedFormControl(null, Validators.maxLength(300)),
                        useOriginUrl: new UntypedFormControl({ value: null, disabled: true })
                    });
                    return group;
                }),
                targetTranslation: new UntypedFormGroup({
                    id: new UntypedFormControl(this.id),
                    languageId: new UntypedFormControl(this.languageId),
                    bottomDescription: new UntypedFormControl(null, Validators.cdnImage),
                    bottomDescriptionAnnotation: new UntypedFormControl(null, Validators.cdnImage),
                    commodityBottomText: new UntypedFormControl(null, [Validators.maxLength(2000), Validators.cdnImage]),
                    commodityFaq: new UntypedFormControl(null, Validators.cdnImage),
                    commodityUpperText: new UntypedFormControl(null, [Validators.maxLength(2000), Validators.cdnImage]),
                    descriptionTag: new UntypedFormControl(null, Validators.maxLength(2000)),
                    keywords: new UntypedFormControl(null, Validators.maxLength(5000)),
                    name: new UntypedFormControl(null, [Validators.maxLength(50), Validators.required]),
                    navigationText: new UntypedFormControl(null, Validators.maxLength(4000)),
                    redText: new UntypedFormControl(null, Validators.maxLength(59)),
                    tooltip: new UntypedFormControl(null, Validators.maxLength(500)),
                    responsiveAnnotation: new UntypedFormControl(false),
                    seoName: new UntypedFormControl(null, [Validators.maxLength(500), Validators.required]),
                    title: new UntypedFormControl(null, Validators.maxLength(150)),
                    upperDescription: new UntypedFormControl(null, Validators.cdnImage),
                    upperDescriptionAnnotation: new UntypedFormControl(null, Validators.cdnImage),
                    upperDescriptionInfoText: new UntypedFormControl(null, Validators.cdnImage),
                    url: new UntypedFormControl(null, [Validators.required, Validators.maxLength(300), Validators.url('relativeOrAbsolute')]),
                    useOriginUrl: new UntypedFormControl({ value: null, disabled: true })
                }),
                fieldsToHighlight: new UntypedFormGroup({
                    bottomDescription: new UntypedFormControl(false),
                    bottomDescriptionAnnotation: new UntypedFormControl(false),
                    commodityBottomText: new UntypedFormControl(false),
                    commodityFaq: new UntypedFormControl(false),
                    commodityUpperText: new UntypedFormControl(false),
                    descriptionTag: new UntypedFormControl(false),
                    keywords: new UntypedFormControl(false),
                    name: new UntypedFormControl(false),
                    navigationText: new UntypedFormControl(false),
                    redText: new UntypedFormControl(false),
                    tooltip: new UntypedFormControl(false),
                    seoName: new UntypedFormControl(false),
                    title: new UntypedFormControl(false),
                    upperDescription: new UntypedFormControl(false),
                    upperDescriptionAnnotation: new UntypedFormControl(false),
                    upperDescriptionInfoText: new UntypedFormControl(false),
                    url: new UntypedFormControl(false)
                })
            });
            this.form.addControl('textTranslations', group);
            this.formHelper.disableAll(group.get('sourceTranslations'));

            this.formReloadSubscriptions(
                group.get(`sourceTranslations.${this.languageId}.useOriginUrl`).valueChanges.subscribe((useOriginUrl) => {
                    if (useOriginUrl) {
                        this.formHelper.disableAll(group.get('targetTranslation'));
                        group.get('targetTranslation.name').enable();
                        group.get('targetTranslation.navigationText').enable();
                    }
                })
            );

            this.dataService
                .loadTextTranslations(this.id, this.languageId)
                .pipe(
                    handle404(),
                    tap((data) => {
                        if (data.fieldsToHighlight === null) {
                            delete data.fieldsToHighlight;
                        }
                        group.patchValue(data);
                    })
                )
                .subscribe();
        }
        return group as UntypedFormGroup;
    }

    public save(reload: boolean) {
        if (!this.validate()) {
            return throwError(null);
        }
        const data = (this.form.get('textTranslations.targetTranslation') as UntypedFormGroup).getRawValue();
        return this.dataService.saveTextTranslations(this.id, this.languageId, data).pipe(
            tap(() => {
                if (reload) {
                    this.init({ id: this.id, languageId: this.languageId });
                }
            }),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 400) {
                    this.dialogs.badRequestMessage(err);
                }
                return throwError(err);
            })
        );
    }
}
