import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './category-description-update.component.html',
    styleUrls: ['./category-description-update.component.scss']
})
export class CategoryDescriptionUpdateComponent {
    saveLastUpdated = false;

    constructor(private dialogRef: MatDialogRef<CategoryDescriptionUpdateComponent>) {}

    closeDialog(save: boolean) {
        if (save) {
            this.dialogRef.close(this.saveLastUpdated);
        } else {
            this.dialogRef.close();
        }
    }
}
