import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CommonDataService } from 'app/common/services';
import { ICategoryProducerDto } from 'app/models/category';
import { CategoryTopProducerSource } from 'app/models/enums/category';
import { Observable, combineLatest, takeUntil } from 'rxjs';
import { CategoryBaseComponent } from '../../category-base.component';
import { CategoryDataService } from '../../category-data.service';
import { CategoryFormService } from '../../category-form.service';

@Component({
    selector: 'app-top-producers',
    templateUrl: './top-producers.component.html',
    styleUrls: ['./top-producers.component.scss']
})
export class TopProducersComponent extends CategoryBaseComponent implements OnInit {
    @Input() selectedCountryChanged: Observable<number>;
    public producersFullList: Array<ICategoryProducerDto> = [];
    public producersList: Array<ICategoryProducerDto> = [];
    public producerToAdd: number;
    public topProducers: UntypedFormArray;
    public selectedCountryId: number = null;
    public topProducerCountValues: Array<number> = Array.from({ length: 12 }, (x, i) => 5 + i);
    public categoryTopProducerSources = CategoryTopProducerSource;

    constructor(
        readonly commonDataService: CommonDataService,
        public readonly dataService: CategoryDataService,
        public readonly formBuilder: UntypedFormBuilder,
        readonly formService: CategoryFormService
    ) {
        super();
    }

    public get topProducersOrdered() {
        if (this.topProducers === undefined) {
            return null;
        }
        return this.topProducers.controls.sort((a, b) => a.get('source').value - b.get('source').value);
    }

    public countryForm: UntypedFormGroup;
    public countries: UntypedFormArray;

    ngOnInit() {
        this.dataService.categoryProducers(this.formService.id).subscribe((res) => {
            this.producersFullList = res;
            this.updateProducerList();
        });

        this.selectedCountryChanged.subscribe((selectedCountryId: number) => {
            this.setForms(selectedCountryId);
            this.selectedCountryId = selectedCountryId;
        });
        this.formService.reload$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.formService.formReloadSubscriptions(
                combineLatest([this.formService.topCountryProducers, this.formService.countriesLoaded]).subscribe(([topProducersloaded, countriesLoaded]) => {
                    // for form reinicializations
                    if (!(topProducersloaded && countriesLoaded)) {
                        return;
                    }
                    if (this.selectedCountryId === null) {
                        const topCountryProducers = this.formService.getTopCountryProducers();
                        if (topCountryProducers.controls.length) {
                            this.selectedCountryId = topCountryProducers.controls[0].get('countryId').value as number;
                        }
                    }
                    this.setForms(this.selectedCountryId);
                })
            );
        });
    }

    private setForms(selectedCountryId: number) {
        this.countryForm = this.formService.getCountries().controls.find((c) => c.value.countryId === selectedCountryId) as UntypedFormGroup;
        this.form = this.formService.getTopCountryProducers().controls.find((c) => c.value.countryId === selectedCountryId) as UntypedFormGroup;

        if (this.form !== undefined && this.countryForm !== undefined) {
            this.topProducers = this.form.get('topProducers') as UntypedFormArray;
            this.updateProducerList();
        }
    }

    public get isLocked() {
        return !this.formService.isEditable('topProducers');
    }

    public updateProducerList() {
        if (this.topProducers === undefined) {
            return;
        }
        const includedItemIds = this.topProducers.getRawValue().map((x) => x.producerId);
        this.producersList = this.producersFullList.filter((x) => !includedItemIds.includes(x.id));
    }

    removeItem(id: number) {
        const formIndex = this.topProducers.controls.findIndex((item) => item.get('producerId').value === id);
        if (formIndex >= 0) {
            this.topProducers.removeAt(formIndex);
        }
        this.updateProducerList();
    }

    addItem() {
        if (!this.topProducers.getRawValue().some((x) => x.producerId === this.producerToAdd)) {
            this.topProducers.push(
                this.formService.createAndFillTopProducerControl({
                    producerId: this.producerToAdd,
                    index: this.topProducers.getRawValue().filter((x) => x.source == 0).length + 1,
                    name: this.producersList.filter((x) => x.id === this.producerToAdd)[0].name,
                    countryId: this.form.get('countryId').value,
                    source: CategoryTopProducerSource.Manual
                })
            );
            this.updateProducerList();
        }
        this.producerToAdd = null;
    }

    public onDrop(event: CdkDragDrop<any>) {
        if (event.container.id === 'orderList') {
            moveItemInArray(this.topProducers.controls, event.previousIndex, event.currentIndex);
            this.topProducers.updateValueAndValidity();
        }
    }

    public moveProducerToManual(item: UntypedFormGroup) {
        const producer = this.topProducers.controls.find((x) => x.get('producerId').value == item.get('producerId').value);
        if (producer) {
            const index = this.topProducers.getRawValue().filter((x) => x.source == 0).length + 1;
            producer.get('index').setValue(index);
            producer.get('source').setValue(CategoryTopProducerSource.Manual);
            this.topProducersReorder();
        }
    }

    private topProducersReorder() {
        this.topProducersOrdered
            .filter((x) => x.get('source').value == CategoryTopProducerSource.Auto)
            .forEach((producer, index) => {
                producer.get('index').setValue(index + 1);
            });
    }
}
