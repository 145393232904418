<app-page-header [title]="'CompatibleParameterValues_CompatibleParameterValues_Title' | translate">
    <breadcrumb [text]="'Menu_Commodity' | translate"></breadcrumb>
    <breadcrumb [text]="'CompatibleParameterValues_CompatibleParameterValues_Title' | translate"></breadcrumb>
</app-page-header>

<div class="wrapper-content animated fadeInRight">
    <div class="ibox">
        <div class="ibox-content">
            <h1>{{ 'CompatibleParameterValues_OverviewOfCategoriesAndGroups' | translate }}</h1>
            <div class="breadcrumbs">
                <span
                    *ngFor="let category of categoryPath.path; let last = last"
                    [attr.data-tid]="'bre_' + category.name"
                    (click)="categorySelected = category"
                >
                    / {{ category.name }}
                </span>
            </div>
            <hr />
            <div class="row">
                <div *ngIf="categoryList" class="col-xs-4">
                    <h2 title="{{ 'CompatibleParameterValues_Categories' | translate }}">{{ 'CompatibleParameterValues_Categories' | translate }}</h2>
                    <div class="collectionCategoryList">
                        <div class="item" *ngIf="categoryPath.isPathVisible" (click)="categorySelected = categoryPath.previousNode">..</div>
                        <div class="item" [attr.data-tid]="'cat_' + category.name" *ngFor="let category of categoryList" (click)="categorySelected = category">
                            {{ category.name }}
                        </div>
                    </div>
                </div>

                <div class="col-xs-4">
                    <h2 title="{{ 'CompatibleParameterValues_Groups' | translate }}">{{ 'CompatibleParameterValues_Groups' | translate }}</h2>
                    <div *ngIf="!!(parameterGroupList && parameterGroupList.length > 0)" class="collectionGroupList">
                        <div
                            title="{{ group.name }}"
                            [attr.data-tid]="'gro_' + group.name"
                            class="item"
                            *ngFor="let group of parameterGroupList"
                            (click)="parameterGroupSelected = group"
                            [class.selected]="!!(parameterGroupSelected && parameterGroupSelected.id === group.id)"
                        >
                            {{ group.name }}
                        </div>
                    </div>
                </div>

                <div *ngIf="!!(parameterList && parameterList.length > 0)" class="col-xs-4">
                    <h2 title="{{ 'CompatibleParameterValues_Types' | translate }}">{{ 'CompatibleParameterValues_Types' | translate }}</h2>
                    <div class="collectionParameterList">
                        <div
                            class="item"
                            [attr.data-tid]="'par_' + parameter.name"
                            [class.selected]="!!(parameterSelected && parameterSelected.id === parameter.id)"
                            *ngFor="let parameter of parameterList"
                            (click)="goto(parameter)"
                        >
                            <a [routerLink]="['/CompatibleParameterValues', parameter.id]">{{ parameter.name }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-page-footer></app-page-footer>
