import { Component, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CommonDataService } from 'app/common/services';
import { Subject, takeUntil } from 'rxjs';
import { CategoryBaseComponent } from '../../category-base.component';
import { CategoryFormService } from '../../category-form.service';

@Component({
    templateUrl: './top-producer-countries.component.html',
    styleUrls: ['./top-producer-countries.component.scss']
})
export class TopProducerCountriesComponent extends CategoryBaseComponent<UntypedFormArray> implements OnInit {
    public selectedCountryChanged: Subject<number> = new Subject<number>();
    public selectedCountryId: number;

    constructor(readonly commonDataService: CommonDataService, readonly formService: CategoryFormService, readonly translateService: TranslateService) {
        super();
    }

    ngOnInit() {
        this.selectedCountryId = null;
        this.formService.reload$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.form = this.formService.getTopCountryProducers();
            this.formService.formReloadSubscriptions(
                this.formService.topCountryProducers.subscribe((loaded) => {
                    // for form reinicializations
                    if (!loaded) {
                        return;
                    }

                    if (this.selectedCountryId === null) {
                        this.select(this.form.controls.length > 0 && (this.form.controls[0].get('countryId').value as number));
                    }
                })
            );
        });
    }

    select(countryId: number | null) {
        this.selectedCountryId = countryId;
        this.selectedCountryChanged.next(this.selectedCountryId);
    }
}
