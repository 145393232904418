import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'app/common/constants';
import { DialogsService } from 'app/common/services/dialogs.service';
import { SessionService } from 'app/common/services/session.service';
import { LoginDataService } from '../login-data.service';

@Component({
    selector: 'app-unconfirmed-email',
    templateUrl: './unconfirmed-email.component.html',
    styleUrls: ['./unconfirmed-email.component.scss']
})
export class UnconfirmedEmailComponent implements OnInit, OnDestroy {
    public email: string;
    public required = false;

    constructor(
        private readonly dataService: LoginDataService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly dialogs: DialogsService,
        private readonly translate: TranslateService,
        private readonly session: SessionService
    ) {}

    ngOnInit() {
        this.dataService.getUserEmail().subscribe((res) => {
            this.email = res.email;
        });
        this.required = !!this.route.snapshot.queryParams['required'];
        this.session.fullView = true;
    }

    ngOnDestroy() {
        this.session.fullView = false;
    }

    public sendEmail() {
        this.dataService.sendConfirmationEmail().subscribe(() => {
            this.dialogs.successMessage(this.translate.instant('Login_ConfirmEmail_EmailSend'), '', { timer: 10000 });
            this.router.navigate(['/dashboard']).catch(EMPTY);
        });
    }
}
