import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class CategoryTranslationsDataService {
    private _apiRoute = '/api/category/';

    constructor(private http: HttpClient) {}

    public saveTextTranslations(id: number, languageId: number, data: any) {
        return this.http.patch(this._apiRoute + `${id}/texts-translations/${languageId}`, data);
    }

    public loadTextTranslations(id: number, languageId: number) {
        return this.http.get<any>(this._apiRoute + `${id}/texts-translations/${languageId}`);
    }

    public textTranslationsAssignedCount() {
        return this.http.get<number>(this._apiRoute + 'text-translations-assigned-count');
    }
}
