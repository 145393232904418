import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'app/common/constants';
import { DialogsService } from 'app/common/services/dialogs.service';
import { MenuService } from 'app/common/services/menu.service';
import { SessionService } from 'app/common/services/session.service';
import { FormHelper } from 'app/components/forms/form-helper.service';
import { SignInResult } from 'app/models/enums';
import { LoginDataService } from '../login-data.service';
import { VerifyTwoFaFormService } from '../verify-two-fa-form.service';

@Component({
    selector: 'app-verify-two-fa',
    templateUrl: './verify-two-fa.component.html',
    styleUrls: ['./verify-two-fa.component.scss'],
    providers: [VerifyTwoFaFormService]
})
export class VerifyTwoFaComponent implements OnInit, OnDestroy, AfterViewChecked {
    public form: UntypedFormGroup;
    public message: string;
    public twoFaEnabled: boolean;
    public phoneNumber: string;
    public returnUrl: string;
    @ViewChildren('input', { read: ElementRef })
    input?: QueryList<ElementRef>;

    constructor(
        private readonly translate: TranslateService,
        private readonly formHelper: FormHelper,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly dialogs: DialogsService,
        private readonly dataService: LoginDataService,
        private readonly formService: VerifyTwoFaFormService,
        private readonly menuService: MenuService,
        private readonly sessionService: SessionService
    ) {}

    ngOnInit() {
        this.sessionService.fullView = true;
        this.route.queryParams.subscribe((query) => {
            this.returnUrl = query['returnUrl'] ? query['returnUrl'] : '';
        });

        this.dataService.is2FaEnabled().subscribe((res) => {
            this.twoFaEnabled = res.twoFactorAuthenticationEnabled;
            this.phoneNumber = res.phoneNumber;
            this.form = this.formService.initVerify2faForm();
        });
    }

    ngOnDestroy() {
        this.sessionService.fullView = false;
    }

    ngAfterViewChecked(): void {
        if (this.input) {
            if (this.input.first) {
                this.input.first.nativeElement.focus();
            }
        }
    }

    public getText() {
        const phoneLastDigits = this.phoneNumber.substring(this.phoneNumber.length - 2);

        return this.twoFaEnabled
            ? this.translate.instant('Login2Fa_MessageApp')
            : `${this.translate.instant('Login2Fa_MessageSms_1')} ${phoneLastDigits} ${this.translate.instant('Login2Fa_MessageSms_2')}`;
    }

    public verify() {
        if (this.form && !this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        this.dataService.verify2Fa(this.form.value).subscribe(
            (result) => {
                this.message = '';
                switch (result.signInResult) {
                    case SignInResult.Failed:
                        this.message = this.translate.instant('Login_InvalidCode');
                        this.form.reset();
                        break;
                    case SignInResult.Succeeded:
                        this.navigate();
                        break;
                    case SignInResult.AccountLocked:
                        this.message = this.translate.instant('Login_TemporaryBlockedAccount');
                        break;
                    case SignInResult.AccountBlocked:
                        this.message = this.translate.instant('Login_BlockedAccount');
                        break;
                    case SignInResult.SucceededRequireEmailVerification:
                        this.router.navigate(['/unconfirmed-email']).catch(EMPTY);
                        break;
                    default:
                        break;
                }
            },
            (err: HttpErrorResponse) => {
                if (err.status === 400) {
                    this.dialogs.badRequestMessage(err);
                }
            }
        );
    }

    public navigate() {
        this.menuService.getMenuItem(this.returnUrl).subscribe((result) => {
            if (this.returnUrl && result) {
                if (!result.isMvcModule) {
                    this.router.navigateByUrl(this.returnUrl).catch(EMPTY);
                } else {
                    window.location.href = decodeURIComponent(this.returnUrl);
                }
            } else {
                this.router.navigate(['/dashboard/']).catch(EMPTY);
            }
            return;
        });
    }
}
