import { EMPTY } from 'app/common/constants';
import { CompatibleParameterValuesDataService } from './compatible-parameter-values-data.service';
import { ICategoryDto, IParameterDto, IParameterGroupDto } from './compatible-parameter-values-dto';
import { Path } from './path';

export class FindParameterByCategory {
    public categoryPath: Path<ICategoryDto>;
    private _parameterGroupSelected: IParameterGroupDto | null = null;
    public parameterSelected: IParameterDto | null = null;
    public categoryList: Array<ICategoryDto> | null = null;
    public parameterGroupList: Array<IParameterGroupDto> | null = null;
    public parameterList: Array<IParameterDto> | null = null;

    constructor(protected dataService: CompatibleParameterValuesDataService) {
        // shortcut, since we know first/root category
        this.categoryPath = new Path<ICategoryDto>({ id: 1, name: 'Alza' });
        this.restoreState();
    }

    public saveState() {
        this.dataService.persistentCategoryId = this.categoryPath.current.id;
        this.dataService.persistentGroupId = this.parameterGroupSelected !== null ? this.parameterGroupSelected.id : null;
    }

    public restoreState() {
        this.loadPath(this.dataService.persistentCategoryId);
        this.loadCategories(this.dataService.persistentCategoryId);
        this.loadCategoryGroups(this.dataService.persistentCategoryId)
            .then(() => {
                if (this.dataService.persistentGroupId && this.parameterGroupList) {
                    this.parameterGroupSelected = this.parameterGroupList.find((t) => t.id === this.dataService.persistentGroupId) || null;
                }
            })
            .catch(EMPTY);
        this.getGroupParameters(this.dataService.persistentGroupId);
    }

    public loadPath(categoryId: number) {
        this.dataService.getPath(categoryId).subscribe((res) => (this.categoryPath.path = res));
    }

    public get categorySelected() {
        return this.categoryPath.current;
    }

    public set categorySelected(value) {
        this.categoryPath.findOrPush(value, (obj1, obj2) => obj1.id === obj2.id);
        this.loadCategories(this.categoryPath.current.id);
        this.loadCategoryGroups(this.categoryPath.current.id).catch(EMPTY);
        this.saveState();
    }

    loadCategories(categoryId: number | null) {
        this.categoryList = null;
        if (categoryId !== null) {
            this.dataService.getCategories(categoryId).subscribe((res) => (this.categoryList = res));
        }
    }

    loadCategoryGroups(categoryId: number | null) {
        return new Promise<void>((resolve) => {
            this.parameterSelected = null;
            this.parameterList = null;
            this._parameterGroupSelected = null;
            this.parameterGroupList = null;
            if (categoryId === null) {
                return;
            }
            this.dataService.getCategoryGroups(categoryId).subscribe((res) => {
                this.parameterGroupList = res;
                resolve();
            });
        });
    }

    getGroupParameters(groupId: number | null) {
        this.parameterSelected = null;
        this.parameterList = null;
        if (groupId === null) {
            return;
        }
        this.dataService.getGroupParameters(groupId).subscribe((res) => (this.parameterList = res));
    }

    public get parameterGroupSelected() {
        return this._parameterGroupSelected;
    }

    public set parameterGroupSelected(value) {
        this._parameterGroupSelected = value;
        this.getGroupParameters(value ? value.id : null);
        this.saveState();
    }
}
