<app-grid data-tid="table">
    <table class="table table-striped table-bordered table-hover dataTable">
        <thead>
            <tr role="row">
                <th [appSortableColumn]="1" data-tid="categoryId">
                    <span class="colTitle">{{ 'CategoryProducerTranslations_CategoryId' | translate }}</span>
                </th>
                <th [appSortableColumn]="2" data-tid="name">
                    <span class="colTitle">{{ 'Category_Name' | translate }}</span>
                </th>
                <th [appSortableColumn]="3" data-tid="seoName">
                    <span class="colTitle">{{ 'Category_SeoName' | translate }}</span>
                </th>
                <th [appSortableColumn]="4" data-tid="producerId">
                    <span class="colTitle">{{ 'CategoryProducerTranslations_ProducerId' | translate }}</span>
                </th>
                <th [appSortableColumn]="5" data-tid="producerName">
                    <span class="colTitle">{{ 'Category_ProducerName' | translate }}</span>
                </th>
                <th>
                    <span class="colTitle">{{ 'Category_Language' | translate }}</span>
                </th>
                <th [appSortableColumn]="0" data-tid="rank">
                    <span class="colTitle">{{ 'CategoryProducerTranslations_Rank' | translate }}</span>
                </th>
                <th [appSortableColumn]="6" data-tid="dueDate">
                    <span class="colTitle">{{ 'CategoryProducerTranslations_DueDate' | translate }}</span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr (click)="openDetail(item)" role="row" [attr.data-tid]="'row_' + ix" *ngFor="let item of pager.items; let ix = index">
                <td data-tid="cell_id">{{ item.categoryId }}</td>
                <td data-tid="cell_name">{{ item.categoryName }}</td>
                <td data-tid="cell_seoName">{{ item.categorySeoName }}</td>
                <td data-tid="cell_producerId">{{ item.producerId }}</td>
                <td data-tid="cell_producerName">{{ item.producerName }}</td>
                <td data-tid="cell_language">{{ item.languageName }}</td>
                <td data-tid="cell_rank">{{ item.rank }}</td>
                <td data-tid="cell_dueDate">{{ item.dueDate | moment }}</td>
            </tr>
        </tbody>
    </table>
</app-grid>
