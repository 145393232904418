import { Validators } from '@alza/cms-components';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, defaultCountryTranslation, maxSmallInt, minSmallInt } from 'app/common/constants';
import { ICountryTranslationDto, IObjectLock } from 'app/common/dto/common-dto';
import { Languages } from 'app/common/enums';
import { handle404 } from 'app/common/operators';
import { DialogsService } from 'app/common/services/dialogs.service';
import { ObjectLockService } from 'app/common/services/object-lock.service';
import { SessionService } from 'app/common/services/session.service';
import { FormHelper, FormServiceBase } from 'app/components/forms';
import { createAndFillParameterControl, getParameterValuesForModal } from 'app/components/parameter-values/parameter-values';
import { IIdNameDto, ISelectListItemDto } from 'app/models';
import {
    ICategoryCountryExtendedDto,
    ICategoryImagesDto,
    ICategoryMainDto,
    ICategoryMainExtendedWithLockDto,
    ICategoryParametersDto,
    ICategoryProducerDto,
    ICategoryTextsDto,
    ICategoryTextsLocalizationDto,
    ICategoryTopProducerDto,
    ICategoryTopProducerExtendedDto
} from 'app/models/category';
import { CategoryCountryVisibilityChange } from 'app/models/enums';
import { CategoryTopProducerSource } from 'app/models/enums/category';
import { IParameterFilterDto } from 'app/models/parameter';
import { ITagRelationDto, ITagRelationExtendedDto } from 'app/models/tag';
import { Observable, ReplaySubject, of, throwError } from 'rxjs';
import { catchError, debounceTime, first, map, switchMap, tap } from 'rxjs/operators';
import { ParameterTypeDataService } from '../parameter/parameter-type-data.service';
import { CategoryDataService } from './category-data.service';
import { CategoryDescriptionUpdateComponent } from './dialogs/category-description-update.component';
import { ICategoryCompositeClientDto } from './dto/category-composite-client-dto';
import { ICategoryEditRights } from './dto/category-edit-rights';

@Injectable()
export class CategoryFormService extends FormServiceBase {
    public producers: Array<ISelectListItemDto> = [];
    public producersLoaded = new ReplaySubject<boolean>(1);
    public topCountryProducers = new ReplaySubject<boolean>(1);
    public countriesLoaded = new ReplaySubject<boolean>(1);
    public producersToAdd: Array<ISelectListItemDto> = [];
    public selectedCountry = defaultCountryTranslation;
    public parameterProducers: Array<ICategoryProducerDto>;
    public categoryFolder: string;
    public modifiedProducers: Array<number> = [];
    public producerCanonicalId: number;
    private _originalTextsValue: ICategoryTextsDto;
    private _editRights: ICategoryEditRights = { editAll: false, texts: false, producerTexts: false };
    private _fallbackRoute = ['category'];
    private _isLocked = true;
    private _objectLock = new ReplaySubject<IObjectLock>(1);
    private _languagesToTranslate?: Array<ICountryTranslationDto> = [];
    private _requiredNameUrlBasedOnLanguageInProgress = false;
    private _textVisibilityChangeObservable: Array<Observable<CategoryCountryVisibilityChange>> = [];
    private _textVisibilityChangeEnum: Array<CategoryCountryVisibilityChange> = [];
    private _formTextKeys = [
        'bottomDescription',
        'bottomDescriptionAnnotation',
        'commodityBottomText',
        'commodityFaq',
        'commodityUpperText',
        'descriptionTag',
        'keywords',
        'name',
        'navigationText',
        'redText',
        'seoName',
        'title',
        'upperDescription',
        'upperDescriptionInfoText',
        'upperDescriptionAnnotation',
        'url',
        'tooltip'
    ];
    private _contentAgeFields = ['upperDescriptionLastUpdate', 'bottomDescriptionLastUpdate', 'productInfoLastUpdate'];
    private _catNavTypeEditableWithTypes = [5, 28, 29]; // ACTION, BLACKFRIDAYACTION, MAILINGACTION

    constructor(
        public readonly dataService: CategoryDataService,
        dialogs: DialogsService,
        private readonly matDialogs: MatDialog,
        private readonly fb: UntypedFormBuilder,
        formHelper: FormHelper,
        private readonly objectLockService: ObjectLockService,
        private readonly parameterDataService: ParameterTypeDataService,
        private readonly router: Router,
        private readonly session: SessionService,
        translate: TranslateService
    ) {
        super(dialogs, formHelper, translate);
        this._editRights.editAll = this.session.user.hasPermission('Category_edit');
        this._editRights.texts = this._editRights.editAll || this.session.user.hasPermission('CategoryText_edit');
        this._editRights.producerTexts = this._editRights.editAll || this.session.user.hasPermission('CategoryProducerText_edit');
    }

    public get localizationTextsAsFormArray() {
        return this.form.get('texts.localization') as UntypedFormGroup;
    }

    public get isLocked() {
        this.getMain();
        return this._isLocked;
    }

    public isEditable(compositeKey: keyof ICategoryCompositeClientDto) {
        return !this.isLocked && this.hasEditRights(compositeKey);
    }

    public get objectLock$() {
        this.getMain();
        return this._objectLock.asObservable();
    }

    public getLanguagesToTranslate() {
        if (this._languagesToTranslate !== undefined) {
            return of(this._languagesToTranslate);
        }
    }

    get titleName() {
        return this.getMain()?.get('name')?.value || '';
    }

    public hasEditRights(compositeKey: keyof ICategoryCompositeClientDto) {
        switch (compositeKey) {
            case 'texts':
                return this._editRights.texts;
            case 'producerTexts':
                return this._editRights.producerTexts;
            default:
                return this._editRights.editAll;
        }
    }

    public getUrlChangeFlags() {
        if (!this.form.get('urlChangeFlags')) {
            const flags = new UntypedFormGroup({
                hasLinkedCategoryProducerSettings: new UntypedFormControl(),
                hasSubstituteExternalCategories: new UntypedFormControl()
            });
            this.form.addControl('urlChangeFlags', flags);
            this.lockReloadSubscriptions('main', flags, () => {
                if (this.id !== 0) {
                    this.dataService
                        .categoryUrlChangeFlags(this.id)
                        .pipe(tap((detail) => this.form.get('urlChangeFlags').patchValue(detail)))
                        .subscribe(EMPTY, (err: HttpErrorResponse) => {
                            this.dialogs.badRequestMessage(err);
                            this.router.navigate(this._fallbackRoute).catch(EMPTY);
                        });
                }
            });
        }
        return this.form.get('urlChangeFlags') as UntypedFormGroup;
    }

    public isLockLocked(objectLock: IObjectLock) {
        if (objectLock && objectLock.userId && objectLock.userId !== this.session.user.id) {
            return true;
        }
        return false;
    }

    // subscribes to object lock. When lock is ready:
    // LOCKED: will disable whole form, and call alwayscallback method
    // UNLOCKED: Call canEditCallback (for value changes subscriptions) and then alwayscallback() for data load
    // we need to see data even on locked form, but we have to load them after lock is checked
    // because there are many valuechange subscribtions that enable/disable parts of the form
    private lockReloadSubscriptions(
        compositeKeyForEditRightsCheck: keyof ICategoryCompositeClientDto,
        formToDisable: AbstractControl,
        alwaysCallback?: () => void,
        canEditCallback?: () => void
    ) {
        this.formReloadSubscriptions(
            this.objectLock$.subscribe((objectLock) => {
                if (!objectLock) {
                    return;
                }
                if (this.isLockLocked(objectLock) || !this.hasEditRights(compositeKeyForEditRightsCheck)) {
                    this.formHelper.disableAll(formToDisable, { emitEvent: false });
                } else if (canEditCallback) {
                    canEditCallback();
                }
                if (alwaysCallback) {
                    alwaysCallback();
                }
            })
        );
    }

    getParameterInfo() {
        if (!this.form.get('parameterInfo')) {
            const parameterInfo = new UntypedFormControl(null, Validators.cdnImage);
            this.form.addControl('parameterInfo', parameterInfo);
            this.lockReloadSubscriptions('parameterInfo', parameterInfo);
            if (this.id !== 0) {
                this.dataService
                    .categoryParameterInfo(this.id)
                    .pipe(
                        tap((data) => {
                            parameterInfo.patchValue(data.parameterInfo);
                        })
                    )
                    .subscribe(EMPTY, (err: HttpErrorResponse) => {
                        this.dialogs.badRequestMessage(err);
                        this.router.navigate(this._fallbackRoute).catch(EMPTY);
                    });
            }
        }
        return this.form.get('parameterInfo') as UntypedFormControl;
    }

    getMain() {
        if (!this.form.get('main')) {
            const main = this.fb.group({
                typeId: { value: null, disabled: true },
                navigationCategoryTypeId: null,
                navigationActionId: null,
                isSeoCategory: null,
                enableAutoGeneratedPages: null,
                navigationHide: null,
                navigationShowMore: null,
                navigationColumn: [null, [Validators.required, Validators.int(minSmallInt), Validators.max(maxSmallInt)]],
                isParametricGenerated: null,
                isMain: null,
                isTopMenu: null,
                isComparable: null,
                showRecommendedProducts: null,
                showParametersInLegend: null,
                producerPosition: [null, [Validators.int()]],
                sort: null,
                sourceSectionId: null,
                wearType: null,
                hideInProduction: null,
                hideInApi: null,
                sideBannerDisabled: null,
                excludedBannerCategories: [],
                highlighted: { value: null, disabled: true },
                highlightedFontColor: null,
                showProducersParametersAtBeginning: null,
                enableExternalArticles: null,
                webDisplayType: null,
                targetGroup: [null, [Validators.required]],
                useOnlyOnFloor: null,
                upperDescriptionShouldBeFilled: false,
                upsellRepeatedPurchases: null,
                upsellShouldHave: null,
                upsellShouldHaveLifetime: [null, [Validators.int(0), Validators.max(99)]],
                name: { value: '', disabled: true },
                isSupplementary: { value: null, disabled: true },
                isSubstitute: { value: null, disabled: true },
                isInvisible: { value: null, disabled: true },
                isExternal: { value: null, disabled: true },
                level: { value: null },
                originCategoryId: { value: null, disabled: true },
                originCategoryName: { value: '', disabled: true },
                redirectToOriginal: { value: null, disabled: true },
                hideInChildrenUrls: { value: null, disabled: true },
                parametersRequired: null,
                objectLock: null,
                showPcConfiguratorSource: null,
                showDiscussionPost: null,
                catalogNavigationCategoryType: { value: null, disabled: true },
                showLiveActivityFeed: null
            });
            this.form.addControl('main', main);
            this.lockReloadSubscriptions('main', main);
            if (this.id !== 0) {
                this.dataService
                    .loadMain(this.id)
                    .pipe(
                        handle404({ command: this._fallbackRoute, extras: {} }),
                        tap((detail) => {
                            main.patchValue(detail);
                            if (main.get('isSubstitute').value) {
                                main.get('enableAutoGeneratedPages').disable();
                                main.get('isParametricGenerated').disable();
                            }
                            if (main.get('highlighted').value) {
                                main.get('highlightedFontColor').enable();
                            }
                            if (this._catNavTypeEditableWithTypes.includes(main.get('typeId').value)) {
                                main.get('catalogNavigationCategoryType').enable();
                            }
                        })
                    )
                    .subscribe();
            }
            this.formReloadSubscriptions(
                main.get('objectLock').valueChanges.subscribe((objectLock: IObjectLock) => {
                    this._isLocked = this.isLockLocked(objectLock);
                    this._objectLock.next(objectLock);
                }),
                main.get('navigationCategoryTypeId').valueChanges.subscribe((navigationCategoryTypeId) => {
                    if (navigationCategoryTypeId) {
                        main.get('navigationActionId').enable();
                        main.get('navigationActionId').setValidators([Validators.required, Validators.int(0), Validators.max(maxSmallInt)]);
                    } else {
                        main.get('navigationActionId').clearValidators();
                        main.get('navigationActionId').setValue(null);
                        main.get('navigationActionId').disable();
                    }
                    main.get('navigationActionId').updateValueAndValidity();
                })
            );
        }
        return this.form.get('main') as UntypedFormGroup;
    }

    getParameterFilters() {
        return this.getParameters().get('parameterFilters') as UntypedFormArray;
    }

    private sourceCategoryChanged(sourceCategoryId: number) {
        const parameter = this.getParameters();
        if (!parameter.get('sourceCategoryId').value || parameter.get('sourceCategoryId').value !== sourceCategoryId) {
            parameter.get('enabled').patchValue(true);
            parameter.get('priceFrom').patchValue(null);
            parameter.get('priceTo').patchValue(null);
            parameter.get('inStock').patchValue(false);
            parameter.get('producers').patchValue([]);
            this.clearParameterFilters();
        }
        if (sourceCategoryId) {
            this.parameterProducers = [];
            this.dataService.categoryProducers(sourceCategoryId).subscribe((val) => {
                this.parameterProducers = val;
            });
            parameter.get('enabled').enable();
            parameter.get('priceFrom').enable();
            parameter.get('priceTo').enable();
            parameter.get('inStock').enable();
            parameter.get('producers').enable();
        } else {
            parameter.get('enabled').disable();
            parameter.get('priceFrom').disable();
            parameter.get('priceTo').disable();
            parameter.get('inStock').disable();
            parameter.get('producers').disable();
        }
    }

    getParameters() {
        if (!this.form.get('parameters')) {
            const parameter = this.fb.group(
                {
                    id: 0,
                    sourceCategoryId: null,
                    enabled: { value: true, disabled: true },
                    priceFrom: [{ value: null, disabled: true }, Validators.int(0)],
                    priceTo: [{ value: null, disabled: true }, Validators.int(0)],
                    inStock: { value: false, disabled: true },
                    producers: { value: [], disabled: true },
                    $state: 'unchanged',
                    parameterFilters: this.fb.array([])
                },
                { validators: [Validators.numberRange({ fromControlName: 'priceFrom', toControlName: 'priceTo' })] }
            );
            this.form.addControl('parameters', parameter);

            this.lockReloadSubscriptions(
                'parameters',
                parameter,
                () => {
                    if (this.id !== 0) {
                        this.dataService.categoryParameters(this.id).subscribe((detail) => {
                            if (detail) {
                                detail.$state = detail.id === 0 ? 'added' : 'modified';
                                this.form.get('parameters').patchValue(detail);
                                const parameterFilters = this.getParameterFilters();
                                detail.parameterFilters.forEach((parameterFilter) =>
                                    parameterFilters.push(this.createAndFillParameterFilterControl({ ...parameterFilter, $state: 'unchanged' }))
                                );
                            }
                            this.lockParamsOnSubstitute();
                        });
                    }
                },
                () => {
                    this.formReloadSubscriptions(parameter.get('sourceCategoryId').valueChanges.subscribe(this.sourceCategoryChanged.bind(this)));
                    this.formReloadSubscriptions(this.getMain().valueChanges.subscribe(this.lockParamsOnSubstitute.bind(this)));
                }
            );
        }
        return this.form.get('parameters') as UntypedFormGroup;
    }

    private lockParamsOnSubstitute() {
        if (this.getMain().get('isSubstitute').value) {
            this.formHelper.disableAll(this.getParameters(), { emitEvent: false });
        }
    }

    private createAndFillParameterFilterControl(parameterFilter: IParameterFilterDto) {
        const formGroup = createAndFillParameterControl(parameterFilter);
        this.lockReloadSubscriptions('parameters', formGroup);
        return formGroup;
    }

    public getParameterValuesForModal(parametersFormArray: UntypedFormArray) {
        return getParameterValuesForModal(parametersFormArray.getRawValue());
    }

    /** removes added filters and sets existing ones as deleted */
    public clearParameterFilters() {
        const parameterFilters = this.getParameterFilters();
        const controlsToRemove = [];
        parameterFilters.controls.forEach((parameterFilter, index) => {
            if (parameterFilter.value['$state'] === 'added') {
                controlsToRemove.push(index);
            } else {
                parameterFilter.patchValue({ $state: 'deleted' });
            }
        });
        controlsToRemove
            .sort((a, b) => b - a) // remove from end so that the indexes won't break
            .forEach((controlId) => {
                parameterFilters.removeAt(controlId);
            });
    }

    public createParameterControl() {
        const group = this.fb.group({
            id: Math.round(Math.random() * -1000),
            parameterTypeGroupId: null,
            parameterTypeEnumId: null,
            value: null,
            valueFrom: null,
            valueTo: null,
            cableLeftTypeEnumId: null,
            cableRightTypeEnumId: null,
            $state: 'added',
            filterInfo: this.fb.group({
                typeGroupId: null,
                renderType: null,
                groupId: null,
                groupName: null,
                isHierarchic: null,
                typeId: null,
                typeName: null,
                typeType: null,
                typeOrder: null,
                typeEnumId: null,
                typeEnumName: null,
                typeEnumParentId: null,
                unitId: null,
                unitName: null,
                cableLeftTypeEnumId: null,
                cableLeftTypeEnumName: null,
                cableRightTypeEnumId: null,
                cableRightTypeEnumName: null
            })
        });
        return group;
    }

    public fillParameterControl(group: UntypedFormGroup, parameter?: Partial<IParameterFilterDto>, skipInfo = false) {
        if (parameter) {
            if (!skipInfo) {
                if (parameter.cableLeftTypeEnumId || parameter.cableRightTypeEnumId) {
                    this.parameterDataService
                        .loadFilterInfo(parameter.parameterTypeGroupId, null, parameter.cableLeftTypeEnumId, parameter.cableRightTypeEnumId)
                        .subscribe((res) => {
                            parameter.filterInfo = res;
                            group.patchValue(parameter);
                        });
                } else {
                    this.parameterDataService.loadFilterInfo(parameter.parameterTypeGroupId, parameter.parameterTypeEnumId).subscribe((res) => {
                        parameter.filterInfo = res;
                        group.patchValue(parameter);
                    });
                }
            } else {
                group.patchValue(parameter);
            }
        }
    }

    getTexts() {
        if (!this.form.get('texts')) {
            this._textVisibilityChangeObservable = [];
            this._textVisibilityChangeEnum = [];
            this.form.addControl(
                'texts',
                this.fb.group({
                    generatedDescriptionType: new UntypedFormControl(null, Validators.required),
                    responsiveAnnotation: false,
                    parameterInfo: null,
                    sendTextsToTranslate: new UntypedFormGroup({
                        languageIds: new UntypedFormControl({ value: [], disabled: true }),
                        fieldsToSend: new UntypedFormGroup({})
                    }),
                    localization: this.formHelper.localizationGroup((languageId) => {
                        const group = this.fb.group({
                            wasFilledAtLoad: new UntypedFormControl(false),
                            seoName: new UntypedFormControl(null, Validators.maxLength(500)),
                            title: new UntypedFormControl(null, Validators.maxLength(150)),
                            upperDescription: new UntypedFormControl(null, Validators.cdnImage),
                            upperDescriptionAnnotation: new UntypedFormControl(null, Validators.cdnImage),
                            upperDescriptionInfoText: new UntypedFormControl(null, Validators.cdnImage),
                            url: new UntypedFormControl(null, [Validators.maxLength(300), Validators.url('relativeOrAbsolute')]),
                            urlRedirectAllowed: new UntypedFormControl({ value: null, disabled: true }),
                            keywords: new UntypedFormControl(null, Validators.maxLength(2000)),
                            name: new UntypedFormControl(null, Validators.maxLength(50)),
                            navigationText: new UntypedFormControl(null, [Validators.maxLength(4000), Validators.cdnImage]),
                            redText: new UntypedFormControl(null, Validators.maxLength(50)),
                            tooltip: new UntypedFormControl(null, Validators.maxLength(500)),
                            bottomDescription: new UntypedFormControl(null, Validators.cdnImage),
                            bottomDescriptionAnnotation: new UntypedFormControl(null),
                            commodityBottomText: new UntypedFormControl(null, [Validators.maxLength(2000), Validators.cdnImage]),
                            commodityFaq: new UntypedFormControl(null, Validators.cdnImage),
                            commodityUpperText: new UntypedFormControl(null, [Validators.maxLength(2000), Validators.cdnImage]),
                            descriptionTag: new UntypedFormControl(null, Validators.maxLength(2000)),
                            upperDescriptionLastUpdate: new UntypedFormControl(null),
                            bottomDescriptionLastUpdate: new UntypedFormControl(null),
                            productInfoLastUpdate: new UntypedFormControl(null)
                        });
                        if (languageId === Languages.Czech) {
                            group.get('name').setValidators([Validators.required, Validators.maxLength(50)]);
                            group.get('seoName').setValidators([Validators.required, Validators.maxLength(500)]);
                            group.get('url').setValidators([Validators.required, Validators.maxLength(300), Validators.url('relativeOrAbsolute')]);
                        }
                        return group;
                    })
                })
            );

            this._formTextKeys.forEach((key) =>
                (this.form.get('texts.sendTextsToTranslate.fieldsToSend') as UntypedFormGroup).addControl(key, new UntypedFormControl(false))
            );
            this.lockReloadSubscriptions(
                'texts',
                this.form.get('texts'),
                () => {
                    if (this.id !== 0) {
                        this.dataService.categoryTexts(this.id).subscribe(
                            (data) => {
                                Object.keys(data.localization).forEach((locKey) => {
                                    data.localization[locKey].wasFilledAtLoad = this.isFilledText(data.localization[locKey]);
                                });
                                this.form.get('texts').patchValue(data);
                                this._originalTextsValue = data;
                            },
                            (err: HttpErrorResponse) => {
                                this.dialogs.badRequestMessage(err);
                                this.router.navigate(this._fallbackRoute).catch(EMPTY);
                            }
                        );
                    } else {
                        this._originalTextsValue = this.form.get('texts').value;
                    }
                },
                () => {
                    this.formReloadSubscriptions(
                        this.form.get('texts.sendTextsToTranslate.fieldsToSend').valueChanges.subscribe(() => {
                            const val = this.form.get('texts.sendTextsToTranslate.fieldsToSend').value as [boolean];
                            if (Object.keys(val).some((key) => !!val[key])) {
                                if (this.form.get('texts.sendTextsToTranslate.languageIds').disabled) {
                                    this.form.get('texts.sendTextsToTranslate.languageIds').enable();
                                }
                            } else {
                                if (this.form.get('texts.sendTextsToTranslate.languageIds').value.length > 0) {
                                    this.form.get('texts.sendTextsToTranslate.languageIds').patchValue([]);
                                }
                                if (this.form.get('texts.sendTextsToTranslate.languageIds').enabled) {
                                    this.form.get('texts.sendTextsToTranslate.languageIds').disable();
                                }
                            }
                        }),
                        this.form.get('texts.localization').valueChanges.subscribe((value) => {
                            const defaultText = this.urlToCompare(value[defaultCountryTranslation]['url']);
                            Object.keys(value).forEach((language) => {
                                if (language === String(defaultCountryTranslation)) {
                                    return;
                                }
                                if (this.isFilledText(value[language]) !== value[language].wasFilledAtLoad) {
                                    if (!this._textVisibilityChangeObservable[language]) {
                                        this.formReloadSubscriptions(
                                            (this._textVisibilityChangeObservable[language] = this.dataService
                                                .categoryCountryVisibilityChange(this.id, Number(language), this.isFilledText(value[language]))
                                                .subscribe((res) => {
                                                    this._textVisibilityChangeEnum[language] = res;
                                                }))
                                        );
                                    }
                                }
                                const redirectControl = this.form.get(`texts.localization.${language}.urlRedirectAllowed`);
                                const valueToCheck = this.urlToCompare(value[language]['url']);
                                if (valueToCheck && valueToCheck !== defaultText) {
                                    if (redirectControl.disabled) {
                                        redirectControl.enable({ onlySelf: true, emitEvent: false });
                                    }
                                } else {
                                    if (redirectControl.enabled) {
                                        redirectControl.disable({ onlySelf: true, emitEvent: false });
                                    }
                                }
                            });
                        })
                    );
                    this.textsStateSetup();
                    this.requredNameUrlBasedOnLanguageHandler();
                    this.formReloadSubscriptions(
                        this.getMain().valueChanges.subscribe(this.textsStateSetup.bind(this)),
                        this.getMain().valueChanges.subscribe(this.requredNameUrlBasedOnLanguageHandler.bind(this)),
                        this.form.get('texts').valueChanges.subscribe(this.requredNameUrlBasedOnLanguageHandler.bind(this))
                    );
                }
            );
        }
        return this.form.get('texts') as UntypedFormGroup;
    }

    public getCountryVisibilityChangedMessage(languageId: number) {
        return this.getCountryVisibilityToMessage(this.getCountryVisibilityChangedValue(languageId));
    }

    public getCountryVisibilityChangedValue(languageId: number): CategoryCountryVisibilityChange {
        if (!this.form.get(`texts.localization.${languageId}`)) {
            return 0 as CategoryCountryVisibilityChange;
        }
        const selectedLangValue = this.form.get(`texts.localization.${languageId}`).value;
        if (!this._textVisibilityChangeEnum[languageId] || this.isFilledText(selectedLangValue) === selectedLangValue.wasFilledAtLoad) {
            return 0 as CategoryCountryVisibilityChange;
        }
        return this._textVisibilityChangeEnum[languageId];
    }

    private getCountryVisibilityToMessage(value: CategoryCountryVisibilityChange) {
        let txt = '';
        if (!value) {
            return '';
        }
        if (value & CategoryCountryVisibilityChange.ToVisible) {
            if (value & CategoryCountryVisibilityChange.NotPossible) {
                txt = this.translate.instant('Category_FilledTextWontEnableCountry');
            } else {
                txt = this.translate.instant('Category_FilledTextWillEnableCountry');
            }
        }
        if (value & CategoryCountryVisibilityChange.ToInvisible) {
            txt = this.translate.instant('Category_ClearedTextWillDisableCountry');
        }
        if (value & CategoryCountryVisibilityChange.ChildCategories) {
            txt = `${txt}. ${this.translate.instant('Category_TextChangeWillPropagateToChildCategories')}`;
        }
        return txt;
    }

    /**
     * So, value of valuechanges doesn't contain disabled values, which we need, so we need to use getRawValue, which is filled afterwars
     * this is why we need to skip a beat with the timeout.
     * We also change validators and need to retrigger validation based on current state of the form and we need to updatevalueandvalidity
     * so that the rest of the form knows about the in/valid state.
     * This also retriggers our valuechanges subscription, so we are tracking if we are called "from the outside" with the helper property.
     *
     * All of this because Angular cannot update validity without updating value, or retrigerring value changes.
     * */
    private requredNameUrlBasedOnLanguageHandler() {
        if (this._requiredNameUrlBasedOnLanguageInProgress) {
            return;
        }
        this._requiredNameUrlBasedOnLanguageInProgress = true;
        setTimeout(() => {
            this.requiredNameUrlBasedOnLanguage((this.form.get(`texts.localization`) as UntypedFormGroup).getRawValue());
            this._requiredNameUrlBasedOnLanguageInProgress = false;
        }, 0);
    }

    private urlToCompare(url: string) {
        if (!url) {
            return null;
        }
        const matches = url.match(/(.*\/)?(.+)\./);
        return matches && matches[2] ? matches[2] : null;
    }

    private textsStateSetup() {
        if ((this.getMain().get('isExternal').value && this.getMain().get('isSubstitute').value) || this.getMain().get('redirectToOriginal').value) {
            // disable all inputs, except name and navtext
            this._formTextKeys
                .filter((k) => k !== 'name' && k !== 'navigationText')
                .concat(this._contentAgeFields)
                .forEach((textKey) => {
                    for (const country of Object.keys(this.localizationTextsAsFormArray.controls)) {
                        this.form.get(`texts.localization.${country}.${textKey}`).disable();
                    }
                });
            this.formHelper.disableAll(this.form.get('texts.sendTextsToTranslate.fieldsToSend'), { emitEvent: false });
            this.form.get('texts.sendTextsToTranslate.fieldsToSend.name').enable();
            this.form.get('texts.sendTextsToTranslate.fieldsToSend.navigationText').enable();
        } else {
            this._formTextKeys
                .filter((k) => k !== 'name' && k !== 'navigationText')
                .forEach((textKey) => {
                    for (const country of Object.keys(this.localizationTextsAsFormArray.controls)) {
                        if (textKey === 'tooltip') {
                            this.getMain().get('highlighted').value
                                ? this.form.get(`texts.localization.${country}.tooltip`).enable()
                                : this.form.get(`texts.localization.${country}.tooltip`).disable();
                        } else {
                            this.form.get(`texts.localization.${country}.${textKey}`).enable();
                        }
                    }
                });
            this.formHelper.enableAll(this.form.get('texts.sendTextsToTranslate.fieldsToSend'), { emitEvent: false });
            this.getMain().get('highlighted').value
                ? this.form.get('texts.sendTextsToTranslate.fieldsToSend.tooltip').enable()
                : this.form.get('texts.sendTextsToTranslate.fieldsToSend.tooltip').disable();
        }
    }

    private requiredNameUrlBasedOnLanguage(texts: Array<ICategoryTextsDto>) {
        {
            const requiredLanguages: Array<string> = [];
            Object.keys(this.localizationTextsAsFormArray.controls).forEach((country) => {
                this._formTextKeys.forEach((textKey) => {
                    if (texts[country][textKey] && !requiredLanguages.some((x) => x === country)) {
                        requiredLanguages.push(country);
                    }
                });

                // we don't want to use this condition if external and substitute are set WAD-2837
                if (requiredLanguages.some((rl) => rl === country) && (!this.getMain().get('isExternal').value || !this.getMain().get('isSubstitute').value)) {
                    this.form.get(`texts.localization.${country}.seoName`).setValidators([Validators.required, Validators.maxLength(500)]);
                    this.form.get(`texts.localization.${country}.name`).setValidators([Validators.required, Validators.maxLength(50)]);
                    this.form
                        .get(`texts.localization.${country}.url`)
                        .setValidators([Validators.required, Validators.maxLength(300), Validators.url('relativeOrAbsolute')]);
                } else {
                    if (country !== String(Languages.Czech)) {
                        this.form.get(`texts.localization.${country}.name`).setValidators([Validators.maxLength(50)]);
                        this.form.get(`texts.localization.${country}.seoName`).setValidators([Validators.maxLength(500)]);
                        this.form.get(`texts.localization.${country}.url`).setValidators([Validators.maxLength(300), Validators.url('relativeOrAbsolute')]);
                    }
                }
                this.form.get(`texts.localization.${country}.name`).updateValueAndValidity();
                this.form.get(`texts.localization.${country}.seoName`).updateValueAndValidity();
                this.form.get(`texts.localization.${country}.url`).updateValueAndValidity();
            });
            this.formHelper.updateAllValuesAndValidities(this.form.get('texts.localization'));
        }
    }

    getCountries() {
        if (!this.form.get('countries')) {
            this.form.addControl('countries', this.fb.array([]));
            this.lockReloadSubscriptions('countries', this.form.get('countries'), () => {
                if (this.id !== 0) {
                    this.dataService
                        .categoryCountries(this.id)
                        .pipe(
                            tap((detail: Array<ICategoryCountryExtendedDto>) => {
                                const countryList = <UntypedFormArray>this.form.get('countries');
                                for (const res of detail) {
                                    countryList.push(this.createAndFillCountry(res));
                                }
                                this.countriesLoaded.next(true);
                            })
                        )
                        .subscribe(EMPTY, (err: HttpErrorResponse) => {
                            this.dialogs.badRequestMessage(err);
                            this.router.navigate(this._fallbackRoute).catch(EMPTY);
                        });
                }
            });
        }
        return this.form.get('countries') as UntypedFormArray;
    }

    public createAndFillCountry(country: ICategoryCountryExtendedDto) {
        const group = this.fb.group({
            countryName: country.countryName,
            countryId: country.countryId,
            specialUrlAction: country.specialUrlAction,
            specialUrl: new UntypedFormControl(country.specialUrl, [Validators.maxLength(300), Validators.url('absolute')]),
            index: new UntypedFormControl(country.index, [Validators.int()]),
            topProducerCount: country.topProducerCount,
            visible: [
                {
                    value: country.visible,
                    disabled: true
                }
            ]
        });
        this.lockReloadSubscriptions('countries', group);
        return group;
    }

    public getTopCountryProducers() {
        if (!this.form.get('topCountryProducers')) {
            const topCountryProducersForm = this.fb.array([]);
            this.form.addControl('topCountryProducers', topCountryProducersForm);

            if (this.id !== 0) {
                this.dataService
                    .categoryActiveCountries(this.id)
                    .pipe(
                        tap((topProducers: Array<IIdNameDto>) => {
                            topProducers.forEach((tp) => topCountryProducersForm.push(this.createAndFillTopCountryProducerControl(tp)));
                            this.setTopProducers(topCountryProducersForm);
                        })
                    )
                    .subscribe(EMPTY, (err: HttpErrorResponse) => {
                        this.dialogs.badRequestMessage(err);
                        this.router.navigate(this._fallbackRoute).catch(EMPTY);
                    });
            }
        }
        return this.form.get('topCountryProducers') as UntypedFormArray;
    }

    private setTopProducers(topCountryProducers: UntypedFormArray) {
        this.lockReloadSubscriptions(
            'topProducers',
            topCountryProducers,
            () => {
                if (this.id !== 0) {
                    this.dataService
                        .categoryTopProducers(this.id)
                        .pipe(
                            tap((topProducers: Array<ICategoryTopProducerExtendedDto>) => {
                                topProducers.forEach((tp) => {
                                    (topCountryProducers.controls.find((c) => c.value.countryId === tp.countryId).get('topProducers') as UntypedFormArray).push(
                                        this.createAndFillTopProducerControl(tp)
                                    );
                                });
                                this.topCountryProducers.next(true);
                            })
                        )
                        .subscribe(EMPTY, (err: HttpErrorResponse) => {
                            this.dialogs.badRequestMessage(err);
                            this.router.navigate(this._fallbackRoute).catch(EMPTY);
                        });
                }
            },
            () => {
                topCountryProducers.controls.forEach((tp) => {
                    this.formReloadSubscriptions(tp.valueChanges.subscribe(() => this.reindexTopProducers(tp as UntypedFormGroup)));
                });
            }
        );
    }

    public createAndFillTopCountryProducerControl(topProducer: IIdNameDto) {
        const control = this.fb.group({
            countryId: topProducer.id,
            name: topProducer.name,
            topProducers: this.fb.array([])
        });
        return control;
    }

    public createAndFillTopProducerControl(topProducer: ICategoryTopProducerExtendedDto) {
        const control = this.fb.group({
            producerId: topProducer.producerId,
            index: topProducer.index,
            name: topProducer.name,
            countryId: topProducer.countryId,
            source: topProducer.source
        });
        this.lockReloadSubscriptions('topProducers', control);
        return control;
    }

    private reindexTopProducers(res: UntypedFormGroup) {
        let idx = 0;
        (res.get('topProducers') as UntypedFormArray).controls
            .filter((x) => x.get('source').value == CategoryTopProducerSource.Manual)
            .forEach((item) => {
                idx++;
                const index = item.get('index');
                // endless cycle prevention
                if (index.value !== idx) {
                    item.get('index').patchValue(idx);
                }
            });
    }

    public getTags() {
        if (!this.form.get('tags')) {
            const tagsForm = this.fb.array([]);
            this.form.addControl('tags', tagsForm);
            this.formReloadSubscriptions(tagsForm.valueChanges.subscribe(() => this.reindexTags()));
            if (this.id !== 0) {
                this.dataService
                    .categoryTags(this.id)
                    .pipe(
                        tap((tags: Array<ITagRelationExtendedDto>) => {
                            tags.forEach((tag) => {
                                tag.$state = 'modified';
                                tagsForm.push(this.createAndFillTagControl(tag));
                            });
                        })
                    )
                    .subscribe(EMPTY, (err: HttpErrorResponse) => {
                        this.dialogs.badRequestMessage(err);
                        this.router.navigate(this._fallbackRoute).catch(EMPTY);
                    });
            }
            this.lockReloadSubscriptions('tags', tagsForm);
        }
        return this.form.get('tags') as UntypedFormArray;
    }

    public createAndFillTagControl(tag: ITagRelationExtendedDto) {
        const group = this.fb.group({
            id: tag.id,
            tagId: tag.tagId,
            index: tag.index,
            style: tag.style,
            tagText: tag.tagText,
            $state: tag.$state
        });
        this.lockReloadSubscriptions('tags', group);
        return group;
    }

    private reindexTags() {
        let idx = 0;
        this.getTags().controls.forEach((item) => {
            if (item.get('$state').value === 'deleted') {
                return;
            }
            idx++;
            const index = item.get('index');
            if (index.value !== idx) {
                // endless cycle prevention
                item.get('index').patchValue(idx);
            }
        });
    }

    public getImages() {
        if (!this.form.get('images')) {
            const group = this.fb.group({
                canDeleteImageBig: true,
                imageSmall: [
                    null,
                    [
                        Validators.file({ allowedExtensions: ['.jpg', '.png', '.jpeg', '.svg'] }),
                        Validators.image({ aspectRatio: [1, 1], minResolution: [40, 40], maxResolution: [120, 120] })
                    ]
                ],
                imageBig: [null, [Validators.file({ allowedExtensions: ['.jpg', '.png', '.jpeg'] }), Validators.image({ aspectRatio: [1, 1] })]],
                categoryIcon: [
                    {
                        value: null,
                        disabled: true
                    },
                    [Validators.file({ maxSize: 51200, allowedExtensions: ['.jpg', '.png', '.jpeg'] }), Validators.image({ aspectRatio: [1, 1] })]
                ]
            });
            this.form.addControl('images', group);
            this.setImageValidators(group.value, this.getMain().getRawValue());
            this.lockReloadSubscriptions(
                'images',
                group,
                () => {
                    if (this.id !== 0) {
                        this.dataService
                            .categoryImages(this.id)
                            .pipe(
                                tap((detail) => {
                                    this.form.get('images').patchValue(detail);
                                })
                            )
                            .subscribe(EMPTY, (err: HttpErrorResponse) => {
                                this.dialogs.badRequestMessage(err);
                                this.router.navigate(this._fallbackRoute).catch(EMPTY);
                            });
                    }
                },
                () => {
                    this.formReloadSubscriptions(
                        group.valueChanges.subscribe((img: ICategoryImagesDto) => {
                            this.setImageValidators(img, this.getMain().getRawValue());
                        }),
                        this.getMain().valueChanges.subscribe((value: ICategoryMainExtendedWithLockDto) => {
                            this.setImageValidators(group.getRawValue(), value);
                        })
                    );
                }
            );
        }
        return this.form.get('images') as UntypedFormGroup;
    }

    private setImageValidators(image: ICategoryImagesDto, categoryExtended: ICategoryMainExtendedWithLockDto) {
        if (categoryExtended.highlighted && this.getImages().get('categoryIcon').disabled) {
            this.getImages().get('categoryIcon').enable();
        }
        if (!categoryExtended.highlighted && this.getImages().get('categoryIcon').enabled) {
            this.getImages().get('categoryIcon').disable();
        }

        const validators = [];
        if (image.canDeleteImageBig) {
            validators.push(Validators.required);
        }
        if (categoryExtended.level <= 3) {
            validators.push(Validators.image({ aspectRatio: [1, 1], minResolution: [500, 500] }));
        } else {
            validators.push(Validators.image({ aspectRatio: [1, 1], minResolution: [240, 240] }));
        }
        const control = this.getImages().get('imageBig');
        control.setValidators(validators);
        control.updateValueAndValidity({ onlySelf: true });
    }

    public tabInvalid(tab: string) {
        if (tab === 'top-producers') {
            tab = 'topCountryProducers';
        }
        if (tab === 'producers') {
            tab = 'producerTexts';
        }
        if (!this.form.get(tab)) {
            return false;
        }
        return this.form.get(tab).invalid;
    }

    public deleteProducer(producerId: number) {
        if (this.getProducerText(producerId).get('$state').value === 'added') {
            const producers = this.getProducersArray();
            const producerTabs = this.getProducers();
            const producerInArray = producers.controls.findIndex((c) => c.value['producerId'] === producerId);
            const producerInTab = producerTabs.controls.findIndex((c) => c.value['id'] === producerId);
            if (producerInArray > -1) {
                producers.removeAt(producerInArray);
            }
            if (producerInTab > -1) {
                this.producersToAdd.push({
                    id: producerTabs.controls[producerInTab].get('id').value,
                    text: producerTabs.controls[producerInTab].get('name').value
                });
                producerTabs.removeAt(producerInTab);
            }
            this.modifiedProducers.push(producerId);
        } else {
            this.getProducerText(producerId).get('$state').setValue('deleted');
            this.modifiedProducers.push(producerId);
        }
    }

    public restoreProducer(producerId: number) {
        this.modifiedProducers.splice(this.modifiedProducers.indexOf(producerId), 1);
        this.getProducerText(producerId).get('$state').setValue('modified');
    }

    public clearProducers() {
        this.producersLoaded.next(false);
        const producers = this.getProducersArray();
        for (const producer of this.modifiedProducers) {
            const producerInArray = producers.controls.findIndex((c) => c.value['producerId'] === producer);
            if (producerInArray > -1) {
                producers.removeAt(producerInArray);
            }
        }
        this.modifiedProducers = [];
    }

    public producerInvalid(producerId: number) {
        if (!this.form.get('producerTexts')) {
            return false;
        }
        const group = (this.form.get('producerTexts') as UntypedFormArray).controls.find((c) => c.value.producerId === producerId) as UntypedFormGroup;
        return group ? group.invalid : false;
    }

    getProducerState(producerId: number) {
        if (!producerId || !this.form.get('producerTexts')) {
            return null;
        }
        const group = (this.form.get('producerTexts') as UntypedFormArray).controls.find((c) => c.value.producerId === producerId) as UntypedFormGroup;
        return group ? group.get('$state').value : null;
    }

    setProducerState(producerId: number) {
        this.modifiedProducers.push(producerId);
    }

    getProducersArray() {
        if (!this.form.get('producerTexts')) {
            this.form.addControl('producerTexts', new UntypedFormArray([]));
        }
        return this.form.get('producerTexts') as UntypedFormArray;
    }

    getProducerText(producerId: number) {
        const producers = this.getProducersArray();
        let group = producers.controls.find((c) => c.value.producerId === producerId) as UntypedFormGroup;
        if (!group) {
            producers.push(
                new UntypedFormGroup({
                    producerId: new UntypedFormControl(producerId)
                })
            );
            group = producers.controls.find((c) => c.value.producerId === producerId) as UntypedFormGroup;
            group.addControl('canonicalCategoryId', new UntypedFormControl(null));
            group.addControl('id', new UntypedFormControl(0));
            group.addControl('producerId', new UntypedFormControl(null));
            group.addControl('responsiveAnnotation', new UntypedFormControl(false));
            group.addControl(
                'sendTextsToTranslate',
                new UntypedFormGroup({
                    languageIds: new UntypedFormControl({ value: [], disabled: true }),
                    fieldsToSend: new UntypedFormGroup({
                        bottomDescription: new UntypedFormControl(false, Validators.cdnImage),
                        bottomDescriptionAnnotation: new UntypedFormControl(false, Validators.cdnImage),
                        commodityBottomText: new UntypedFormControl(false, Validators.cdnImage),
                        commodityFaq: new UntypedFormControl(false, Validators.cdnImage),
                        commodityUpperText: new UntypedFormControl(false, Validators.cdnImage),
                        descriptionTag: new UntypedFormControl(false),
                        name: new UntypedFormControl(false),
                        seoName: new UntypedFormControl(false),
                        title: new UntypedFormControl(false),
                        upperDescription: new UntypedFormControl(false, Validators.cdnImage),
                        upperDescriptionAnnotation: new UntypedFormControl(false, Validators.cdnImage)
                    })
                })
            );
            group.addControl(
                'localization',
                this.formHelper.localizationGroup(() => {
                    const localizationGroup = this.fb.group({
                        bottomDescription: new UntypedFormControl(null, Validators.cdnImage),
                        bottomDescriptionAnnotation: new UntypedFormControl(null, Validators.cdnImage),
                        canonical: new UntypedFormControl(null, Validators.maxLength(200)),
                        commodityBottomText: new UntypedFormControl(null, [Validators.maxLength(2000), Validators.cdnImage]),
                        commodityFaq: new UntypedFormControl(null, Validators.cdnImage),
                        commodityUpperText: new UntypedFormControl(null, [Validators.maxLength(2000), Validators.cdnImage]),
                        descriptionTag: new UntypedFormControl(null, Validators.maxLength(2000)),
                        name: new UntypedFormControl(null, Validators.maxLength(100)),
                        seoName: new UntypedFormControl(null, Validators.maxLength(500)),
                        title: new UntypedFormControl(null, Validators.maxLength(150)),
                        upperDescription: new UntypedFormControl(null, Validators.cdnImage),
                        upperDescriptionAnnotation: new UntypedFormControl(null, Validators.cdnImage)
                    });
                    localizationGroup.get(`canonical`).disable();
                    return localizationGroup;
                })
            );
            if (this.modifiedProducers.some((x) => x === producerId)) {
                group.addControl('$state', new UntypedFormControl('added'));
            }
            if (!group.get('$state')?.value) {
                group.addControl('$state', new UntypedFormControl('unchanged'));
            }

            this.formReloadSubscriptions(
                group
                    .get('canonicalCategoryId')
                    .valueChanges.pipe(debounceTime(300))
                    .subscribe((categoryId) => {
                        if (categoryId === '' || categoryId === '0' || categoryId === 0) {
                            group.get('canonicalCategoryId').setValue(null);
                            categoryId = null;
                        }
                        if (categoryId === null) {
                            this.clearCanonical(producerId);
                            return;
                        }
                        if (Number(categoryId) !== parseInt(categoryId, 10)) {
                            return;
                        }

                        if (Number(categoryId) !== this.producerCanonicalId) {
                            this.dataService
                                .categoryUrl(categoryId)
                                .pipe(
                                    map((data) => {
                                        this.clearCanonical(producerId);
                                        Object.entries(data).forEach((el) => {
                                            group.get(`localization.${el[0]}.canonical`).patchValue(el[1]);
                                        });
                                    })
                                )
                                .subscribe();
                        } else {
                            this.producerCanonicalId = null;
                        }
                    })
            );
            this.formReloadSubscriptions(
                this.objectLock$.subscribe((objectLock) => {
                    if (objectLock === undefined) {
                        return;
                    }
                    if (this.isLockLocked(objectLock)) {
                        this.formHelper.disableAll(group, { emitEvent: false });
                        return;
                    }
                    this.formReloadSubscriptions(
                        group.get('sendTextsToTranslate.fieldsToSend').valueChanges.subscribe(() => {
                            const val = group.get('sendTextsToTranslate.fieldsToSend').value;
                            if (
                                val['bottomDescription'] ||
                                val['bottomDescriptionAnnotation'] ||
                                val['canonical'] ||
                                val['commodityBottomText'] ||
                                val['commodityFaq'] ||
                                val['commodityUpperText'] ||
                                val['descriptionTag'] ||
                                val['name'] ||
                                val['seoName'] ||
                                val['title'] ||
                                val['upperDescription'] ||
                                val['upperDescriptionAnnotation']
                            ) {
                                if (group.get('sendTextsToTranslate.languageIds').disabled) {
                                    group.get('sendTextsToTranslate.languageIds').enable();
                                }
                            } else {
                                if (group.get('sendTextsToTranslate.languageIds').enabled) {
                                    group.get('sendTextsToTranslate.languageIds').disable();
                                }
                                if (group.get('sendTextsToTranslate.languageIds').value.length > 0) {
                                    group.get('sendTextsToTranslate.languageIds').patchValue([]);
                                }
                            }
                        })
                    );
                })
            );
            group.get('sendTextsToTranslate.languageIds').disable();
            if (group.get('$state').value === 'unchanged') {
                this.dataService
                    .categoryProducerTexts(this.id, producerId)
                    .pipe(
                        tap((data) => {
                            if (data) {
                                this.producerCanonicalId = data.canonicalCategoryId;
                                group.patchValue(data);
                                group.get('$state').setValue('modified');
                            }
                        })
                    )
                    .subscribe();
            }
        }
        return group;
    }

    getProducers() {
        let producersControls = this.form.get('producersGrid') as UntypedFormArray;
        if (!producersControls) {
            producersControls = new UntypedFormArray([]);
            this.form.addControl('producersGrid', producersControls);
            this.lockReloadSubscriptions('producerTexts', producersControls, () => {
                if (this.id !== 0) {
                    this.producers = [];
                    this.dataService.categoryProducers(this.id).subscribe(
                        (producers) => {
                            producers.forEach((producer) => {
                                if (producer.hasTexts) {
                                    const group = this.fb.group({
                                        id: producer.id,
                                        hasTexts: producer.hasTexts,
                                        name: producer.name,
                                        $state: 'added'
                                    });
                                    this.lockReloadSubscriptions('producerTexts', group);
                                    producersControls.push(group);
                                }
                                this.producers.push({
                                    text: producer.name,
                                    id: producer.id
                                });
                            });
                            this.producersToAdd = [...this.producers];
                            this.producersLoaded.next(true);
                        },
                        (err: HttpErrorResponse) => {
                            this.dialogs.badRequestMessage(err);
                            this.router.navigate(this._fallbackRoute).catch(EMPTY);
                        }
                    );
                } else {
                    this.producersLoaded.next(true);
                }
            });
        }
        return producersControls;
    }

    clearCanonical(producerId: number) {
        const langKeys = Object.keys((this.getProducerText(producerId).get('localization') as UntypedFormGroup).controls);
        langKeys.forEach((lang) => {
            this.getProducerText(producerId).get(`localization.${lang}.canonical`).patchValue(null);
        });
    }

    private topProducersToData() {
        if (!this.form.get('topCountryProducers')) {
            return null;
        }
        const topProducers = [];

        (this.form.get('topCountryProducers') as UntypedFormArray).controls.forEach((countryProducer) => {
            const producers = (countryProducer.get('topProducers') as UntypedFormArray).getRawValue().map((extended: ICategoryTopProducerExtendedDto) => {
                const topProducer: ICategoryTopProducerDto = {
                    index: extended.index,
                    producerId: extended.producerId,
                    countryId: extended.countryId,
                    source: extended.source
                };
                return topProducer;
            });
            topProducers.push(...producers);
        });

        return { topProducers: topProducers };
    }

    private tagsData() {
        if (!this.form.get('tags') || (this.form.get('tags') as UntypedFormArray).controls.length === 0) {
            return null;
        }
        const tagValues = (this.form.get('tags') as UntypedFormArray).getRawValue();
        const tags: Array<ITagRelationDto> = [];
        tagValues.forEach((tagValue) => {
            const tag = { ...tagValue };
            tags.push(tag);
        });

        return { tags: tags };
    }

    private countryData() {
        if (!this.form.get('countries') || (this.form.get('countries') as UntypedFormArray).controls.length === 0) {
            return null;
        }
        const countryValues = (this.form.get('countries') as UntypedFormArray).getRawValue();
        const countries: Array<ICategoryCountryExtendedDto> = [];
        countryValues.forEach((countryValue) => {
            const country = { ...countryValue };
            countries.push(country);
        });

        return { countries: countries };
    }

    private textsToData() {
        const returner = <any>{ id: this.id };
        if (this.form.get('texts')) {
            returner.texts = (this.form.get('texts') as UntypedFormGroup).getRawValue();
            returner.sendTextsToTranslate = returner.texts.sendTextsToTranslate;
            delete returner.texts.sendTextsToTranslate;
        }
        return returner as ICategoryCompositeClientDto;
    }

    private producersToData() {
        if (!this.form.get('producerTexts') || (this.form.get('producerTexts') as UntypedFormArray).controls.length === 0) {
            return null;
        }
        const producersValues = (this.form.get('producerTexts') as UntypedFormArray).getRawValue();
        const producers: Array<any> = [];
        producersValues.forEach((producerValue) => {
            const simpleValue = { ...producerValue };
            delete simpleValue.sendTextsToTranslate;
            const producer = {
                producerId: producerValue.producerId,
                texts: simpleValue,
                sendTextsToTranslate: producerValue.sendTextsToTranslate,
                $state: producerValue.$state
            };
            if (producerValue.$state === 'deleted') {
                producer.sendTextsToTranslate = null;
            }
            producers.push(producer);
        });

        return { producerTexts: producers };
    }

    private mainToData() {
        const returner = <any>{ id: this.id };
        if (this.form.get('main')) {
            returner.main = (this.form.get('main') as UntypedFormGroup).getRawValue();
        }
        return returner as ICategoryMainDto;
    }

    private imagesToData() {
        const returner = <ICategoryCompositeClientDto>{ id: this.id };
        if (this.form.get('images')) {
            returner.images = (this.form.get('images') as UntypedFormGroup).getRawValue();
        }
        return returner;
    }

    private parametersToData() {
        if (!this.form.get('parameters')) {
            return null;
        }
        const parameterValues = (this.form.get('parameters') as UntypedFormGroup).getRawValue() as ICategoryParametersDto;
        if (!parameterValues.id) {
            if (!parameterValues.sourceCategoryId) {
                return null;
            } else {
                parameterValues.$state = 'added';
            }
        } else {
            parameterValues.$state = parameterValues.sourceCategoryId ? 'modified' : 'deleted';
        }
        parameterValues.sourceCategoryId = parameterValues.sourceCategoryId || 0;
        for (const el of parameterValues.parameterFilters) {
            if (el.$state === 'added') {
                el.id = 0;
            }
        }
        return { parameters: parameterValues };
    }

    private parameterInfoToData() {
        const returner = <ICategoryCompositeClientDto>{ id: this.id };
        if (this.form.get('parameterInfo')) {
            returner.parameterInfo = this.form.get('parameterInfo').value;
        }
        return returner;
    }

    private formToData() {
        return {
            id: this.id,
            ...this.mainToData(),
            ...this.topProducersToData(),
            ...this.tagsData(),
            ...this.imagesToData(),
            ...this.countryData(),
            ...this.textsToData(),
            ...this.producersToData(),
            ...this.parametersToData(),
            ...this.parameterInfoToData()
        } as ICategoryCompositeClientDto;
    }

    public unlock() {
        this.objectLock$.pipe(first()).subscribe((objectLock) => {
            if (objectLock && objectLock.userId === this.session.user.id) {
                this.objectLockService.unlockObject(objectLock.objectType, objectLock.objectId, objectLock.additionalId).subscribe();
            }
        });
    }

    private isFilledText(data: ICategoryTextsLocalizationDto) {
        return !!(data.seoName && data.url && data.name);
    }

    public formSave(reload: boolean): Observable<any> {
        if (!this.validate()) {
            return throwError(null);
        }

        const upperDescLanguageUpdates: Array<number> = [];
        const bottomDescLanguageUpdates: Array<number> = [];

        const texts = this.form.get('texts');

        if (texts) {
            const currentTextValue = texts.value;

            this.session.user.availableLanguages.forEach((l) => {
                if (
                    (this._originalTextsValue.localization[l.id] &&
                        this._originalTextsValue.localization[l.id].bottomDescription !== currentTextValue.localization[l.id].bottomDescription) ||
                    (!this._originalTextsValue.localization[l.id] &&
                        currentTextValue.localization[l.id] &&
                        currentTextValue.localization[l.id].bottomDescription)
                ) {
                    bottomDescLanguageUpdates.push(l.id);
                }
                if (
                    (this._originalTextsValue.localization[l.id] &&
                        this._originalTextsValue.localization[l.id].upperDescription !== currentTextValue.localization[l.id].upperDescription) ||
                    (!this._originalTextsValue.localization[l.id] &&
                        currentTextValue.localization[l.id] &&
                        currentTextValue.localization[l.id].upperDescription)
                ) {
                    upperDescLanguageUpdates.push(l.id);
                }
                if (
                    (this._originalTextsValue.localization[l.id] &&
                        this._originalTextsValue.localization[l.id].bottomDescriptionAnnotation !==
                            currentTextValue.localization[l.id].bottomDescriptionAnnotation) ||
                    (!this._originalTextsValue.localization[l.id] &&
                        currentTextValue.localization[l.id] &&
                        currentTextValue.localization[l.id].bottomDescriptionAnnotation)
                ) {
                    bottomDescLanguageUpdates.push(l.id);
                }
                if (
                    (this._originalTextsValue.localization[l.id] &&
                        this._originalTextsValue.localization[l.id].upperDescriptionAnnotation !==
                            currentTextValue.localization[l.id].upperDescriptionAnnotation) ||
                    (!this._originalTextsValue.localization[l.id] &&
                        currentTextValue.localization[l.id] &&
                        currentTextValue.localization[l.id].upperDescriptionAnnotation)
                ) {
                    upperDescLanguageUpdates.push(l.id);
                }
            });
        }

        if (bottomDescLanguageUpdates.length > 0 || upperDescLanguageUpdates.length > 0) {
            return this.matDialogs
                .open<CategoryDescriptionUpdateComponent>(CategoryDescriptionUpdateComponent, {
                    panelClass: 'dialog-lg'
                })
                .afterClosed()
                .pipe(
                    switchMap((res) => {
                        if (res !== undefined) {
                            if (res) {
                                bottomDescLanguageUpdates.forEach((lang) => {
                                    this.form.get(`texts.localization.${lang}.bottomDescriptionLastUpdate`).patchValue(new Date());
                                });
                                upperDescLanguageUpdates.forEach((lang) => {
                                    this.form.get(`texts.localization.${lang}.upperDescriptionLastUpdate`).patchValue(new Date());
                                });
                            }

                            const composite = this.formToData();
                            return this.sendData(composite, reload);
                        } else {
                            return throwError(null);
                        }
                    })
                );
        } else {
            const composite = this.formToData();
            return this.sendData(composite, reload);
        }
    }

    private sendData(composite: any, reload: boolean) {
        return this.dataService.categorySave(<ICategoryCompositeClientDto>composite).pipe(
            tap(() => {
                this.clearProducers();
                this.topCountryProducers.next(false);
                if (reload) {
                    this._objectLock.next(null);
                    this.init({ id: this.id });
                }
            }),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 400) {
                    this.dialogs.badRequestMessage(err);
                }
                return throwError(err);
            })
        );
    }
}
