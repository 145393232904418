<div *ngIf="form">
    <div *ngIf="isLocked">
        <div class="row">
            <div class="col-xs-12 col-md-10 col-lg-8 col-xl-6">
                <ul class="list-group" id="orderList">
                    <li
                        *ngFor="let item of form.controls; let ix = index; let odd = odd; let even = even"
                        class="list-group-item"
                        [class.odd-dnd]="odd"
                        [class.even-dnd]="even"
                    >
                        <div class="row">
                            <div class="col-xs-2">{{ item.get('index').value }}.</div>
                            <div class="col-xs-5">
                                {{ item.get('tagText').value }}
                            </div>
                            <div class="col-xs-5">
                                {{ item.get('style').value | enum : 'CodeBook+TagStyleType' }}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!isLocked">
        <div class="row">
            <cms-select
                [id]="'tagToAdd'"
                [labelText]="'Category_Tags_TagToAdd' | translate"
                [items]="tagList"
                bindValue="id"
                bindLabel="text"
                [virtualScroll]="true"
                [clearable]="true"
                class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
                [(ngModel)]="tagToAdd"
            ></cms-select>
            <div class="withoutHeading">
                <app-button type="button" (click)="addItem()" [id]="'addTag'" [color]="'primary'" [disabled]="!tagToAdd">
                    <i class="fas fa-fw fa-plus"></i> {{ 'Common_Add' | translate }}
                </app-button>
            </div>
        </div>
        <form class="form" [formGroup]="form">
            <div class="row">
                <div class="col-xs-12 col-md-10 col-lg-8">
                    <ul class="list-group" id="orderList" cdkDropList (cdkDropListDropped)="onDrop($event)">
                        <li
                            *ngFor="let item of form.controls; let ix = index; let odd = odd; let even = even"
                            class="list-group-item"
                            [class.odd-dnd]="odd"
                            [class.even-dnd]="even"
                            [class.deleted]="item.get('$state').value === 'deleted'"
                            [class.added]="item.get('$state').value === 'added'"
                            [attr.data-tid]="'row_' + ix"
                            cdkDrag
                        >
                            <div class="row">
                                <div class="col-xs-2 col-md-2 mt-2">
                                    <div class="dragHandle" cdkDragHandle><i class="fas fa-grip-vertical"></i></div>
                                    {{ item.get('index').value }}.
                                </div>
                                <div class="col-xs-4 col-md-5 mt-1">
                                    {{ item.get('tagText').value }}
                                </div>
                                <cms-select
                                    [id]="'style_' + ix"
                                    name="style"
                                    [formControl]="item.get('style')"
                                    [enum]="'CodeBook+TagStyleType'"
                                    class="col-xs-4 col-md-4"
                                >
                                </cms-select>
                                <div class="col-xs-2 col-md-1 pull-right mt-2">
                                    <span
                                        *ngIf="item.get('$state').value !== 'deleted'"
                                        (click)="delete(form, ix)"
                                        class="remove-btn cursor-pointer"
                                        data-tid="btnRemoveTag"
                                    >
                                        <span class="fas fa-times"></span>
                                    </span>
                                    <span
                                        *ngIf="item.get('$state').value === 'deleted'"
                                        (click)="undelete(item)"
                                        class="restore-btn cursor-pointer"
                                        data-tid="btnRestoreTag"
                                    >
                                        <span class="fas fa-redo"></span>
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </form>
    </ng-container>
</div>
