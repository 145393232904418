<app-page-header
    *ngIf="!!(data && data.mainParameter)"
    [title]="('CompatibleParameterValues_CompatibleValuesForParameter' | translate) + ' ' + data.mainParameter.name + ' (' + data.mainParameter.id + ')'"
>
    <breadcrumb [text]="'Menu_Commodity' | translate"></breadcrumb>
    <breadcrumb [link]="['/CompatibleParameterValues']" [text]="'CompatibleParameterValues_CompatibleParameterValues' | translate"></breadcrumb>
    <breadcrumb
        [active]="true"
        [text]="('CompatibleParameterValues_CompatibleValuesForParameter' | translate) + ' ' + data.mainParameter.name + ' (' + data.mainParameter.id + ')'"
    ></breadcrumb>
</app-page-header>

<div class="wrapper-content animated fadeInRight">
    <div class="ibox">
        <div class="ibox-content" *ngIf="!!(data && data.mainParameter)">
            <h1>{{ 'CompatibleParameterValues_CompatibleValues' | translate }}</h1>
            <div class="row flexrow m-r-none">
                <div class="col-xs-6 col-sm-4 mainParameter">
                    <h2>{{ data.mainParameter.name }}</h2>
                    <div class="row">
                        <cms-input
                            class="col-xs-12 col-sm-6"
                            [id]="'parameterValueFilter'"
                            name="parameterValueFilter"
                            [labelText]="'Common_Search' | translate"
                            [placeholder]="'CompatibleParameterValues_FilterTooltip' | translate"
                            [(ngModel)]="parameterValueFilter"
                            (keyup)="filterParameterValues()"
                        ></cms-input>
                    </div>
                    <ul>
                        <li
                            *ngFor="let value of paginatedParameterValues"
                            [attr.data-tid]="'val_' + value.id"
                            [class.selected]="value == selected"
                            (click)="selectMainParameterValue(value)"
                        >
                            {{ value.id }}. {{ value.name }}
                        </li>
                    </ul>
                    <div *ngIf="totalPages > 1" style="margin: -20px 0">
                        <app-pagination
                            [pageSize]="pageSize"
                            [collectionSize]="filteredParameterValues.length"
                            [page]="currentPage"
                            (pageChange)="setPage($event)"
                        ></app-pagination>
                    </div>
                </div>
                <div *ngIf="!selected" class="col-xs-6 col-sm-8 relatedParameters">
                    <h3>{{ 'CompatibleParameterValues_SelectValueToPair' | translate }}</h3>
                </div>
                <div *ngIf="selected" class="col-xs-6 col-sm-8 relatedParameters">
                    <h3>{{ selected.id }}. {{ selected.name }}</h3>
                    <div *ngIf="parametersForSelected">
                        <h4 *ngIf="parametersForSelected.length > 0">Spárované parametry:</h4>
                        <div
                            class="relatedParameter"
                            [class.relatedHidden]="!relatedParameter.visible"
                            [attr.data-tid]="'rel_' + relatedParameter.id"
                            *ngFor="let relatedParameter of parametersForSelected"
                        >
                            <h3 (click)="relatedParameter.visible = !relatedParameter.visible">
                                {{ relatedParameter.id }}. {{ relatedParameter.name }} <i class="fas fa-fw fa-chevron-down"></i>
                            </h3>
                            <ul>
                                <li *ngFor="let relatedParameterValue of relatedParameter.values">
                                    <div class="checkbox">
                                        <input
                                            id="chckbx_{{ selected.id }}_{{ relatedParameterValue.id }}"
                                            [attr.data-tid]="'cb_' + relatedParameterValue.id"
                                            type="checkbox"
                                            [checked]="data.pairedValues[selected.id].indexOf(relatedParameterValue.id) !== -1"
                                            (change)="selectPairedValue($event, relatedParameterValue.id)"
                                        />
                                        <label for="chckbx_{{ selected.id }}_{{ relatedParameterValue.id }}"
                                            >{{ relatedParameterValue.id }}. {{ relatedParameterValue.name }}</label
                                        >
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-primary pull-right" data-tid="btnAddParameter" (click)="addPairableParameter()">
                            <i class="fas fa-fw fa-plus"></i>{{ 'CompatibleParameterValues_AddParameter' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-page-footer returnRoute="/CompatibleParameterValues">
    <storno-button></storno-button>
    <save-button (click)="save()"></save-button>
</app-page-footer>
