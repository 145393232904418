import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class CategoryProducerTranslationsDataService {
    private _apiRoute = '/api/category/';

    constructor(private http: HttpClient) {}

    public saveProducerTextTranslations(id: number, producerId: number, languageId: number, data: any) {
        return this.http.patch(this._apiRoute + `${id}/producer/${producerId}/texts-translation/${languageId}`, data);
    }

    public loadProducerTextTranslations(id: number, producerId: number, languageId: number) {
        return this.http.get<any>(this._apiRoute + `${id}/producer/${producerId}/texts-translation/${languageId}`);
    }

    public producerTextTranslationsAssignedCount() {
        return this.http.get<number>(this._apiRoute + 'producer-text-translations-assigned-count');
    }
}
