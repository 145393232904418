<app-space-background>
    <div *ngIf="step === 'setup'">
        <h1 class="py-4 text-center">{{ 'Login_Required2FaSetupPhoneNumber_Header' | translate }}</h1>
        <div class="alert alert-warning">
            <i class="fas fa-exclamation-triangle"></i>
            <b>
                {{ 'Login_Required2FaSetupPhoneNumber_Message' | translate }}
            </b>
            <br />
            {{ 'Login_Required2FaSetupPhoneNumber_MessageBody' | translate }}
        </div>

        <div class="form-group">
            <cms-input [id]="'phoneNumber'" [placeholder]="'Login_Required2FaSetupPhoneNumber_PhoneNumber' | translate" [formControl]="phoneNumber">
                <error-message [errorCode]="'pattern'" [message]="'Login_Required2FaSetupPhoneNumber_PhoneNumberFailed' | translate"></error-message>
            </cms-input>
        </div>
        <div class="form-group">
            <button type="button" class="btn btn-primary block full-width m-b" data-tid="btnSendConfirmationCode" (click)="setup()">
                {{ 'Common_Save' | translate }}
            </button>
        </div>
    </div>

    <div *ngIf="step === 'verify'">
        <h1 class="py-4 text-center">{{ 'Login_Required2FaSetupPhoneNumber_Header' | translate }}</h1>
        <div class="alert alert-warning">
            <i class="fas fa-exclamation-triangle"></i>
            <b>
                {{ 'Login_Required2FaSetupPhoneNumber_VerifyMessage' | translate }}
            </b>
            <br />
            {{ 'Login_Required2FaSetupPhoneNumber_VerifyMessageBody' | translate }}
        </div>

        <div class="form-group">
            <cms-input [id]="'code'" [placeholder]="'Login_Required2FaSetupPhoneNumber_Code' | translate" [formControl]="code"></cms-input>
        </div>
        <div class="form-group">
            <button type="button" class="btn btn-primary block full-width m-b" data-tid="btnSendConfirmationCode" (click)="verify()">
                {{ 'Common_Save' | translate }}
            </button>
        </div>
    </div>
</app-space-background>
