<app-page-header [title]="id ? ('History_Detail' | translate) : ('History_Title' | translate)">
    <breadcrumb [text]="'History_Utils' | translate"></breadcrumb>
    <breadcrumb *ngIf="!id" [text]="'History_Title' | translate"></breadcrumb>
    <breadcrumb *ngIf="id" [text]="'History_Title' | translate" [link]="'/audit'"></breadcrumb>
    <breadcrumb [text]="detailBreadCrumbs" [active]="true" *ngIf="id"></breadcrumb>
</app-page-header>
<form>
    <div class="wrapper wrapper-content animated fadeInRight">
        <div class="ibox">
            <div class="ibox-content"><router-outlet></router-outlet></div>
        </div>
    </div>
</form>
<app-page-footer> <storno-button *ngIf="id" (click)="stornoClick($event)"></storno-button> </app-page-footer>
