<app-space-background>
    <h1 class="py-4 text-center">{{ 'Login_ChangePassword' | translate }}</h1>
    <div>
        <div class="alert alert-success" *ngIf="message">
            {{ message }}
        </div>
        <div *ngIf="invalidToken">
            <div class="alert alert-danger">
                {{ 'Login_ChangePassword_InvalidToken' | translate }}
            </div>
            <div class="form-group">
                <a routerLink="/login" class="btn btn-default block full-width m-b" data-tid="btngoToLogin">
                    {{ 'Login_ChangePassword_BackToLogin' | translate }}
                </a>
            </div>
        </div>
        <form [formGroup]="form" *ngIf="form && !invalidToken">
            <app-new-password [form]="form" [(weakPassword)]="weakPassword" [username]="username"></app-new-password>
            <div class="form-group">
                <button id="submitBtn" type="submit" class="btn btn-primary block full-width m-b" (click)="resetPassword($event)" data-tid="btnChange">
                    {{ 'Common_Change' | translate }}
                </button>
            </div>
        </form>
    </div>
</app-space-background>
