<app-page-header [title]="'Setup2Fa_Title' | translate">
    <breadcrumb [active]="true" [text]="'Setup2Fa_Title' | translate"></breadcrumb>
</app-page-header>
<div class="wrapper-content animated fadeInRight">
    <div class="ibox">
        <div class="ibox-content">
            <form [formGroup]="form" *ngIf="form">
                <div class="row m-b-md">
                    <div class="col-xs-12">
                        <h2>{{ 'Setup2Fa_ModeSelection' | translate }}</h2>
                        <cms-radio
                            [id]="'twoFaRadio'"
                            [verticalLayout]="true"
                            formControlName="mode"
                            [items]="modeSelectionValues"
                            bindLabel="text"
                            bindValue="value"
                        ></cms-radio>
                    </div>
                </div>
                <div class="row" *ngIf="form.get('mode').value === 'APP'">
                    <div class="col-xs-12 col-lg-6 col-xl-4">
                        <h2>{{ 'Setup2FA_ConfHeader' | translate }}</h2>
                        <div>
                            <h3>{{ 'Setup2FA_GetApp' | translate }}</h3>
                            <p>
                                {{ 'Setup2FA_GetApp_1' | translate }}
                                <strong>Microsoft Authenticator</strong>(<a href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=cs"
                                    >android</a
                                >, <a href="https://itunes.apple.com/cz/app/microsoft-authenticator/id983156458?l=cs&mt=8">iOS</a>,
                                <a href="https://www.microsoft.com/cs-cz/store/p/microsoft-authenticator/9nblgggzmcj6">WindowsPhone</a>)
                                {{ 'Setup2FA_GetApp_2' | translate }}
                                <b>Google Authenticator</b> (<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                    >android</a
                                >
                                , <a href="https://itunes.apple.com/cz/app/google-authenticator/id388497605?mt=8">iOS</a>).
                                {{ 'Setup2FA_GetApp_3' | translate }}
                                <a href="https://authy.com/features/setup/">Authy</a>
                                {{ 'Setup2FA_GetApp_4' | translate }}
                            </p>
                        </div>
                        <div class="m-t-md">
                            <h3>{{ 'Setup2FA_CopyKey' | translate }}</h3>
                            <img class="m-b-md" [src]="getQrCode()" width="300" />
                            <textarea rows="3" class="form-control text-center bold cursor-auto resize-vertical" disabled>{{
                                form.get('manualCode').value
                            }}</textarea>
                        </div>
                        <div class="m-t-md">
                            <h3>{{ 'Setup2FA_Validate' | translate }}</h3>
                            <div class="row">
                                <div class="col-xs-4">
                                    <cms-input [id]="'code'" formControlName="code"> </cms-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<app-page-footer returnRoute="/dashboard">
    <storno-button></storno-button>
    <save-button (click)="save($event)"></save-button>
</app-page-footer>
