<div *ngIf="form">
    <form class="form" [formGroup]="form">
        <div class="row flexrow">
            <cms-select
                class="col-sm-12 col-md-4"
                [id]="'TypeId'"
                [labelText]="'Category_Main_TypeId' | translate"
                [formControl]="control('typeId')"
                [items]="categoryTypeList"
                bindLabel="text"
                bindValue="id"
            ></cms-select>
            <cms-select
                class="col-sm-12 col-md-4"
                [id]="'TypeId'"
                [labelText]="'Category_Main_NavigationCategoryTypeId' | translate"
                [formControl]="control('navigationCategoryTypeId')"
                [items]="categoryTypeList"
                bindLabel="text"
                bindValue="id"
                [clearable]="true"
            ></cms-select>
            <cms-select
                class="col-sm-12 col-md-4"
                [id]="'CatalogNavigationCategoryType'"
                [labelText]="'Category_Main_CatalogNavigationCategoryType' | translate"
                [formControl]="control('catalogNavigationCategoryType')"
                [enum]="'Category+CatalogNavigationCategoryType'"
                bindLabel="text"
                bindValue="id"
                [clearable]="false"
            ></cms-select>
            <cms-number-input
                class="col-sm-6 col-md-3 col-lg-2"
                [id]="'NavigationActionId'"
                name="navigationActionId"
                [labelText]="'Category_Main_NavigationActionId' | translate"
                [formControl]="control('navigationActionId')"
            ></cms-number-input>
            <cms-number-input
                class="col-sm-6 col-md-3 col-lg-2"
                [id]="'NavigationColumn'"
                name="navigationColumn"
                [labelText]="'Category_Main_NavigationColumn' | translate"
                [formControl]="control('navigationColumn')"
            ></cms-number-input>
            <cms-number-input
                class="col-sm-6 col-md-3 col-lg-3"
                [id]="'ProducerPosition'"
                name="producerPosition"
                [labelText]="'Category_Main_ProducerPosition' | translate"
                [formControl]="control('producerPosition')"
            ></cms-number-input>
            <cms-select
                class="col-sm-6 col-md-4 col-lg-3"
                [id]="'SourceSectionId'"
                name="sourceSectionId"
                [labelText]="'Category_Main_SourceSectionId' | translate"
                [formControl]="control('sourceSectionId')"
                [items]="sourceSections"
                bindLabel="text"
                bindValue="id"
                clearable="true"
            ></cms-select>
            <cms-select
                class="col-sm-6 col-md-4 col-lg-3"
                [id]="'Sort'"
                [labelText]="'Category_Main_Sort' | translate"
                [formControl]="control('sort')"
                [enum]="'Category+CategorySort'"
            ></cms-select>
            <cms-select
                class="col-sm-6 col-md-4 col-lg-3"
                [id]="'WearType'"
                [labelText]="'Category_Main_WearType' | translate"
                [formControl]="control('wearType')"
                [enum]="'Category+WearType'"
            ></cms-select>
            <cms-select
                class="col-sm-6 col-md-4 col-lg-3"
                [id]="'EnableExternalArticles'"
                [labelText]="'Category_Main_EnableExternalArticles' | translate"
                [labelTooltip]="'Category_Main_EnableExternalArticlesTooltip' | translate"
                [formControl]="control('enableExternalArticles')"
                [enum]="'CommonState'"
            ></cms-select>
            <cms-select
                class="col-sm-6 col-md-4 col-lg-3"
                [id]="'WebDisplayType'"
                [labelText]="'Category_Main_WebDisplayType' | translate"
                [formControl]="control('webDisplayType')"
                enum="Category+WebDisplayType"
            ></cms-select>
            <cms-select
                class="col-sm-6 col-md-4 col-lg-3"
                [id]="'targetGroup'"
                [labelText]="'Category_Main_TargetGroup' | translate"
                [formControl]="control('targetGroup')"
                enum="Category+TargetGroup"
            ></cms-select>
            <cms-select
                class="col-sm-6 col-md-4 col-lg-3"
                [multiple]="true"
                [id]="'excludedBannerCategories'"
                [labelText]="'Category_Main_ExcludedBannerCategories' | translate"
                [formControl]="control('excludedBannerCategories')"
                enum="Banner+InternalBannerCategory"
            ></cms-select>
            <app-color-picker
                class="col-sm-6 col-md-4 col-lg-3"
                [id]="'highlightedFontColor'"
                [labelText]="'Category_Main_HighlightedFontColor' | translate"
                [formControl]="control('highlightedFontColor')"
            ></app-color-picker>
            <cms-select
                class="col-sm-6 col-md-4 col-lg-3"
                [labelText]="'Category_Main_ShowPcConfiguratorSource' | translate"
                [id]="'showPcConfiguratorSource'"
                [formControl]="control('showPcConfiguratorSource')"
                [clearable]="true"
                [placeholder]="'Category_Main_ShowPcConfiguratorSource_FromParent' | translate"
                [items]="showPcConfiguratorSources"
                bindLabel="value"
                bindValue="key"
            ></cms-select>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="well">
                    <div class="row flexrow">
                        <cms-checkbox
                            [labelText]="'Category_Main_IsSeoCategory' | translate"
                            [id]="'IsSeoCategory'"
                            name="isSeoCategory"
                            [formControl]="control('isSeoCategory')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_NavigationHide' | translate"
                            [id]="'NavigationHide'"
                            name="navigationHide"
                            [formControl]="control('navigationHide')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_NavigationShowMore' | translate"
                            [id]="'NavigationShowMore'"
                            name="navigationShowMore"
                            [formControl]="control('navigationShowMore')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_IsMain' | translate"
                            [id]="'IsMain'"
                            name="isMain"
                            [formControl]="control('isMain')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_IsTopMenu' | translate"
                            [id]="'IsTopMenu'"
                            name="isTopMenu"
                            [formControl]="control('isTopMenu')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_IsComparable' | translate"
                            [id]="'IsComparable'"
                            name="isComparable"
                            [formControl]="control('isComparable')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_ShowRecommendedProducts' | translate"
                            [id]="'ShowRecommendedProducts'"
                            name="showRecommendedProducts"
                            [formControl]="control('showRecommendedProducts')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_ShowParametersInLegend' | translate"
                            [id]="'ShowParametersInLegend'"
                            name="showParametersInLegend"
                            [formControl]="control('showParametersInLegend')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_HideInProduction' | translate"
                            [labelTooltip]="'Category_Main_HideInProductionTooltip' | translate"
                            [id]="'HideInProduction'"
                            name="hideInProduction"
                            [formControl]="control('hideInProduction')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_HideInApi' | translate"
                            [labelTooltip]="'Category_Main_HideInApiTooltip' | translate"
                            [id]="'HideInApi'"
                            name="hideInApi"
                            [formControl]="control('hideInApi')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_ShowProducersParametersAtBeginning' | translate"
                            [labelTooltip]="'Category_Main_ShowProducersParametersAtBeginningTooltip' | translate"
                            [id]="'ShowProducersParametersAtBeginning'"
                            name="showProducersParametersAtBeginning"
                            [formControl]="control('showProducersParametersAtBeginning')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_EnableAutoGeneratedPages' | translate"
                            [labelTooltip]="'Category_Main_EnableAutoGeneratedPagesTooltip' | translate"
                            [id]="'EnableAutoGeneratedPages'"
                            name="enableAutoGeneratedPages"
                            [formControl]="control('enableAutoGeneratedPages')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_IsParametricGenerated' | translate"
                            [labelTooltip]="'Category_Main_IsParametricGeneratedTooltip' | translate"
                            [id]="'IsParametricGenerated'"
                            name="isParametricGenerated"
                            [formControl]="control('isParametricGenerated')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        >
                        </cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_UseOnlyOnFloor' | translate"
                            [id]="'useOnlyOnFloor'"
                            name="useOnlyOnFloor"
                            [formControl]="control('useOnlyOnFloor')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_IsSupplementary' | translate"
                            [id]="'IsSupplementary'"
                            name="isSupplementary"
                            [formControl]="control('isSupplementary')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_IsSubstitute' | translate"
                            [id]="'IsSubstitute'"
                            name="isSubstitute"
                            [formControl]="control('isSubstitute')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_IsInvisible' | translate"
                            [id]="'IsInvisible'"
                            name="isInvisible"
                            [formControl]="control('isInvisible')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_IsExternal' | translate"
                            [id]="'IsExternal'"
                            name="isExternal"
                            [formControl]="control('isExternal')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_RedirectToOriginal' | translate"
                            [id]="'RedirectToOriginal'"
                            name="redirectToOriginal"
                            [formControl]="control('redirectToOriginal')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_SideBannerDisabled' | translate"
                            [id]="'SideBannerDisabled'"
                            [formControl]="control('sideBannerDisabled')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_Highlighted' | translate"
                            [id]="'Highlighted'"
                            [formControl]="control('highlighted')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_UpperDescriptionShouldBeFilled' | translate"
                            [id]="'UpperDescriptionShouldBeFilled'"
                            [formControl]="control('upperDescriptionShouldBeFilled')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_HideInChildrenUrls' | translate"
                            [id]="'hideInChildrenUrls'"
                            name="hideInChildrenUrls"
                            [formControl]="control('hideInChildrenUrls')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_ParametersRequired' | translate"
                            [id]="'parametersRequired'"
                            name="parametersRequired"
                            [formControl]="control('parametersRequired')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_ShowDiscussionPost' | translate"
                            [id]="'showDiscussionPost'"
                            name="showDiscussionPost"
                            [formControl]="control('showDiscussionPost')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                        <cms-checkbox
                            [labelText]="'Category_Main_ShowLiveActivityFeed' | translate"
                            [id]="'showLiveActivityFeed'"
                            name="showLiveActivityFeed"
                            [formControl]="control('showLiveActivityFeed')"
                            class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                        ></cms-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <h3 class="col-xs-12">{{ 'Category_Main_Upsell' | translate }}</h3>
            <cms-checkbox
                [labelText]="'Category_Main_UpsellRepeatedPurchases' | translate"
                [id]="'UpsellRepeatedPurchases'"
                name="upsellRepeatedPurchases"
                [formControl]="control('upsellRepeatedPurchases')"
                class="col-xs-6 col-md-4 col-lg-3 withoutHeading"
            ></cms-checkbox>
            <cms-checkbox
                [labelText]="'Category_Main_UpsellShouldHave' | translate"
                [id]="'UpsellShouldHave'"
                name="upsellShouldHave"
                [formControl]="control('upsellShouldHave')"
                class="col-xs-6 col-md-4 col-lg-3 withoutHeading"
            ></cms-checkbox>
            <cms-number-input
                class="col-sm-6 col-md-4 col-lg-3"
                [id]="'UpsellShouldHaveLifetime'"
                name="upsellShouldHaveLifetime"
                [labelText]="'Category_Main_UpsellShouldHaveLifetime' | translate"
                [formControl]="control('upsellShouldHaveLifetime')"
                class="col-xs-6 col-md-4 col-lg-3"
            >
            </cms-number-input>
        </div>
    </form>
</div>
