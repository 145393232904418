<app-space-background>
    <div>
        <h1 class="py-4 text-center">{{ 'Login_Required2FaConfirmEmail_Header' | translate }}</h1>
        <div class="alert alert-warning">
            <i class="fas fa-exclamation-triangle"></i>
            <b>
                {{ 'Login_Required2FaConfirmEmail_Message' | translate }}
            </b>
            <br />
            {{ 'Login_Required2FaConfirmEmail_MessageBody' | translate }}
        </div>
    </div>
    <div>
        <div class="form-group">
            <button (click)="send()" type="button" class="btn btn-primary block full-width m-b" data-tid="btnSendConfirmationCode">
                {{ 'Login_SendConfirmationCode' | translate }} {{ email }}
            </button>
        </div>
    </div>
</app-space-background>
