import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'app/common/constants';
import { DialogsService } from 'app/common/services/dialogs.service';
import { MenuService } from 'app/common/services/menu.service';
import { SessionService } from 'app/common/services/session.service';
import { FormHelper } from 'app/components/forms/form-helper.service';
import { IAdminUserDto } from '../dto/user.dto';
import { ForgottenPasswordFormService } from '../forgotten-password-form.service';
import { LoginDataService } from '../login-data.service';

@Component({
    templateUrl: './forgotten-password.component.html',
    styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnInit, OnDestroy {
    public form: UntypedFormGroup;
    public message: string;
    public emailSent = false;
    public returnUrl: string;

    constructor(
        private readonly translate: TranslateService,
        private readonly formHelper: FormHelper,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly dialogs: DialogsService,
        private readonly sessionService: SessionService,
        private readonly menuService: MenuService,
        private readonly dataService: LoginDataService,
        private readonly formService: ForgottenPasswordFormService
    ) {
        this.form = this.formService.initForgottenPasswordForm();
    }

    ngOnInit() {
        this.sessionService.fullView = true;

        this.route.queryParams.subscribe((query) => {
            this.returnUrl = query['returnUrl'] ? query['returnUrl'] : '';
        });
    }

    ngOnDestroy() {
        this.sessionService.fullView = false;
    }

    public navigate() {
        this.menuService.getMenuItem(this.returnUrl).subscribe((result) => {
            if (this.returnUrl && result) {
                if (!result.isMvcModule) {
                    this.router.navigateByUrl(this.returnUrl).catch(EMPTY);
                } else {
                    window.location.href = decodeURIComponent(this.returnUrl);
                }
            } else {
                this.router.navigate(['/dashboard/']).catch(EMPTY);
            }
            return;
        });
    }

    public sendEmail() {
        if (this.formService.form && !this.formService.form.valid) {
            this.formService.form.markAllAsTouched();
            return;
        }

        const dto: IAdminUserDto = new IAdminUserDto();
        const infoForm = this.formService.getFormGroup();
        if (infoForm) {
            dto.password = infoForm.value.password;
            dto.userName = infoForm.value.username;
        }

        this.dataService.forgottenPassword(dto).subscribe(
            () => {
                this.message = '';
                this.message = this.translate.instant('Login_ForgotPassword_EmailSend');
                this.emailSent = true;
            },
            (err: HttpErrorResponse) => {
                if (err.status === 400) {
                    this.dialogs.badRequestMessage(err);
                }
            }
        );
    }
}
