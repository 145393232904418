import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CommonDataService } from './services';
import { ClientSettingsService } from './services/client-settings.service';

export class AppTranslateLoader implements TranslateLoader {
    constructor(
        private readonly http: HttpClient,
        private readonly cookies: CookieService,
        private readonly commonData: CommonDataService,
        private readonly clientSettings: ClientSettingsService
    ) {}
    getTranslation(): Observable<any> {
        const langCode = (this.cookies.get('adm2lang') || 'cs').toLowerCase();

        return this.commonData.language.getLanguages().pipe(
            switchMap((languages) => {
                const cultureInfo = (languages.find((x) => x.code.toLowerCase() === langCode)?.cultureInfo || 'cs-cz').toLowerCase();
                if (cultureInfo === 'cs-cz') {
                    return this.loadTranslations(cultureInfo);
                }
                return forkJoin([this.loadTranslations(cultureInfo), this.loadTranslations('cs-cz')]).pipe(
                    map(([res, def]) => {
                        Object.keys(def).forEach((defKey) => {
                            if (!res[defKey]) {
                                res[defKey] = def[defKey];
                            }
                        });
                        return res;
                    })
                );
            })
        );
    }

    private loadTranslations(culture: string) {
        let url = this.clientSettings.value.translationsUrl.replace('{cultureInfo}', culture);
        if (!environment.production && culture === 'cs-cz') {
            url = '/assets/i18n/cs.json';
        }
        return this.http.get<object>(url).pipe(
            catchError(() => of({})),
            map((x) => this.removePrefix(x))
        );
    }

    private removePrefix(translations: object) {
        if (!translations) {
            return {};
        }
        const res = {};
        Object.keys(translations).forEach((key) => {
            if (key.startsWith('Admin_NG_')) {
                res[key.substr('Admin_NG_'.length)] = translations[key];
            } else {
                res[key] = translations[key];
            }
        });
        return res;
    }
}
