import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'app/common/constants';
import { DialogsService, SessionService } from 'app/common/services';
import { LoginDataService } from '../login-data.service';

@Component({
    selector: 'app-phone-number-required',
    templateUrl: './phone-number-required.component.html',
    styleUrls: ['./phone-number-required.component.scss']
})
export class PhoneNumberRequiredComponent implements OnInit, OnDestroy {
    public email: string;

    constructor(
        private readonly session: SessionService,
        private readonly dataService: LoginDataService,
        private readonly router: Router,
        private readonly dialogs: DialogsService,
        private readonly translate: TranslateService
    ) {}

    ngOnInit() {
        this.dataService.getUserEmail().subscribe((res) => {
            this.email = res.email;
        });
        this.session.fullView = true;
    }

    ngOnDestroy() {
        this.session.fullView = false;
    }

    public send() {
        this.dataService.sendSetup2FaPhoneNumberEmail().subscribe(() => {
            this.dialogs.successMessage(this.translate.instant('Login_Required2FaConfirmEmail_EmailSend'), '', { timer: 10000 });
            this.router.navigate(['/login']).catch(EMPTY);
        });
    }
}
