import { Validators } from '@alza/cms-components';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormHelper } from 'app/components/forms/form-helper.service';

@Injectable()
export class VerifyTwoFaFormService {
    public form: UntypedFormGroup;

    constructor(private formHelper: FormHelper) {}

    initVerify2faForm() {
        this.form = new UntypedFormGroup({
            code: new UntypedFormControl('', [Validators.required])
        });
        return this.form;
    }

    getFormGroup() {
        if (this.form) {
            return this.form;
        }
        return null;
    }
}
