import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { defaultCountryTranslation } from 'app/common/constants';
import { ICountryTranslationDto } from 'app/common/dto/common-dto';
import { CommonDataService } from 'app/common/services';
import { UrlUtils } from 'app/common/utils';
import { ITab, TabManager } from 'app/components/tabs';
import { takeUntil } from 'rxjs/operators';
import { CategoryBaseComponent } from '../../category-base.component';
import { CategoryFormService } from '../../category-form.service';

@Component({
    templateUrl: './texts.component.html',
    styleUrls: ['./texts.component.scss']
})
export class TextsComponent extends CategoryBaseComponent implements OnInit {
    public main?: UntypedFormGroup;
    public textTabs: TabManager;
    public hasValueChanged = false;
    public tmpUrl: string;
    public languages: Array<ICountryTranslationDto>;

    constructor(
        private readonly commonDataService: CommonDataService,
        private readonly formService: CategoryFormService,
        readonly translateService: TranslateService
    ) {
        super();
        this.textTabs = new TabManager(translateService);
        this.textTabs.tabs = [
            {
                name: 'general',
                text: _('Category_TextsGeneralTab')
            },
            {
                name: 'upperDescription',
                text: _('Category_TextsUpperDescriptionTab')
            },
            {
                name: 'bottomDescription',
                text: _('Category_TextsBottomDescriptionTab')
            },
            {
                name: 'productInfo',
                text: _('Category_TextsProductInfoTab')
            }
        ];
    }

    ngOnInit() {
        this.commonDataService.language
            .getLanguages()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((languages) => {
                this.languages = languages.filter((language) => language.id !== defaultCountryTranslation);
            });
        this.formService.reload$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.form = this.formService.getTexts();
            this.main = this.formService.getMain();
        });
        this.form
            .get(`localization.${this.selectedCountry}.url`)
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((url) => {
                if (!this.tmpUrl) {
                    this.tmpUrl = url;
                }
                if (this.tmpUrl && this.tmpUrl !== url) {
                    this.hasValueChanged = true;
                }
            });
    }

    public tabInvalid(tab: ITab) {
        if (!this.form) {
            return false;
        }
        const loc = this.form.get('localization') as UntypedFormArray;

        let isInvalid = false;
        for (const country of Object.keys(loc.controls)) {
            switch (tab.name) {
                case 'general':
                    isInvalid =
                        isInvalid ||
                        this.form.get(`localization.${country}.name`).invalid ||
                        this.form.get(`localization.${country}.seoName`).invalid ||
                        this.form.get(`localization.${country}.url`).invalid ||
                        this.form.get(`localization.${country}.navigationText`).invalid ||
                        this.form.get(`localization.${country}.title`).invalid ||
                        this.form.get(`localization.${country}.keywords`).invalid ||
                        this.form.get(`localization.${country}.descriptionTag`).invalid ||
                        this.form.get(`localization.${country}.redText`).invalid;
                    break;
                case 'upperDescription':
                    isInvalid =
                        isInvalid ||
                        this.form.get('generatedDescriptionType').invalid ||
                        this.form.get(`localization.${country}.upperDescription`).invalid ||
                        this.form.get(`localization.${country}.upperDescriptionAnnotation`).invalid;
                    break;
                case 'bottomDescription':
                    isInvalid =
                        isInvalid ||
                        this.form.get(`localization.${country}.bottomDescriptionAnnotation`).invalid ||
                        this.form.get(`localization.${country}.bottomDescription`).invalid;
                    break;
                case 'productInfo':
                    isInvalid =
                        isInvalid ||
                        this.form.get(`localization.${country}.commodityUpperText`).invalid ||
                        this.form.get(`localization.${country}.commodityBottomText`).invalid ||
                        this.form.get(`localization.${country}.commodityFaq`).invalid;
                    break;
            }
        }
        return isInvalid;
    }

    public get showGeneralPanel() {
        return (
            this.main &&
            (this.main.get('isInvisible').value ||
                this.main.get('isExternal').value ||
                this.main.get('isSupplementary').value ||
                this.main.get('isSubstitute').value)
        );
    }

    public get countryVisibilityChangedMessage() {
        return this.formService.getCountryVisibilityChangedMessage(this.selectedCountry);
    }

    public showHasLinkedCategoryProducerSettingsMessage(url: UntypedFormControl) {
        if (this.hasValueChanged) {
            return this.formService.getUrlChangeFlags().get('hasLinkedCategoryProducerSettings').value && url.touched;
        }
    }

    public showUrlWillBeModifiedMessage(url: UntypedFormControl) {
        if (this.hasValueChanged) {
            return this.formService.getUrlChangeFlags().get('hasSubstituteExternalCategories').value && url.touched;
        }
    }

    public get isDefaultLanguage() {
        return this.selectedCountry === defaultCountryTranslation;
    }

    fillUrl() {
        this.form
            .get(`localization.${this.selectedCountry}.url`)
            .patchValue(UrlUtils.toUrlFormat(this.form.get(`localization.${this.selectedCountry}.seoName`).value || '') + '/' + this.formService.id + '.htm');
    }
}
