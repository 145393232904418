import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonDataService } from './services/common-data.service';
import { DialogsService } from './services/dialogs.service';
import { EnumsService } from './services/enums.service';
import { MenuService } from './services/menu.service';
import { SessionService } from './services/session.service';

@NgModule({
    providers: [SessionService, DialogsService, CommonDataService, EnumsService, MenuService]
})
export class CommonModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CommonModule
    ) {
        if (parentModule) {
            throw new Error(`${String(parentModule)} has already been loaded. Import Core modules in the AppModule only.`);
        }
    }
}
