<form (ngSubmit)="search()">
    <div class="row flexrow">
        <cms-input
            [id]="'name'"
            class="col-xs-12 col-md-4 col-lg-3 flexcol"
            [labelText]="'Common_Search_Heading' | translate"
            [(ngModel)]="filter.searchValue"
            [placeholder]="'Common_SearchPlaceholder' | translate"
            [ngModelOptions]="{ standalone: true }"
        ></cms-input>
        <cms-select
            class="col-xs-12 col-md-4 col-lg-3 flexcol"
            [id]="'countryIds'"
            name="countryIds"
            [labelText]="'Common_Country' | translate"
            [(ngModel)]="filter.countryIds"
            [items]="countries"
            bindLabel="name"
            bindValue="id"
            multiple="true"
        ></cms-select>
        <cms-checkbox
            [id]="'expanded'"
            name="expanded"
            class="col-xs-5 col-md-4 col-lg-2 col-xl-1 flexcol m-t-md"
            [labelText]="'Category_FilterExpanded' | translate"
            [(ngModel)]="filter.expanded"
            [ngModelOptions]="{ standalone: true }"
        ></cms-checkbox>

        <div class="col-xs-7 col-md-7 col-lg-3 flexcol">
            <div class="flexfill"></div>
            <div class="form-group btn-group" role="group">
                <button type="submit" data-tid="btnSearch" class="btn btn-primary">{{ 'Common_Search_Button' | translate }}</button>
                <button type="button" data-tid="btnClear" class="btn btn-secondary" (click)="clearFilter()">
                    {{ 'Common_Search_Clear' | translate }}
                </button>
            </div>
        </div>
    </div>
</form>
<div style="max-width: 600px">
    <app-category-tree-view
        [itemTemplate]="itemTemplate"
        [filter]="filter"
        (categoryIdChange)="openDetail($event)"
        (expandedIds)="expandedIds = $event"
    ></app-category-tree-view>

    <ng-template #itemTemplate let-item>
        <div class="item-default">
            <span class="item-clickable" (click)="openDetail(item.id)">{{ item.name }}</span>
            <span
                *ngIf="item.isSubstitute"
                class="text-muted item-clickable"
                [class.text-match-muted]="item.isMatch"
                (click)="openDetail(item.originCategoryId)"
                >({{ item.originCategoryName }})</span
            >
            <div class="item-icons">
                <i class="far fa-eye-slash fa-fw" *ngIf="item.isInvisible" [cmsTooltip]="'Category_Invisible' | translate"></i>
                <i class="fas fa-external-link-alt fa-fw" *ngIf="item.isExternal" [cmsTooltip]="'Category_IsExternal' | translate"></i>
                <i class="fas fa-share fa-fw" *ngIf="item.isSubstitute" [cmsTooltip]="'Category_IsLink' | translate"></i>
                <i class="fas fa-exchange-alt fa-fw" *ngIf="item.isSupplementary" [cmsTooltip]="'Category_IsSupplementary' | translate"></i>
            </div>
        </div>
    </ng-template>
</div>
