<form (ngSubmit)="pager.search()">
    <div class="row flexrow">
        <cms-select
            class="col-xs-12 col-md-4 col-lg-3 flexcol"
            name="languageId"
            [id]="'languageId'"
            [labelText]="'CategoryProducerTranslations_FilterUntranslatedLanguage' | translate"
            [items]="languages"
            [(ngModel)]="pager.filter.languageId"
            bindLabel="name"
            bindValue="id"
        ></cms-select>
        <cms-select
            class="col-xs-12 col-md-4 col-lg-3 flexcol"
            name="field"
            [id]="'field'"
            [labelText]="'CategoryProducerTranslations_FieldWithoutTranslation' | translate"
            [enum]="'Category+CategoryProducerTextsFields'"
            [(ngModel)]="pager.filter.field"
            [clearable]="true"
        ></cms-select>
        <cms-select
            class="col-xs-12 col-md-4 col-lg-3 flexcol"
            name="assignedUser"
            [id]="'assignedUser'"
            [labelText]="'CategoryProducerTranslations_Assigned' | translate"
            [(ngModel)]="pager.filter.assignedUserId"
            [clearable]="true"
            [items]="users"
            bindLabel="text"
            bindValue="id"
            [virtualScroll]="true"
        ></cms-select>

        <div class="col-xs-12 col-sm-6 col-lg-3 flexcol">
            <div class="flexfill"></div>
            <div class="form-group btn-group" role="group">
                <button type="submit" data-tid="btnSearch" class="btn btn-primary">{{ 'Common_Search_Button' | translate }}</button>
                <button type="button" data-tid="btnClear" class="btn btn-secondary" (click)="pager.clearFilter()">
                    {{ 'Common_Search_Clear' | translate }}
                </button>
            </div>
        </div>
    </div>
</form>
<app-grid data-tid="table">
    <table class="table table-striped table-bordered table-hover dataTable">
        <thead>
            <tr role="row">
                <th [appSortableColumn]="1" data-tid="categoryId">
                    <span class="colTitle">{{ 'CategoryProducerTranslations_CategoryId' | translate }}</span>
                </th>
                <th [appSortableColumn]="2" data-tid="name">
                    <span class="colTitle">{{ 'Category_Name' | translate }}</span>
                </th>
                <th [appSortableColumn]="3" data-tid="seoName">
                    <span class="colTitle">{{ 'Category_SeoName' | translate }}</span>
                </th>
                <th [appSortableColumn]="4" data-tid="producerId">
                    <span class="colTitle">{{ 'CategoryProducerTranslations_ProducerId' | translate }}</span>
                </th>
                <th [appSortableColumn]="5" data-tid="producerName">
                    <span class="colTitle">{{ 'Category_ProducerName' | translate }}</span>
                </th>
                <th [appSortableColumn]="0" data-tid="rank">
                    <span class="colTitle">{{ 'CategoryProducerTranslations_Rank' | translate }}</span>
                </th>
                <th>
                    <span class="colTitle">{{ 'CategoryProducerTranslations_Assigned' | translate }}</span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr [class.clickable]="openable(item)" role="row" [attr.data-tid]="'row_' + ix" *ngFor="let item of pager.items; let ix = index">
                <td (click)="openDetail(item)" data-tid="cell_id">{{ item.categoryId }}</td>
                <td (click)="openDetail(item)" data-tid="cell_name">{{ item.categoryName }}</td>
                <td (click)="openDetail(item)" data-tid="cell_seoName">{{ item.categorySeoName }}</td>
                <td (click)="openDetail(item)" data-tid="cell_producerId">{{ item.producerId }}</td>
                <td (click)="openDetail(item)" data-tid="cell_producerName">{{ item.producerName }}</td>
                <td (click)="openDetail(item)" data-tid="cell_rank">{{ item.rank }}</td>
                <td (click)="openDetail(item)" *ngIf="!item.assignedUserName" data-tid="cell_assigned"></td>
                <td (click)="openable(item)" *ngIf="item.assignedUserName">
                    {{ item.assignedUserName }}
                    <i class="fas clickable fa-fw fa-times" (click)="unassign(item)"></i>
                </td>
            </tr>
        </tbody>
    </table>
</app-grid>
