import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BusyService } from 'app/common/services/busy.service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoadingIndicatorHttpInterceptor implements HttpInterceptor {
    constructor(private readonly busy: BusyService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const r = next.handle(req);
        let started = false;
        const responseSubscribe = r.subscribe.bind(r);
        r.subscribe = (...args) => {
            this.busy.add(req);
            started = true;
            return responseSubscribe(...args);
        };

        return r.pipe(finalize(() => started && this.busy.sub(req)));
    }
}
