<div *ngIf="form">
    <form class="form" [formGroup]="form">
        <div class="row">
            <div class="col-xs-12 col-md-8 col-lg-9">
                <app-tabs id="category_texts" [active]="textTabs.selected" (activeChange)="textTabs.select($event)">
                    <tab [title]="tab.text" [value]="tab.name" *ngFor="let tab of textTabs.tabs" [formInvalid]="tabInvalid(tab)"></tab>
                    <div body>
                        <div *ngIf="textTabs.isSelected('general')">
                            <div class="localizationGroup">
                                <app-localization-switch
                                    [localizedGroup]="form.get('localization')"
                                    (localizationChanged)="selectedCountry = $event"
                                    [properties]="['descriptionTag', 'keywords', 'name', 'navigationText', 'redText', 'seoName', 'title', 'tooltip', 'url']"
                                ></app-localization-switch>
                                <div class="items ibox-content">
                                    <div class="row flexrow">
                                        <div class="col-xs-12">
                                            <cms-checkbox
                                                class="compact send-checkbox"
                                                [id]="'flagName'"
                                                [labelText]="'Category_SendToTranslate' | translate"
                                                [formControl]="form.get('sendTextsToTranslate.fieldsToSend.name')"
                                            ></cms-checkbox>
                                            <cms-input [id]="'name'" [labelText]="'Category_Name' | translate" [formControl]="localized('name')"></cms-input>
                                        </div>
                                        <div class="col-xs-12">
                                            <cms-checkbox
                                                class="compact send-checkbox"
                                                [id]="'flagSeoName'"
                                                [labelText]="'Category_SendToTranslate' | translate"
                                                [formControl]="form.get('sendTextsToTranslate.fieldsToSend.seoName')"
                                            >
                                            </cms-checkbox>
                                            <cms-input [id]="'seoName'" [labelText]="'Category_SeoName' | translate" [formControl]="localized('seoName')">
                                            </cms-input>
                                            <div class="form-group">
                                                <button class="btn btn-xs btn-primary" [disabled]="localized('url').disabled" (click)="fillUrl()">
                                                    {{ 'Category_GenerateUrl' | translate }}
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-xs-12">
                                            <cms-checkbox
                                                class="compact send-checkbox"
                                                [id]="'flagUrl'"
                                                [labelText]="'Category_SendToTranslate' | translate"
                                                [formControl]="form.get('sendTextsToTranslate.fieldsToSend.url')"
                                            ></cms-checkbox>
                                            <cms-input [id]="'url'" [labelText]="'Category_Url' | translate" [formControl]="localized('url')"> </cms-input>
                                            <cms-checkbox
                                                *ngIf="!isDefaultLanguage"
                                                [id]="'urlRedirectAllowed'"
                                                [labelText]="'Category_UrlRedirectAllowed' | translate"
                                                [formControl]="localized('urlRedirectAllowed')"
                                            ></cms-checkbox>

                                            <div *ngIf="showHasLinkedCategoryProducerSettingsMessage(localized('url'))" class="alert alert-warning">
                                                {{ 'Category_LinkedCategoryWillBeUpdated' | translate }}
                                            </div>
                                            <div *ngIf="showUrlWillBeModifiedMessage(localized('url'))" class="alert alert-warning">
                                                {{ 'Category_UrlWillBeModifiedOnSubcategories' | translate }}
                                            </div>
                                            <div *ngIf="countryVisibilityChangedMessage" class="alert alert-warning">
                                                {{ countryVisibilityChangedMessage }}
                                            </div>
                                        </div>
                                        <div class="col-xs-12">
                                            <cms-checkbox
                                                class="compact send-checkbox"
                                                [id]="'flagNavigationText'"
                                                [labelText]="'Category_SendToTranslate' | translate"
                                                [formControl]="form.get('sendTextsToTranslate.fieldsToSend.navigationText')"
                                            >
                                            </cms-checkbox>
                                            <cms-input
                                                [id]="'navigationText'"
                                                [labelText]="'Category_NavigationText' | translate"
                                                [formControl]="localized('navigationText')"
                                            ></cms-input>
                                        </div>
                                        <div class="col-xs-12">
                                            <cms-checkbox
                                                class="compact send-checkbox"
                                                [id]="'flagTitle'"
                                                [labelText]="'Category_SendToTranslate' | translate"
                                                [formControl]="form.get('sendTextsToTranslate.fieldsToSend.title')"
                                            ></cms-checkbox>
                                            <cms-input
                                                [id]="'title'"
                                                [labelText]="'Category_TileTitle' | translate"
                                                [formControl]="localized('title')"
                                            ></cms-input>
                                        </div>
                                        <div class="col-xs-12">
                                            <cms-checkbox
                                                class="compact send-checkbox"
                                                [id]="'flagKeywords'"
                                                [labelText]="'Category_SendToTranslate' | translate"
                                                [formControl]="form.get('sendTextsToTranslate.fieldsToSend.keywords')"
                                            >
                                            </cms-checkbox>
                                            <cms-input
                                                [id]="'keywords'"
                                                [labelText]="'Category_Keywords' | translate"
                                                [formControl]="localized('keywords')"
                                            ></cms-input>
                                        </div>
                                        <div class="col-xs-12">
                                            <cms-checkbox
                                                class="compact send-checkbox"
                                                [id]="'flagDescriptionTag'"
                                                [labelText]="'Category_SendToTranslate' | translate"
                                                [formControl]="form.get('sendTextsToTranslate.fieldsToSend.descriptionTag')"
                                            >
                                            </cms-checkbox>
                                            <cms-input
                                                [id]="'descriptionTag'"
                                                [labelText]="'Category_DescriptionTag' | translate"
                                                [formControl]="localized('descriptionTag')"
                                            ></cms-input>
                                        </div>
                                        <div class="col-xs-12">
                                            <cms-checkbox
                                                class="compact send-checkbox"
                                                [id]="'flagRedText'"
                                                [labelText]="'Category_SendToTranslate' | translate"
                                                [formControl]="form.get('sendTextsToTranslate.fieldsToSend.redText')"
                                            >
                                            </cms-checkbox>
                                            <cms-input
                                                [id]="'redText'"
                                                [labelText]="'Category_RedText' | translate"
                                                [formControl]="localized('redText')"
                                            ></cms-input>
                                        </div>
                                        <div class="col-xs-12">
                                            <cms-checkbox
                                                class="compact send-checkbox"
                                                [id]="'flagTooltip'"
                                                [labelText]="'Category_SendToTranslate' | translate"
                                                [formControl]="form.get('sendTextsToTranslate.fieldsToSend.tooltip')"
                                            >
                                            </cms-checkbox>
                                            <cms-input
                                                [id]="'redText'"
                                                [labelText]="'Category_Tooltip' | translate"
                                                [formControl]="localized('tooltip')"
                                            ></cms-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="textTabs.isSelected('upperDescription')">
                            <cms-select
                                class="col-xs-6"
                                [id]="'generatedDescriptionType'"
                                [labelText]="'Category_GeneratedDescriptionType' | translate"
                                [formControl]="form.get('generatedDescriptionType')"
                                [enum]="'Category+GeneratedDescriptionType'"
                            >
                            </cms-select>
                            <cms-checkbox
                                class="col-xs-6 withoutHeading"
                                style="margin-bottom: 0px; text-align: left"
                                [id]="'responsiveAnnotation'"
                                [labelText]="'Category_ResponsiveAnnotation' | translate"
                                [formControl]="form.get('responsiveAnnotation')"
                            ></cms-checkbox>
                            <div class="localizationGroup">
                                <app-localization-switch
                                    [localizedGroup]="form.get('localization')"
                                    (localizationChanged)="selectedCountry = $event"
                                    [properties]="['upperDescription', 'upperDescriptionAnnotation', 'upperDescriptionInfoText']"
                                ></app-localization-switch>
                                <div class="items ibox-content">
                                    <div class="row flexrow">
                                        <div class="col-xs-12">
                                            <cms-checkbox
                                                class="compact send-checkbox"
                                                [id]="'flagUpperDescriptionAnnotation'"
                                                [labelText]="'Category_SendToTranslate' | translate"
                                                [formControl]="form.get('sendTextsToTranslate.fieldsToSend.upperDescriptionAnnotation')"
                                            >
                                            </cms-checkbox>
                                            <app-froala
                                                [id]="'upperDescriptionAnnotation'"
                                                [labelText]="'Category_UpperDescriptionAnnotation' | translate"
                                                [formControl]="localized('upperDescriptionAnnotation')"
                                            >
                                            </app-froala>
                                        </div>
                                        <div class="col-xs-12">
                                            <cms-checkbox
                                                class="compact send-checkbox"
                                                [id]="'flagUpperDescription'"
                                                [labelText]="'Category_SendToTranslate' | translate"
                                                [formControl]="form.get('sendTextsToTranslate.fieldsToSend.upperDescription')"
                                            >
                                            </cms-checkbox>
                                            <app-froala
                                                [id]="'upperDescription'"
                                                [labelText]="'Category_UpperDescription' | translate"
                                                [formControl]="localized('upperDescription')"
                                            ></app-froala>
                                        </div>
                                        <div class="col-xs-12">
                                            <cms-checkbox
                                                class="compact send-checkbox"
                                                [id]="'flagUpperDescriptionInfoText'"
                                                [labelText]="'Category_SendToTranslate' | translate"
                                                [formControl]="form.get('sendTextsToTranslate.fieldsToSend.upperDescriptionInfoText')"
                                            >
                                            </cms-checkbox>
                                            <app-froala
                                                [id]="'upperDescriptionInfoText'"
                                                [labelText]="'Category_UpperDescriptionInfoText' | translate"
                                                [formControl]="localized('upperDescriptionInfoText')"
                                            ></app-froala>
                                        </div>
                                        <cms-datetimepicker
                                            class="col-xs-12 col-md-6 col-lg-4"
                                            [id]="'upperDescriptionLastUpdate'"
                                            [labelText]="'Category_UpperDescriptionLastUpdate' | translate"
                                            [formControl]="localized('upperDescriptionLastUpdate')"
                                        ></cms-datetimepicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="textTabs.isSelected('bottomDescription')">
                            <div class="localizationGroup">
                                <app-localization-switch
                                    [localizedGroup]="form.get('localization')"
                                    (localizationChanged)="selectedCountry = $event"
                                    [properties]="['bottomDescription', 'bottomDescriptionAnnotation']"
                                ></app-localization-switch>
                                <div class="items ibox-content">
                                    <div class="row flexrow">
                                        <div class="col-xs-12">
                                            <cms-checkbox
                                                class="compact send-checkbox"
                                                [id]="'flagBottomDescriptionAnnotation'"
                                                [labelText]="'Category_SendToTranslate' | translate"
                                                [formControl]="form.get('sendTextsToTranslate.fieldsToSend.bottomDescriptionAnnotation')"
                                            >
                                            </cms-checkbox>
                                            <app-froala
                                                [id]="'bottomDescriptionAnnotation'"
                                                [labelText]="'Category_BottomDescriptionAnnotation' | translate"
                                                [formControl]="localized('bottomDescriptionAnnotation')"
                                            >
                                            </app-froala>
                                        </div>
                                        <div class="col-xs-12">
                                            <cms-checkbox
                                                class="compact send-checkbox"
                                                [id]="'flagBottomDescription'"
                                                [labelText]="'Category_SendToTranslate' | translate"
                                                [formControl]="form.get('sendTextsToTranslate.fieldsToSend.bottomDescription')"
                                            >
                                            </cms-checkbox>
                                            <app-froala
                                                [id]="'bottomDescription'"
                                                [labelText]="'Category_BottomDescription' | translate"
                                                [formControl]="localized('bottomDescription')"
                                            ></app-froala>
                                        </div>
                                        <cms-datetimepicker
                                            class="col-xs-12 col-md-6 col-lg-4"
                                            [id]="'bottomDescriptionLastUpdate'"
                                            [labelText]="'Category_BottomDescriptionLastUpdate' | translate"
                                            [formControl]="localized('bottomDescriptionLastUpdate')"
                                        ></cms-datetimepicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="textTabs.isSelected('productInfo')">
                            <div class="localizationGroup">
                                <app-localization-switch
                                    [localizedGroup]="form.get('localization')"
                                    (localizationChanged)="selectedCountry = $event"
                                    [properties]="['commodityBottomText', 'commodityFaq', 'commodityUpperText']"
                                ></app-localization-switch>
                                <div class="items ibox-content">
                                    <div class="row flexrow">
                                        <div class="col-xs-12">
                                            <cms-checkbox
                                                class="compact send-checkbox"
                                                [id]="'flagCommodityUpperText'"
                                                [labelText]="'Category_SendToTranslate' | translate"
                                                [formControl]="form.get('sendTextsToTranslate.fieldsToSend.commodityUpperText')"
                                            >
                                            </cms-checkbox>
                                            <app-froala
                                                [id]="'commodityUpperText'"
                                                [labelText]="'Category_CommodityUpperText' | translate"
                                                [formControl]="localized('commodityUpperText')"
                                            ></app-froala>
                                        </div>
                                        <div class="col-xs-12">
                                            <cms-checkbox
                                                class="compact send-checkbox"
                                                [id]="'flagCommodityBottomText'"
                                                [labelText]="'Category_SendToTranslate' | translate"
                                                [formControl]="form.get('sendTextsToTranslate.fieldsToSend.commodityBottomText')"
                                            >
                                            </cms-checkbox>
                                            <app-froala
                                                [id]="'commodityBottomText'"
                                                [labelText]="'Category_CommodityBottomText' | translate"
                                                [formControl]="localized('commodityBottomText')"
                                            ></app-froala>
                                        </div>
                                        <div class="col-xs-12">
                                            <cms-checkbox
                                                class="compact send-checkbox"
                                                [id]="'flagCommodityFaq'"
                                                [labelText]="'Category_SendToTranslate' | translate"
                                                [formControl]="form.get('sendTextsToTranslate.fieldsToSend.commodityFaq')"
                                            >
                                            </cms-checkbox>
                                            <app-froala
                                                [id]="'commodityFaq'"
                                                [labelText]="'Category_CommodityFaq' | translate"
                                                [formControl]="localized('commodityFaq')"
                                            ></app-froala>
                                        </div>
                                        <cms-datetimepicker
                                            class="col-xs-12 col-md-6 col-lg-4"
                                            [id]="'productInfoLastUpdate'"
                                            [labelText]="'Category_ProductInfoLastUpdate' | translate"
                                            [formControl]="localized('productInfoLastUpdate')"
                                        ></cms-datetimepicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </app-tabs>
            </div>
            <div class="col-xs-12 col-md-4 col-lg-3">
                <div class="panel panel-default mrgt-40" [class.disabledBlock]="form.get('sendTextsToTranslate.languageIds').disabled">
                    <div class="panel-heading">
                        <label>{{ 'Category_TranslateInLanguage' | translate }}</label>
                    </div>

                    <div class="panel-body" *ngIf="languages">
                        <cms-select
                            [id]="'languageIds'"
                            [labelText]="'Category_TranslateToLanguage' | translate"
                            [formControl]="form.get('sendTextsToTranslate.languageIds')"
                            [items]="languages"
                            bindLabel="name"
                            bindValue="id"
                            [multiple]="true"
                        ></cms-select>
                    </div>
                </div>
                <div class="panel panel-default mrgt-40" *ngIf="showGeneralPanel">
                    <div class="panel-heading">
                        <label>{{ 'Category_General' | translate }}</label>
                    </div>
                    <div class="panel-body">
                        <div *ngIf="main.get('isInvisible').value">
                            <i class="far fa-eye-slash fa-enhanced fa-fw" [cmsTooltip]="'Category_Invisible' | translate"></i>
                            {{ 'Category_Invisible' | translate }}
                        </div>
                        <div *ngIf="main.get('isExternal').value">
                            <i class="fas fa-external-link-alt fa-enhanced fa-fw" [cmsTooltip]="'Category_IsExternal' | translate"></i>
                            {{ 'Category_IsExternal' | translate }}
                        </div>
                        <div *ngIf="main.get('isSupplementary').value">
                            <i class="fas fa-exchange-alt fa-enhanced fa-fw" [cmsTooltip]="'Category_IsSupplementary' | translate"></i>
                            {{ 'Category_IsSupplementary' | translate }}
                        </div>
                        <div *ngIf="main.get('isSubstitute').value">
                            <i class="fas fa-share fa-enhanced fa-fw" [cmsTooltip]="'Category_IsLink' | translate"></i>
                            {{ 'Category_IsLink' | translate }}
                            <span class="text-muted">({{ main.get('originCategoryName').value }})</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
