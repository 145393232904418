<div *ngIf="form">
    <form class="form" [formGroup]="form">
        <div class="row">
            <ng-container
                *ngTemplateOutlet="
                    imageblock;
                    context: { label: 'Category_Images_Small' | translate, controlName: 'imageSmall', serverDirectory: 'Foto\\category\\40\\' }
                "
            ></ng-container>
            <ng-container
                *ngTemplateOutlet="
                    imageblock;
                    context: { label: 'Category_Images_Big' | translate, controlName: 'imageBig', serverDirectory: 'Foto\\category\\orig\\' }
                "
            ></ng-container>
            <ng-container
                *ngTemplateOutlet="
                    imageblock;
                    context: { label: 'Category_Images_CategoryIcon' | translate, controlName: 'categoryIcon', serverDirectory: 'Foto\\category\\icon\\' }
                "
            ></ng-container>
        </div>
    </form>
</div>

<ng-template #imageblock let-controlName="controlName" let-label="label" let-serverDirectory="serverDirectory">
    <div class="col-sm-12 col-md-4">
        <app-file-input
            *ngIf="form.get(controlName)"
            [id]="controlName"
            [labelText]="label"
            [formControl]="form.get(controlName)"
            [accept]="['.jpg', '.png', '.jpeg', '.svg']"
            [serverDirectory]="serverDirectory"
        >
        </app-file-input>
        <div class="image" *ngIf="form.get(controlName)?.value?.isImage">
            <img [attr.src]="getImage(form.get(controlName).value.data) || form.get(controlName).value.url" [attr.alt]="form.get(controlName).value?.name" />
        </div>
    </div>
</ng-template>
