import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IKeyValue } from 'app/common/dto/common-dto';
import { CommonDataService } from 'app/common/services';
import { ISelectListItemDto } from 'app/models';
import { takeUntil } from 'rxjs/operators';
import { CategoryBaseComponent } from '../../category-base.component';
import { CategoryDataService } from '../../category-data.service';
import { CategoryFormService } from '../../category-form.service';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent extends CategoryBaseComponent implements OnInit {
    categoryTypeList: Array<ISelectListItemDto>;
    sourceSections: Array<ISelectListItemDto>;
    showPcConfiguratorSources: Array<IKeyValue<boolean, string>>;

    constructor(
        private readonly commonDataService: CommonDataService,
        private readonly formService: CategoryFormService,
        private readonly dataService: CategoryDataService,
        private readonly translate: TranslateService
    ) {
        super();
    }

    ngOnInit() {
        this.formService.reload$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.form = this.formService.getMain();
        });
        this.dataService
            .categoryTypes()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => (this.categoryTypeList = res));
        this.commonDataService.selectList
            .getSections()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => (this.sourceSections = res));

        this.translate
            .stream([
                'Category_Main_ShowPcConfiguratorSource_FromParent',
                'Category_Main_ShowPcConfiguratorSource_Show',
                'Category_Main_ShowPcConfiguratorSource_NotShow'
            ])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((text) => {
                this.showPcConfiguratorSources = [
                    { key: true, value: text['Category_Main_ShowPcConfiguratorSource_Show'] },
                    { key: false, value: text['Category_Main_ShowPcConfiguratorSource_NotShow'] }
                ];
            });
    }
}
