import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY } from 'app/common/constants';

@Component({
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
    public categoryId: number;
    private _gridTab: string;

    constructor(private route: ActivatedRoute, private readonly router: Router) {
        this.selectedTab = 'upper';
    }

    public selectedTab: string;

    public selectTab(tab) {
        this.selectedTab = tab;
        this.router.navigate(['category', this._gridTab, this.categoryId, 'rating', tab]).catch(EMPTY); // { queryParamsHandling: 'preserve' } would break rating tab switch
    }

    ngOnInit() {
        this.route.parent.params.subscribe((params) => {
            this.categoryId = params['id'];
            this._gridTab = params['gridTab'] || 'tree';
        });

        if (this.route.snapshot.firstChild) {
            this.selectedTab = this.route.snapshot.firstChild.routeConfig.path;
        }
    }
}
