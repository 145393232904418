import { ICmsComponentsLanguagesProvider, ICountryTranslationModel } from '@alza/cms-components';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { SessionService } from './services';

@Injectable({ providedIn: 'root' })
export class AdminLanguageProvider implements ICmsComponentsLanguagesProvider {
    constructor(private readonly session: SessionService, private readonly cookies: CookieService) {}

    getAvailableLanguages(): Observable<Array<ICountryTranslationModel>> {
        return of(this.session.languages);
    }

    getCurrentLanguage() {
        const langCode = (this.cookies.get('adm2lang') || 'cz').toLowerCase();
        return this.session.languages.find((x) => x.code.toLowerCase() === langCode);
    }
}
