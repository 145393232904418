import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY } from 'app/common/constants';
import { PagerService } from 'app/common/services/pager.service';
import { ICategoryAssignedTextTranslationsCollectionItemDto } from '../dto/category-assigned-text-translations-collection-item-dto';

@Component({
    providers: [PagerService],
    templateUrl: './translations-assigned-grid.component.html',
    styleUrls: ['./translations-assigned-grid.component.scss']
})
export class TranslationsAssignedGridComponent {
    constructor(public readonly pager: PagerService<any, any>, private readonly router: Router) {
        pager.initialize('/api/category/text-translations-assigned', undefined, { key: 0, desc: true });
    }

    public openDetail(item: ICategoryAssignedTextTranslationsCollectionItemDto) {
        if (item.languageId) {
            this.router
                .navigate(['category-translations', 'assigned', item.id, item.languageId], {
                    queryParams: { returnQuery: this.pager.queryString }
                })
                .catch(EMPTY);
        }
    }
}
