<app-page-header [title]="id ? ('History_Detail' | translate) : ('History_Title' | translate)">
    <breadcrumb [text]="'History_Utils' | translate"></breadcrumb>
    <breadcrumb *ngIf="!id" [text]="'History_Title' | translate"></breadcrumb>
    <breadcrumb *ngIf="id" [text]="'History_Title' | translate" [link]="'/audit-v2'"></breadcrumb>
    <breadcrumb [text]="detailBreadCrumbs" [active]="true" *ngIf="id"></breadcrumb>
</app-page-header>

<app-page-content>
    <app-page-box>
        <router-outlet></router-outlet>
    </app-page-box>
</app-page-content>

<app-page-footer>
    <storno-button *ngIf="id" (click)="stornoClick($event)"></storno-button>
</app-page-footer>
