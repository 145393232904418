<app-space-background>
    <h1 class="py-4 text-center">
        {{ 'Login2Fa_VerifyTitle' | translate }}
    </h1>
    <div>
        <div class="alert alert-danger" *ngIf="message">
            {{ message }}
        </div>
        <form [formGroup]="form" *ngIf="form">
            <div class="form-group">
                <h4>{{ getText() }}</h4>
                <input #input [attr.id]="'code'" [attr.data-tid]="'tbCode'" class="form-control" type="text" formControlName="code" />
                <!-- <cms-input #input [id]="'code'" formControlName="code"></cms-input> -->
            </div>

            <div class="form-group">
                <button type="submit" class="btn btn-primary block full-width m-b" (click)="verify()" data-tid="btnVerify">
                    {{ 'Login2Fa_Verify' | translate }}
                </button>
            </div>
        </form>
    </div>
</app-space-background>
