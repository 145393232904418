import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogsService } from 'app/common/services/dialogs.service';
import { FormHelper } from 'app/components/forms/form-helper.service';
import { SaveButtonEvent } from 'app/components/page-footer/button-events';
import { LoginDataService } from '../login-data.service';
import { SetupTwoFaFormService } from '../setup-two-fa-form.service';

@Component({
    selector: 'app-setup-two-fa',
    templateUrl: './setup-two-fa.component.html',
    styleUrls: ['./setup-two-fa.component.scss']
})
export class SetupTwoFaComponent implements OnInit {
    public form: UntypedFormGroup;
    public modeSelectionValues: Array<any> = [];

    constructor(
        private readonly formService: SetupTwoFaFormService,
        private readonly translate: TranslateService,
        private readonly dataService: LoginDataService,
        private readonly formHelper: FormHelper,
        private readonly dialogs: DialogsService
    ) {
        this.form = this.formService.initSetup2FaForm();
        this.dataService.get2FaSetup().subscribe((res) => {
            this.form.patchValue(res);
        });
    }

    ngOnInit() {
        this.translate.get(['Setup2FA_Mode_SMS', 'Setup2FA_Mode_MobileApp']).subscribe((res) => {
            this.modeSelectionValues = [
                { value: 'SMS', text: res.Setup2FA_Mode_SMS },
                { value: 'APP', text: res.Setup2FA_Mode_MobileApp }
            ];
        });
    }

    public getQrCode() {
        return this.form.get('qrCode').value;
    }

    public save(ev: SaveButtonEvent) {
        if (this.formService.form && !this.formService.form.valid) {
            this.formService.form.markAllAsTouched();
            return;
        }
        this.dataService.update2FaSetup(this.form.value).subscribe(
            () => {
                this.dialogs.successMessage(this.translate.instant('Setup2FA_Saved'), '');
                ev.navigateToReturnRoute(['/dashboard']);
            },
            (err: HttpErrorResponse) => {
                if (err.status === 400) {
                    this.dialogs.badRequestMessage(err);
                }
            }
        );
    }
}
