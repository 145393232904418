import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthGuard } from 'app/common/auth-guard';
import { ComponentsModule } from 'app/components/components.module';
import { HistoryDataService } from '../history/history-data.service';
import { CollectionComponent } from './collection/collection.component';

const routes: Routes = [
    {
        path: 'audit',
        canActivate: [AuthGuard],
        component: CollectionComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('app/modules/history/history.module').then((m) => m.HistoryModule)
            },
            {
                path: '**',
                redirectTo: ''
            }
        ]
    }
];

@NgModule({
    imports: [ComponentsModule, TranslateModule, RouterModule.forChild(routes)],
    declarations: [CollectionComponent],
    providers: [HistoryDataService]
})
export class AuditModule {}
