import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthGuard } from 'app/common/auth-guard';
import { ComponentsModule } from 'app/components/components.module';
import { ChangePasswordFormService } from './change-password-form.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { ForgottenPasswordFormService } from './forgotten-password-form.service';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { LoginDataService } from './login-data.service';
import { LoginFormService } from './login-form.service';
import { LoginComponent } from './login/login.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { PhoneNumberRequiredComponent } from './phone-number-required/phone-number-required.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SetupPhoneNumberComponent } from './setup-phone-number/setup-phone-number.component';
import { SetupTwoFaFormService } from './setup-two-fa-form.service';
import { SetupTwoFaComponent } from './setup-two-fa/setup-two-fa.component';
import { SpaceBackgroundComponent } from './space-background/space-background.component';
import { UnconfirmedEmailComponent } from './unconfirmed-email/unconfirmed-email.component';
import { VerifyTwoFaComponent } from './verify-two-fa/verify-two-fa.component';
import { WeakPasswordFormService } from './weak-password-form.service';
import { WeakPasswordComponent } from './weak-password/weak-password.component';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'forgotten-password',
                component: ForgottenPasswordComponent
            },
            {
                path: 'change-password',
                canActivate: [AuthGuard],
                component: ChangePasswordComponent
            },
            {
                path: 'reset-password',
                component: ResetPasswordComponent
            },
            {
                path: 'setup-2fa',
                canActivate: [AuthGuard],
                component: SetupTwoFaComponent
            },
            {
                path: 'phone-number-required',
                component: PhoneNumberRequiredComponent
            },
            {
                path: 'setup-phone-number',
                component: SetupPhoneNumberComponent
            },
            {
                path: 'unconfirmed-email',
                component: UnconfirmedEmailComponent
            },
            {
                path: 'weak-password',
                canActivate: [AuthGuard],
                component: WeakPasswordComponent
            },
            {
                path: 'verify-2fa',
                component: VerifyTwoFaComponent
            },
            {
                path: 'confirm-email',
                component: ConfirmEmailComponent
            }
        ]
    }
];

@NgModule({
    imports: [ComponentsModule, TranslateModule, ReactiveFormsModule, CommonModule, RouterModule.forChild(routes)],
    declarations: [
        LoginComponent,
        ForgottenPasswordComponent,
        ChangePasswordComponent,
        ResetPasswordComponent,
        SetupTwoFaComponent,
        UnconfirmedEmailComponent,
        NewPasswordComponent,
        WeakPasswordComponent,
        VerifyTwoFaComponent,
        ConfirmEmailComponent,
        LanguageSelectComponent,
        SetupPhoneNumberComponent,
        PhoneNumberRequiredComponent,
        SpaceBackgroundComponent
    ],
    providers: [LoginDataService, LoginFormService, ForgottenPasswordFormService, ChangePasswordFormService, SetupTwoFaFormService, WeakPasswordFormService]
})
export class LoginModule {}
